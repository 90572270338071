import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { baseURL, successCode } from "../../configuration/url";
import axios from "axios";
import { toast } from "react-toastify";
import {
  Autocomplete,
  TextField,
} from "@mui/material";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { Button } from "primereact/button";
import { getAllBeacon } from "../../slices/thunk";
import { authorize } from "../../helpers/common";

interface FormData {
  id: string;
  uuid: string;
  deviceName: string;
  deviceId: string;
  BeaconType: string;
  modelNumber: string;
  orgId: string;
}

interface DropdownItem {
  id: string;
  value: string;
  type: string;
}

interface Dropdown {
  id: string;
  dropdown: string;
  list: DropdownItem[];
}

interface BeaconCreationFormProps {
  modal: boolean;
  toggle: () => void;
  orgId1: string;
  currentPage: number;
}

const initialFormData: FormData = {
  id: "",
  uuid: "",
  deviceName: "",
  deviceId: "",
  BeaconType: "",
  modelNumber: "",
  orgId: "",
};

const BeaconCreation: React.FC<BeaconCreationFormProps> = ({
  modal,
  toggle,
  orgId1,
  currentPage
}) => {
  const { organization } = useSelector((state: any) => state.Login);
  const orgId = orgId1 ? orgId1 : organization;
  const [dropdownData, setDropdownData] = useState<DropdownItem[]>([]);
  const [formValues, setFormValues] = useState<FormData>(initialFormData);
  const dispatch = useDispatch<any>();

  const resetForm = () => {
    setFormValues(initialFormData);
  };

  const handleCancelClick = () => {
    toggle();
    resetForm();
    setFormValues(initialFormData);
  };

  const handleSaveClick = async () => {
    const requestBody = {
      id: "",
      uuid: formValues.uuid,
      deviceName: formValues.deviceName,
      deviceId: formValues.deviceId,
      deviceType: formValues.BeaconType,
      modelNumber: formValues.modelNumber,
      orgId: orgId,
    };
    const { header1 } = authorize();
    try {

      if (!formValues.uuid || !formValues.deviceId || !formValues.deviceName) {
        toast.error("Please fill the required fields");
        return;
      }
      const response = await axios.post(
        `${baseURL}/sensor/register`,
        requestBody, { headers: header1 }
      );
      if (
        response.data.message &&
        response.data.message.code === successCode
      ) {
        toast.success(response.data.message.description);
        toggle();
        resetForm();
        handleCancelClick();
        getAllBeacon(dispatch, orgId, currentPage, '')
      } else {
        toast.error(response.data.message.description);
      }
    } catch (error) {
      toast.error("Something went wrong")
      console.error("beaconCreate: ", error);
    }
  };

  useEffect(() => {
    const fetchDropdownData = async () => {
      const { header1 } = authorize();
      try {
        const response = await axios.get(`${baseURL}/dropdowns/getByDropdown?dropdown=BeaconType`, { headers: header1 });
        if (response.data.message.code === 'MHC - 0200') {
          setDropdownData(response.data.data[0].list);
        } else {
          console.error('Error fetching dropdown data:', response.data.message.description);
        }
      } catch (error) {
        console.error('Error fetching dropdown data:', error);
      }
    };
    fetchDropdownData();
  }, []);

  return (
    <Modal
      isOpen={modal}
      size="lg"
      centered
      style={{ width: "580px" }}
    >
      <div className="d-flex align-items-center justify-content-center m-20">
        <div className="row w-100">
          <div className="container col-md-12">
            <ModalHeader toggle={toggle}>Device/Beacon Register</ModalHeader>
            <ModalBody>
              <div
                className="row w-100"
                style={{
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <div className="col-md-12 mb-2">
                  <TextField
                    id="outlined-basic-1"
                    label="Unique ID (UUID)"
                    variant="outlined"
                    fullWidth
                    onChange={(e) =>
                      setFormValues({ ...formValues, uuid: e.target.value })
                    }
                  />
                </div>
              </div>
              <div
                className="row w-100"
                style={{
                  alignItems: "center",
                  justifyContent: "center",
                  marginTop: "10px",
                }}
              >
                {/* <div className="col-md-6 mb-2">
                  <TextField
                    id="outlined-basic-1"
                    label="Mac Address"
                    variant="outlined"
                    fullWidth
                    onChange={(e) =>
                      setFormValues({ ...formValues, deviceId: e.target.value })
                    }
                  />
                </div> */}
                <div className="col-md-6 mb-2">
                  <TextField
                    id="outlined-basic-1"
                    label="Mac Address"
                    variant="outlined"
                    fullWidth
                    value={formValues.deviceId || ""}
                    onChange={(e) => {
                      let rawInput = e.target.value.replace(/[^a-fA-F0-9]/g, "");

                      if (rawInput.length > 12) {
                        rawInput = rawInput.slice(0, 12);
                      }
                      let formattedValue = "";
                      for (let i = 0; i < rawInput.length; i += 2) {
                        formattedValue += rawInput.substring(i, i + 2) + ":";
                      }
                      formattedValue = formattedValue.slice(0, -1);
                      setFormValues({ ...formValues, deviceId: formattedValue });
                    }}
                  />
                </div>

                <div className="col-md-6 mb-2">
                  <TextField
                    id="outlined-basic-1"
                    type="Number"
                    label="Serial Number"
                    variant="outlined"
                    fullWidth
                    onChange={(e) =>
                      setFormValues({ ...formValues, deviceName: e.target.value })
                    }
                  />
                </div>
              </div>
              <div
                className="row w-100"
                style={{
                  alignItems: "center",
                  justifyContent: "center",
                  marginTop: "10px",
                }}
              >
                <div className="col-md-6 mb-2">
                  <Autocomplete
                    id="Model"
                    options={dropdownData.map((item: any) => item.value)}
                    value={formValues.modelNumber}
                    onChange={(event, value: any) => {
                      let modelNumber = "";
                      if (value === "W7") {
                        modelNumber = "METTLER WRISTBAND BEACON";
                      } else if (value === "W8") {
                        modelNumber = "METTLER SOS BEACON";
                      } else if (value === "KG02") {
                        modelNumber = "METTLER BEACON GATEWAY";
                      } else if (value === "WH15") {
                        modelNumber = "METTLER HR WRISTBAND BEACON";
                      }
                      setFormValues({ ...formValues, modelNumber: value, BeaconType: modelNumber });
                    }}
                    renderInput={(params: any) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label="Model"
                        fullWidth
                      />
                    )}
                  />
                </div>
                <div className="col-md-6 mb-2">
                  <TextField
                    id="outlined-basic-1"
                    label="Device Type"
                    variant="outlined"
                    fullWidth
                    value={formValues.BeaconType ? formValues.BeaconType : 'N/A'}
                    name="modelNumber"
                  />
                </div>
              </div>
            </ModalBody>
          </div>
          <ModalFooter className="">
            <div className="d-flex gap-3 justify-content-center">
              <Button
                label="Cancel"
                severity="secondary"
                style={{
                  color: "#000",
                  backgroundColor: "#94a0b7",
                  fontWeight: "bold",
                }}
                onClick={handleCancelClick}
              ></Button>
              <Button
                label="Save Changes"
                style={{ backgroundColor: "#0f3995", fontWeight: "bold" }}
                onClick={handleSaveClick}
              ></Button>
            </div>
          </ModalFooter>
        </div>
      </div>
    </Modal>
  );
};

export default BeaconCreation;
