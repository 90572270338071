import { Autocomplete, TextField } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { Modal, ModalFooter, ModalBody, ModalHeader, Dropdown } from "reactstrap";
import { updatePatientDetails } from '../../slices/thunk';
import { authorize, formatEmail } from '../../helpers/common';
import axios from 'axios';
import { baseURL } from '../../configuration/url';
import { CircularProgress } from '@material-ui/core';
import { Button } from "primereact/button";
import * as Constants from "../Constants/Constant";
import uploadImage from "../../assets/images/rectangle-59991.svg";


interface PatientEditModalProps {
  editModal: boolean
  setEditModal: any
  selectedPatientName: any
  formData: any
  setFormData: any
  selectPatientId: any
  admitDate: any
  deviceId: any
  currentPage: number
}
const PatientEditModal: React.FC<PatientEditModalProps> = ({ editModal, setEditModal, selectedPatientName, formData, setFormData, selectPatientId, admitDate, deviceId, currentPage }) => {

  let [highlightfirstname, setHighlight] = useState(false);
  let [highlightlastname, sethighlightlastname] = useState(false);
  let [highlightdate, sethighlightdate] = useState(false);
  let [highlightssn, sethighlightssn] = useState(false);
  let [highlightemail, sethighlightemail] = useState(false);
  let [highlightzipcode, sethighlightzipcode] = useState(false);
  const { organization } = useSelector((state: any) => state.Login);
  const [dropdownData, setDropdownData] = useState<Dropdown[]>([]);
  let [cityDropDown, setCityDropDown] = useState(new Array<any>());


  const dispatch = useDispatch<any>()

  const handleinputchange = (event: any) => {
    if (event.target.id === 'firstName') {
      formData.firstName = event.target.value;
      setHighlight(false);
    } else if (event.target.id === 'middleName') {
      formData.middleName = event.target.value;
    } else if (event.target.id === 'lastName') {
      formData.lastName = event.target.value;
      sethighlightlastname(false);
    } else if (event.target.id === 'mrNumber') {
      formData.mrNumber = event.target.value;
    } else if (event.target.id === 'ssn') {
      formData.ssn = event.target.value;
      sethighlightdate(false);
    } else if (event.target.id === 'email') {
      formData.email = event.target.value;
      sethighlightemail(false);
    } else if (event.target.id === 'addressLine1') {
      formData.addressLine1 = event.target.value;
    } else if (event.target.id === 'AddressLine2') {
      formData.addressLine2 = event.target.value;
    } else if (event.target.id === 'city') {
      formData.city = event.target.value;
    } else if (event.target.id === 'state') {
      formData.state = event.target.value;
    } else if (event.target.id === 'country') {
      formData.country = event.target.value;
    }
    setFormData({ ...formData });
  }

  const highlight = () => {
    let highlighted = false;
    if (!formData.firstName.trim()) {
      setHighlight(true);
      toast.error("Please Enter First Name");
      highlighted = true;
    } else {
      setHighlight(false);
    }
    if (!formData.lastName.trim()) {
      sethighlightlastname(true);
      toast.error("Please Enter LastName");
      highlighted = true;
    } else {
      sethighlightlastname(false);
    }
    if (!formData.birthDate.trim()) {
      sethighlightdate(true);
      toast.error("Please Enter birthDate");
      highlighted = true;
    } else {
      sethighlightdate(false);
    }
    if (!formData.ssn.trim()) {
      sethighlightssn(true);
      toast.error("Please Enter tin");
      highlighted = true;
    } else {
      sethighlightssn(false);
    }
    if (!formData.email.trim()) {
      sethighlightemail(true);
      toast.error("Please Enter email");
      highlighted = true;
    } else {
      sethighlightemail(false);
    }
    if (!formData.postalCode.trim()) {
      sethighlightzipcode(true);
      toast.error("Please Enter Postalcode");
      highlighted = true;
    } else {
      sethighlightzipcode(false);
    }
    return highlighted;
  };

  const handleSaveChanges = () => {
    const isHighlighted = highlight();
    if (!isHighlighted) {
      if (!selectPatientId) {
        return;
      } else if (formData.postalCode.length > 0 && formData.city === "" && formData.state === "") {
        formData.city = "";
        formData.state = "";
        formData.country = "";
        alert("Please Enter Valid Zip Code");
        return;
      }
      const formatDateold = (dateString: any) => {
        const [year, month, day] = dateString.split('-');
        return `${year}${month}${day}`;
      };
      const updatedPatientFields = {
        id: selectPatientId,
        active: (admitDate === null) ? "0" : "1",
        basicDetails: [
          {
            name: [
              {
                use: formData.middleName,
                given: formData.firstName,
                family: formData.lastName,
              },
            ],
            profile: formData.profile,
            ssn: formData.ssn,
            mrNumber: formData.mrNumber,
            gender: formData.gender,
            birthDate: formatDateold(formData.birthDate),
          },
        ],
        email: formData.email,
        organization,
        contact: [
          {
            address: [
              {
                addressLine1: formData.addressLine1,
                addressLine2: formData.addressLine2,
                city: formData.city,
                state: formData.state,
                postalCode: formData.postalCode,
                country: formData.country,
              },
            ],
          },
        ],
        beaconDevice: deviceId
      };
      dispatch(
        updatePatientDetails(
          selectPatientId,
          updatedPatientFields,
          organization,
          currentPage
        )
      );
      setEditModal(false);
    }
  };

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setFormData((prevData: object) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const handleAddress = (e: any) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  const [loading1, setLoading] = useState(false);
  const handlePostalCodeChange = async (e: any) => {
    formData.postalCode = e.target.value;
    setFormData({ ...formData });
    sethighlightzipcode(false);
    if (e.target.value.length === 5) {
      setLoading(true);
      try {
        const response = await axios.get(`https://app.zipcodebase.com/api/v1/search?codes=${e.target.value}&country=US&${Constants.apiKey}`);
        formData.state = response.data.query.codes !== null && response.data.query.codes !== undefined ? response.data.results[response.data.query.codes[0]][0].state : "";
        formData.country = response.data.query.codes !== null && response.data.query.codes !== undefined ? response.data.results[response.data.query.codes[0]][0].country_code : "";
        formData.city = response.data.query.codes !== null && response.data.query.codes !== undefined ? response.data.results[response.data.query.codes[0]][0].city : "";
        setCityDropDown(response.data.query.codes !== null && response.data.query.codes !== undefined ? response.data.results[response.data.query.codes[0]].map((k: any) => k.city_en) : [])
      } catch (error) {
        console.error('Error fetching city:', error);
      } finally {
        setLoading(false);
      }
    } else {
      formData.state = "";
      formData.country = "";
      formData.city = "";
    }
    setFormData({ ...formData });
  };
  useEffect(() => {
    const fetchDropdownData = async () => {
      const { header1 } = authorize();
      try {
        const response = await axios.get(`${baseURL}/dropdowns/getByDropdown?dropdown=gender`, { headers: header1 });
        if (response && response.data.message && response.data.message.code === 'MHC - 0200') {
          setDropdownData(response.data.data[0].list);
        } else {
          console.error('Error fetching dropdown data:', response.data.message.description);
        }
      } catch (error) {
        console.error('Error fetching dropdown data:', error);
      }
    };
    fetchDropdownData();
  }, []);
  const hiddenFileInput = useRef<any>(null);

  const handleProfileChange = () => {
    if (hiddenFileInput !== null) {
      hiddenFileInput.current.click();
    }
  }

  const handleImageChange = (e: any) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      formData.profile = reader.result !== null ? reader.result.toString() : ""
      setFormData({ ...formData });
    }
    reader.readAsDataURL(e.target.files[0]);
  }

  const [profilePath, setProfilePath] = useState<any>(uploadImage)
  useEffect(() => {
    if (!formData) return
    setProfilePath(formData.profile && formData.profile.length > 0 ? formData.profile : uploadImage)
  }, [formData])
  return (
    <>
      <Modal
        isOpen={editModal}
        centered
        size="lg"
      ><div className="d-flex align-items-center justify-content-center vh-90">
          <div className="row">
            <div className="container col-md-12">
              <ModalHeader toggle={() => setEditModal(false)}>
                <div className='d-flex gap-2 '>
                  <div className='' >
                    {selectedPatientName}
                  </div>
                  <div className=''>
                    <div onClick={handleProfileChange} hidden={formData.profile !== null && formData.profile !== "" && formData.profile !== "string"} style={{ left: "unset", width: "156px" }} className="">
                      <div className="" style={{ fontSize: '13px', color: 'blue', cursor: 'pointer', height: '37px', width: '145px', border: '2px dashed grey', padding: '7px' }}>UPLOAD PROFILE PIC</div>
                    </div>
                    <div hidden={formData.profile === null || formData.profile === "" || formData.profile === "string"} style={{ left: "unset", width: "182px" }} className="">
                      <div style={{ position: 'absolute', top: "5px" }}><img style={{ height: '50px', width: '50px', borderRadius: "60px" }} alt='' src={formData.profile} onClick={handleProfileChange} /><input className="" style={{ position: 'absolute', top: '12px', display: 'none' }} type="file" ref={hiddenFileInput} onChange={handleImageChange} /></div>
                    </div>
                  </div>
                </div>
              </ModalHeader>
              <ModalBody style={{ maxHeight: '68vh', overflowY: 'auto' }}>
                <div className="row w-100 ">
                  <div className="col-md-4 "  >
                    <TextField
                      error={highlightfirstname}
                      id="firstName"
                      name="firstName"
                      label="First Names"
                      placeholder="Enter First Name"
                      value={formData.firstName}
                      onChange={handleinputchange}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="outlined"
                      fullWidth
                      margin="normal"
                    />
                  </div>
                  <div className="col-md-4 ">
                    <TextField
                      id="middleName"
                      name="middleName"
                      label="Middle Name"
                      placeholder="Enter Middle Name"
                      value={formData.middleName}
                      onChange={handleinputchange}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="outlined"
                      fullWidth
                      margin="normal"
                    />
                  </div>
                  <div className="col-md-4 ">
                    <TextField
                      error={highlightlastname}
                      id="lastName"
                      name="lastName"
                      label="Last Name"
                      placeholder="Enter LastName"
                      value={formData.lastName}
                      onChange={handleinputchange}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="outlined"
                      fullWidth
                      margin="normal"
                    />
                  </div>
                </div>
                <div className="row w-100">
                  <div className="col-md-4 mt-3">
                    <Autocomplete
                      id='gender'
                      options={dropdownData.map((list: any) => list.value)}
                      value={formData.gender}
                      onChange={(e, v) => { formData.gender = v; setFormData({ ...formData }); }}
                      renderInput={(params) => (
                        <TextField {...params} label='Gender' variant="outlined" />
                      )}
                    />
                  </div>
                  <div className="col-md-4 ">
                    <TextField
                      type="date"
                      sx={{
                        border: highlightdate ? "1px solid red" : "",
                        borderRadius: "5px"
                      }}
                      id="birthDate"
                      name="birthDate"
                      label="Date Of Birth"
                      placeholder="Enter DateOfBirth"
                      value={formData.birthDate}
                      onChange={handleChange}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="outlined"
                      fullWidth
                      margin="normal"
                    />
                  </div>
                  <div className="col-md-4">
                    <TextField
                      id="mrNumber"
                      name="mrNumber"
                      label="MrNumber"
                      placeholder="Enter MR Number"
                      value={formData.mrNumber}
                      onChange={(e) => {
                        const inputValue = e.target.value;
                        if (/^\d{0,7}$/.test(inputValue)) {
                          handleinputchange(e);
                        }
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="outlined"
                      fullWidth
                      margin="normal"
                      InputProps={{
                        inputProps: {
                          style: {
                            appearance: 'textfield',
                            MozAppearance: 'textfield',
                          },
                        },
                      }}
                      sx={{
                        '& input[type=number]': {
                          '-webkit-appearance': 'none',
                          '-moz-appearance': 'textfield',
                          'appearance': 'none',
                        },
                      }}
                    />
                  </div>
                </div>
                <div className="row w-100">
                  <div className="col-md-6 ">
                    <TextField
                      error={highlightemail}
                      id="email"
                      name="email"
                      label="Email"
                      placeholder="Enter Email"
                      value={formData.email}
                      onChange={handleinputchange}
                      onBlur={() => {
                        try {
                          const formattedEmail = formatEmail(formData.email);
                          setFormData({ ...formData, email: formattedEmail });
                          sethighlightemail(false);
                        } catch (error) {
                          sethighlightemail(true);
                        }
                      }}

                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="outlined"
                      fullWidth
                      margin="normal"
                    />
                  </div>
                  <div className="col-md-6 ">
                    <TextField
                      error={highlightssn}
                      id="ssn"
                      name="ssn"
                      label="SSN"
                      placeholder="Enter SSN"
                      value={formData.ssn}
                      onChange={handleinputchange}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="outlined"
                      fullWidth
                      margin="normal"
                    />
                  </div>
                </div>
                <div className="row w-100">
                  <div className="col-md-4 ">
                    <TextField
                      id="addressLine1"
                      name="addressLine1"
                      label="Address Line 1"
                      placeholder="Enter Address"
                      value={formData.addressLine1}
                      onChange={handleinputchange}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="outlined"
                      fullWidth
                      margin="normal"
                    />
                  </div>
                  <div className="col-md-4 ">
                    <TextField
                      id="addressLine2"
                      name="addressLine2"
                      label="Address Line 2"
                      placeholder="Enter Address"
                      value={formData.addressLine2}
                      onChange={handleAddress}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="outlined"
                      fullWidth
                      margin="normal"
                    />
                  </div>
                  <div className="col-md-4 mt-3">
                    <TextField
                      error={highlightzipcode}
                      id="zipcode"
                      label="Zip/Postal Code"
                      variant="outlined"
                      fullWidth
                      value={formData.postalCode}
                      onChange={handlePostalCodeChange}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      inputProps={{
                        maxLength: 5,
                        pattern: "\\d{5}",
                      }}
                    />
                    {loading1 && <CircularProgress size={'25px'} />}
                  </div>
                </div>
                <div className="row w-100">
                  <div className="col-md-4 mt-3">
                    {cityDropDown !== null && cityDropDown.length === 1 ?
                      <TextField
                        id="city"
                        label="City"
                        variant="outlined"
                        fullWidth
                        value={formData.city}
                        onChange={handleinputchange}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      /> :
                      <Autocomplete
                        id="city"
                        options={cityDropDown}
                        value={formData.city}
                        getOptionLabel={(option) => option}
                        onChange={(e, v) => { formData.city = v; setFormData({ ...formData }); }}
                        sx={{ width: "100%" }}
                        size="medium"
                        renderInput={params =>
                          <TextField
                            name=""
                            {...params}
                            variant="outlined"
                            label="City"
                            placeholder=""
                            margin="none"
                            size="medium"
                            fullWidth
                          />
                        }
                      />}
                  </div>
                  <div className="col-md-4 mt-3">
                    <TextField
                      id="state"
                      label="State"
                      variant="outlined"
                      fullWidth
                      value={formData.state}
                      onChange={handleinputchange}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </div>
                  <div className="col-md-4">
                    <TextField
                      id="country"
                      name="country"
                      label="Country"
                      placeholder="Enter Country"
                      value={formData.country}
                      onChange={handleinputchange}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="outlined"
                      fullWidth
                      margin="normal"
                    />
                  </div>
                </div>
              </ModalBody>
            </div>
          </div>
        </div>
        <ModalFooter className="">
          <div className="d-flex gap-3 justify-content-center">
            <Button label="Cancel" severity="secondary" style={{ color: '#000', backgroundColor: '#94a0b7', fontSize: '12px', fontWeight: 'bold' }} onClick={() => setEditModal(false)}></Button>
            <Button label="Save Changes" style={{ backgroundColor: '#0f3995', fontSize: '12px', fontWeight: 'bold' }} onClick={handleSaveChanges}></Button>
          </div>
        </ModalFooter>
      </Modal>
    </>
  )
}

export default PatientEditModal