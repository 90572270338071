import React from 'react';
import { Button, Modal, ModalBody, ModalHeader } from "reactstrap";

interface LogoutConfirmationModalProps {
    open: boolean;
    handleConfirm: () => void;
    handleClose: () => void;
    message: string;
}

const LogoutConfirmationModal: React.FC<LogoutConfirmationModalProps> = ({ open, handleConfirm, handleClose, message }) => {

    return (
        <Modal
            isOpen={open}
            toggle={handleClose}
            centered
            style={{
                fontFamily: "calibri",
                fontSize: "20px",
                maxWidth: "500px", 
                width: "80%", 
                height: "300px", 
            }}
        >
            <ModalHeader style={{ fontSize: "24px", textAlign: "center" }}>
                Mettler Health Care
            </ModalHeader>
            <ModalBody
                style={{
                    display: "flex",
                    gap: "4px",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center", 
                }}
            >
                <div
                    style={{
                        marginBottom: "10px",
                        position: "relative",
                         top: "-5px",
                    }}
                >
                    {message}
                </div>
                <div style={{ display: "flex", gap: "20px" }}>
                    <Button color="primary" onClick={handleConfirm}>
                        Yes
                    </Button>
                    <Button onClick={handleClose}>No</Button>
                </div>
            </ModalBody>
        </Modal>
    );
};

export default LogoutConfirmationModal;
