import "./header.css";
import LogoImg from "../../assets/images/mettlerTitle.png";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getOrgByID, handleLogout } from "../../slices/thunk";
import React, { useState, useEffect } from "react";
import { Logout, LockReset } from "@mui/icons-material";
import {
  Avatar,
  Box,
  Divider,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Tooltip,
} from "@mui/material";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { TextField } from "@mui/material";
import { ArrowDropDownIcon } from "@mui/x-date-pickers";
import { baseURL } from "../../configuration/url";
import LogoutConfirmationModal from "../LogoutModel/index";
import { greytext, primarytext } from "../../common/primary";
import { setCurrentDateTime } from "../../slices/LargeScreen/reducer";
import Loader from "../loader/Loader";
interface Organization {
  id: string;
  name: string;
}

interface HeaderProps {
  currentPage: string;
  subPage: string;
  isSidebarOpen: boolean;
}
const Header: React.FC<HeaderProps> = ({ currentPage, subPage, isSidebarOpen }) => {
  const dispatch = useDispatch<any>()
  const [orgName, setOrgName] = useState("");
  const [organizationData, setOrganizationData] = useState<Organization[]>([]);
  const { orgName: lgOrgName, currentDateTime, orgTimeZone } = useSelector((state: any) => state.LargeScreen)


  useEffect(() => {
    const updateTime = () => {
      // console.log(orgTimeZone, 'org time')
      const now = new Date();

      // Ensure orgTimeZone is a valid time zone string
      const validTimeZone = orgTimeZone ? orgTimeZone : "America/Detroit"; // Default to America/Detroit if orgTimeZone is not set
      // console.log(validTimeZone, 'time zone valid')
      try {
        const formattedDateTime = new Intl.DateTimeFormat("en-US", {
          timeZone: validTimeZone,
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
          hour: "2-digit",
          minute: "2-digit",
          second: "2-digit",
          hour12: true, // You can set this to false if you prefer 24-hour format
        }).format(now);
        // alert(formattedDateTime)
        // console.log(formattedDateTime,'datasafhsfdjh')
        localStorage.setItem('LTime', formattedDateTime);
        dispatch(setCurrentDateTime(formattedDateTime));
      } catch (error) {
        // console.error("Error formatting date/time:", error);
      }
    };

    updateTime();
    const interval = setInterval(updateTime, 1000);

    return () => clearInterval(interval);
  }, [orgTimeZone]);


  const fetchOrganizationData = async () => {
    try {
      const response = await fetch(`${baseURL}/org/name`);
      const data = await response.json();
      setOrganizationData(data.data);
    } catch (error) {
      console.error("Error fetching organization data:", error);
    }
  };

  const organizationId = useSelector((state: any) => state.Login.organization);
  useEffect(() => {
    fetchOrganizationData();
    getOrgByID(dispatch, organizationId)
  }, [organizationId]);
  useEffect(() => {
    const organization = organizationData.find(org => org.id === userData.organization);
    if (organization) {
      setOrgName(organization.name)
      localStorage.setItem('orgName', organization.name)
    }
  }, [organizationData, organizationId]);

  const [givenName, setGivenName] = useState("");
  const [familyName, setFamilyName] = useState("");

  const [email, setEmail] = useState("");
  useEffect(() => {
    const given = localStorage.getItem("given");
    const family = localStorage.getItem("family");
    if (given && family) {
      setGivenName(given);
      setFamilyName(family);
    }
  }, []);
  useEffect(() => {
    const userEmail = localStorage.getItem("userDetailEmail");
    if (userEmail) {
      setEmail(userEmail);
    }
  }, []);
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open1 = Boolean(anchorEl);
  const location = useLocation();
  const navigate = useNavigate();
  const isLoginPage = location.pathname === "/login";
  const isSecretKeyPage = location.pathname === "/secret-key";
  const isUnblockPage = location.pathname === "/account-unblock";
  const isForgotPassword = location.pathname === "/forgot-password";
  const isChangePassword = location.pathname === "/change-password";
  const isVerifyOTP = location.pathname === "/verify-otp";
  const isResetSecret = location.pathname === "/resetSecretKey";
  const isReCreatePassword = location.pathname === "/recreatePassword";
  const patientView = location.pathname === "/patient-view";
  const staffView = location.pathname === "/staff-view";


  const { jwt, userType, userData, loading } = useSelector(
    (state: any) => state.Login
  );
  const { organizationDetails } = useSelector(
    (state: any) => state.Organization
  );
  const [LorgName, setLorgName] = useState('')
  const [Ltime, setLtime] = useState('')
  useEffect(() => {
    const LorgName: any = localStorage.getItem('orgName')
    const Ltime: any = localStorage.getItem('LTime')
    setLorgName(LorgName ? LorgName : lgOrgName)
    setLtime(Ltime ? Ltime : currentDateTime)
    // alert('l')
  }, [currentDateTime, orgTimeZone, orgName])
  const username = useSelector((state: any) => state.Login.userDetails);
  const [modal, setModal] = useState(false);

  const showLogoImg =
    !isLoginPage &&
    !isSecretKeyPage &&
    !isUnblockPage &&
    !isForgotPassword &&
    !isChangePassword &&
    !isVerifyOTP &&
    // !patientView &&
    // !staffView &&
    !isResetSecret;
  const showAvatar =
    !isLoginPage &&
    !isSecretKeyPage &&
    !isUnblockPage &&
    !isForgotPassword &&
    !isChangePassword &&
    !isVerifyOTP &&
    // !patientView &&
    // !staffView &&
    !isResetSecret;
  const showHeader =
    !isLoginPage &&
    !isSecretKeyPage &&
    !isUnblockPage &&
    !isForgotPassword &&
    !isChangePassword &&
    !isVerifyOTP &&
    // !patientView &&
    // !staffView &&
    !isResetSecret &&
    !isReCreatePassword;

  if (!showHeader) {
    return null;
  }
  const handleLogoutClick = () => {
    setOpen(true);
  };

  const handleConfirmLogout = () => {
    const body = { jwt, username };
    handleLogout(body, navigate, dispatch);
    // setOpen(false);
  };


  const handleChangePassword = () => {
    setOpen(!open);
    navigate("/recreatePassword");
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClo = () => {
    setOpen(false)
  }

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleProfileCheck = () => {
    setModal(!modal);
  };
  const user = userData.userDetail?.username || "";
  const role = userData.userDetail?.role || "";
  const mobilePhone = userData.userDetail?.contact?.[0]?.mobilePhone || "";
  const ssn = userData.userDetail?.ssn || "";
  const npi = userData.userDetail?.npi || "";
  const dateofBirth = userData.userDetail?.dateofBirth || "";
  const speciality = userData.userDetail?.speciality?.[0] || "";
  const organizationName =
    userData.userDetail?.organizationdetails?.[0]?.name || "";
  const mobileNumber = userData.userDetail?.mobileNumber || "";
  const websiteURL = userData.userDetail?.websiteUrl || "";
  const q15Access = userData.userDetail?.q15Access || "";
  const geofencing = userData.userDetail?.geofencing || "";
  const proximityVerification =
    userData.userDetail?.proximityVerification || "";




  return (
    <>
      {loading && <Loader />}
      <div
        className='row mHeader'
      >
        {/* {(patientView || staffView) &&  */}
        <div className="px-5 " style={{ color: 'white', width: '40%', display: 'flex', justifyContent: 'flex-start', zIndex: 101, flexDirection: 'column', marginLeft: patientView || staffView ? "" : isSidebarOpen ? '210px' : '40px', transition: 'width 0.5s ease' }}>
          <p className="org-name">Organization: {LorgName}</p>
          <p className="time">Date/Time: {Ltime}</p>
        </div>
        {/* } */}
        <div className="header-container">

          {showLogoImg && (
            <img
              src={LogoImg}
              alt="Logo"
              className="logo-img"
            />
          )}
        </div>
        <div className="avatar-container">
          <Box>
            <Tooltip title="Account settings">
              <IconButton
                onClick={handleClick}
                size="small"
                aria-controls={open ? "account-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
              >
                <Avatar
                  sx={{ width: 32, height: 32, backgroundColor: "#9F9FA2" }}
                >
                  {username.charAt(0).toUpperCase()}
                </Avatar>
                <ArrowDropDownIcon style={{ color: '#fff' }} />
              </IconButton>
            </Tooltip>
          </Box>
        </div>
        <Menu
          anchorEl={anchorEl}
          id="account-menu"
          open={open1}
          onClose={handleClose}
          onClick={handleClose}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              mt: 1.5,
              "& .MuiAvatar-root": {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              "&::before": {
                content: '""',
                display: "block",
                position: "absolute",
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: "background.paper",
                transform: "translateY(-50%) rotate(45deg)",
                zIndex: 0,
              },
            },
          }}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        >
          <MenuItem onClick={handleProfileCheck}>
            <Avatar
              sx={{ width: 32, height: 32, backgroundColor: "#9F9FA2" }}
              src={localStorage.getItem("profile") || undefined}
            >
              {localStorage.getItem("profile") ? null : username.charAt(0).toUpperCase()}
            </Avatar>
            {userType === "Super Admin" ? (
              <div style={{ fontSize: '12px' }}>
                <p style={{ margin: '0px', fontWeight: 'bold', color: primarytext }}>{username}</p>
                <p style={{ margin: '0px', color: greytext }}>{userType}</p>
                <p style={{ margin: '0px', fontWeight: 'bold', color: greytext }}>{organizationName}</p>
              </div>
            ) : (userType === 'Admin') ? (
              <div style={{ fontSize: '12px' }}>
                <p style={{ margin: '0px', fontWeight: 'bold', color: primarytext }}>{givenName} {familyName}</p>
                <p style={{ margin: '0px', color: greytext }}>Supervisor</p>
                <p style={{ margin: '0px', fontWeight: 'bold', color: greytext }}>{orgName}</p>
              </div>
            ) : (userType === 'System Admin') ? (
              <div style={{ fontSize: '12px' }}>
                <p style={{ margin: '0px', fontWeight: 'bold', color: primarytext }}>{username}</p>
                <p style={{ margin: '0px', color: greytext }}>{userType}</p>
                <p style={{ margin: '0px', fontWeight: 'bold', color: greytext }}>{orgName}</p>
              </div>
            ) : (
              <div style={{ fontSize: '12px' }}>
                <p style={{ margin: '0px', fontWeight: 'bold', color: primarytext }}>{givenName} {familyName}</p>
                <p style={{ margin: '0px', color: greytext }}>{userType === "Receptionist-sos" ? "Receptionist-SOS" : userType }</p>
                <p style={{ margin: '0px', fontWeight: 'bold', color: greytext }}>{orgName}</p>
              </div>
            )}
          </MenuItem>
          <Divider />
          <MenuItem onClick={handleChangePassword}>
            <ListItemIcon>
              <LockReset fontSize="small" />
            </ListItemIcon>
            Change Password
          </MenuItem>

          <MenuItem onClick={handleLogoutClick}>
            <ListItemIcon>
              <Logout fontSize="small" />
            </ListItemIcon>
            Logout
          </MenuItem>
        </Menu>
        <LogoutConfirmationModal
          open={open}
          handleConfirm={() => handleConfirmLogout()}
          handleClose={handleClo}
          message="Are you sure you want to logout?"
        />
        <Modal
          isOpen={modal}
          toggle={() => setModal(false)}
          centered
          style={{ fontFamily: "calibri", fontSize: "20px" }}
        >
          <ModalHeader toggle={() => setModal(false)}>
            {" "}
            User Profile Details
          </ModalHeader>
          <ModalBody>
            {userType === "Super Admin" ? (
              <>
                <div className="row">
                  <div className="col-md-4"> <TextField
                    id="outlined-basic-1"
                    label="UserName"
                    variant="outlined"
                    fullWidth
                    style={{ marginBottom: "10px" }}
                    value={username}
                  /></div>
                  <div className="col-md-4"><TextField
                    id="outlined-basic-2"
                    label="UserType"
                    variant="outlined"
                    fullWidth
                    style={{ marginBottom: "10px" }}
                    value={userType}
                  /></div>
                  <div className="col-md-4"><TextField
                    id="outlined-basic-3"
                    label="OrganizationName"
                    variant="outlined"
                    fullWidth
                    style={{ marginBottom: "10px" }}
                    value={organizationName}
                  /></div>
                </div>
                <div className="row">
                  <div className="col-md-12"><TextField
                    id="outlined-basic-3"
                    label="Email"
                    variant="outlined"
                    fullWidth
                    style={{ marginBottom: "10px" }}
                    value={email}
                  />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6"><TextField
                    id="outlined-basic-4"
                    variant="outlined"
                    label="MobileNumber"
                    fullWidth
                    style={{ marginBottom: "10px" }}
                    value={mobileNumber}
                  /></div>
                  <div className="col-md-6"><TextField
                    id="outlined-basic-5"
                    variant="outlined"
                    value={websiteURL}
                    fullWidth
                    label="Website URL"
                    style={{ marginBottom: "10px" }}
                  /></div>
                </div>
                <div className="row">
                  <div className="col-md-4"><TextField
                    id="outlined-basic-7"
                    variant="outlined"
                    label="Q15Access"
                    fullWidth
                    style={{ marginBottom: "10px" }}
                    value={q15Access}
                  /></div>
                  <div className="col-md-4"><TextField
                    id="outlined-basic-6"
                    variant="outlined"
                    value={geofencing}
                    fullWidth
                    style={{ marginBottom: "10px" }}
                    label="GeoFencing"
                  /></div>
                  <div className="col-md-4"><TextField
                    id="outlined-basic-8"
                    label="ProximityVerification"
                    value={proximityVerification}
                    fullWidth
                    style={{ marginBottom: "10px" }}
                  /></div>
                </div>
              </>
            ) : (userType === 'Admin' || userType === 'Staff') ? (
              <>
                <div className="row">
                  <div className="col-md-6">
                    <TextField
                      id="outlined-basic-4"
                      label="Organization Name"
                      variant="outlined"
                      value={orgName}
                      fullWidth
                      style={{ marginBottom: "20px" }}
                    />
                  </div>
                  <div className="col-md-6">
                    <TextField
                      id="outlined-basic-1"
                      label="Username"
                      variant="outlined"
                      fullWidth
                      style={{ marginBottom: "10px" }}
                      value={username}
                    /></div>

                </div>
                <div className="row ">
                  <div className="col-md-6">
                    <TextField
                      id="outlined-basic-2"
                      label="Given Name"
                      variant="outlined"
                      fullWidth
                      style={{ marginBottom: "10px" }}
                      value={givenName}
                    /></div>
                  <div className="col-md-6">
                    <TextField
                      id="outlined-basic-2"
                      label="Family Name"
                      variant="outlined"
                      fullWidth
                      style={{ marginBottom: "10px" }}
                      value={familyName}
                    /></div>
                </div>
                <div className="row ">
                  <div className="col-md-12">
                    <TextField
                      id="outlined-basic-3"
                      label="Email"
                      variant="outlined"
                      fullWidth
                      style={{ marginBottom: "10px" }}
                      value={email}
                    />
                  </div>
                </div>
                <div className="row ">
                  <div className="col-md-6">
                    <TextField
                      id="outlined-basic-5"
                      label="Role"
                      variant="outlined"
                      fullWidth
                      style={{ marginBottom: "10px" }}
                      value={role}
                    /></div>
                  <div className="col-md-6">
                    <TextField
                      id="outlined-basic-9"
                      label="Speciality"
                      variant="outlined"
                      fullWidth
                      style={{ marginBottom: "10px" }}
                      value={speciality}
                    />
                  </div>

                </div>
                <div className="row">
                  <div className="col-md-6">
                    <TextField
                      id="outlined-basic-6"
                      label="Contact"
                      variant="outlined"
                      fullWidth
                      style={{ marginBottom: "10px" }}
                      value={mobilePhone}
                    /></div>
                  <div className="col-md-6">
                    <TextField
                      id="outlined-basic-10"
                      label="DateOfBirth"
                      value={dateofBirth}
                      variant="outlined"
                      fullWidth
                      style={{ marginBottom: "10px" }}
                    />
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className="row">
                  <div className="col-md-6"><TextField
                    id="outlined-basic-1"
                    label="Username"
                    variant="outlined"
                    fullWidth
                    style={{ marginBottom: "10px" }}
                    value={username}
                  /></div>
                  <div className="col-md-6"><TextField
                    id="outlined-basic-2"
                    label="UserType"
                    variant="outlined"
                    fullWidth
                    style={{ marginBottom: "10px" }}
                    value={userType}
                  /></div>

                </div>
                <div className="row ">
                  <div className="col-md-12"><TextField
                    id="outlined-basic-3"
                    label="Email"
                    variant="outlined"
                    fullWidth
                    style={{ marginBottom: "10px" }}
                    value={email}
                  /></div>

                </div>
                <div className="row">
                  <div className="col-md-12"><TextField
                    id="outlined-basic-4"
                    label="Organization Name"
                    variant="outlined"
                    value={orgName}
                    fullWidth
                    style={{ marginBottom: "20px" }}
                  /></div>
                </div>
              </>
            )}
          </ModalBody>
        </Modal>
      </div>
    </>
  );
};

export default Header;