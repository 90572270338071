import axios from 'axios';
import { endLoading, saveLoginData, saveOrganization, saveUserData, saveUserDetails, saveUserType, startLoading, userDetails } from './reducer'
import { toast } from 'react-toastify';
import { baseURL, successCode } from '../../configuration/url'
import { authorize } from '../../helpers/common';
import { getStaffLSSuccess } from '../staff/reducer';

export const handleLogin = async (dispatch: any, body: any, navigate: (p: string) => void, ischecked: boolean) => {
    dispatch(startLoading())
    try {
        const response = await axios.post(`${baseURL}/user/signin`, body);
        if (response.data.message.code === successCode) {
            const { jwt, session, userType, organization, userDetail } = response.data.data;
            const userEmail = userDetail ? userDetail.email : null;
            const username = body.username;
            localStorage.setItem('userDetailEmail', userEmail);
            localStorage.setItem('userDetailUsername', username);
            localStorage.setItem('userID', userDetail.id);
            sessionStorage.setItem('jwt', response.data.data.jwt.jwtToken);
            localStorage.setItem('userType', userType[0])
            console.log(userType[0], 'type')
            dispatch(saveLoginData(jwt.jwtToken));
            dispatch(saveUserDetails(username));
            dispatch(saveUserData(response.data.data))
            dispatch(saveUserType(userType[0]));
            dispatch(saveOrganization(organization));
            dispatch(userDetails(userDetail))
            toast.success(response.data.message.description);
            if (ischecked) {
              localStorage.setItem('userName', body.username)
              localStorage.setItem('password', body.password)
            } else {
              localStorage.removeItem('userName');
              localStorage.removeItem('password');
            }
            if (response.data.data.resetCount === 0) {
                navigate("/recreatePassword");
            } else {
                if (userType[0] === "Super Admin") {
                navigate('/secret-key');
            } else {
                if (userDetail && userDetail.name && userDetail.name.length > 0) {
                  localStorage.setItem('profile', userDetail.profile);
                    const given = userDetail.name[0].given;
                    const family = userDetail.name[0].family;
                    localStorage.setItem('given', given);
                    localStorage.setItem('family', family);
                }
                navigate('/secret-key');
            }
            }
        } else if (response.data.message.code === "MHC - 0003") {
            dispatch(saveUserDetails(body.username));
            toast.error(response.data.message.description);
            navigate('/account-unblock');
        } else {
            toast.error(response.data.message.description);
            dispatch(endLoading());
        }


    } catch (error) {
        dispatch(endLoading())
    }
};

export const handleLogout = async (body: any, navigate: (p: string) => void, dispatch: any) => {
    const { header1 } = authorize();
    dispatch(startLoading())
    try {
        const userName: any = localStorage.getItem('userName')
        const password: any = localStorage.getItem('password')
        const response = await axios.post(`${baseURL}/user/signout`, body, { headers: header1 });
        if (response.data.message.code === successCode) {
            if (localStorage.getItem('userName') && localStorage.getItem('password')) {
              sessionStorage.removeItem('jwt');
              sessionStorage.removeItem('authStaff');
              localStorage.removeItem('userDetailEmail');
              localStorage.removeItem('userDetailUsername');
              localStorage.removeItem('userID');
              localStorage.removeItem('orgName');
              localStorage.removeItem('getByDate');
              localStorage.removeItem('family');
              localStorage.removeItem('given');
              dispatch(getStaffLSSuccess([]))
              dispatch(endLoading())
              navigate('/login')
            } else {
              sessionStorage.clear()
              localStorage.clear()
              dispatch(endLoading())
              dispatch(getStaffLSSuccess([]))
              navigate('/login')
            }
        } else {
          if (localStorage.getItem('userName') && localStorage.getItem('password')) {
            sessionStorage.removeItem('jwt');
            sessionStorage.removeItem('authStaff');
            localStorage.removeItem('userDetailEmail');
            localStorage.removeItem('userDetailUsername');
            localStorage.removeItem('userID');
            localStorage.removeItem('orgName');
            localStorage.removeItem('getByDate');
            localStorage.removeItem('family');
            localStorage.removeItem('given');
            dispatch(endLoading())
            dispatch(getStaffLSSuccess([]))
            navigate('/login')
          } else {
            localStorage.clear()
            sessionStorage.clear()
            dispatch(endLoading())
            dispatch(getStaffLSSuccess([]))
            navigate('/login')
          }
        }
    } catch (error) {
        console.error("Error during login:", error);
        dispatch(endLoading())
    }
}