import React, { useEffect, useState } from "react";
import { Autocomplete, DialogContent, DialogContentText } from "@mui/material";
import Dialog from '@mui/material/Dialog';
import { DateCalendar, DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { HttpLogin } from "../../utils/Http";
import bottomImage from '../../assets/images/bg.svg';
import MaskGroupImage from '../../assets/images/Mask group.svg';
import calendarMuiImage from '../../assets/images/calendarMuiImage.svg';
import patientImage from './../../assets/images/patientImage.png'
import '../../pages/Q15SlotAssign/q15Slot.css';
import { toast } from "react-toastify";
import moment from "moment";
import {
    TextField,
    FormControl,
    InputLabel,
    MenuItem,
    FormHelperText,
    Select,
    Stack,
    AlertTitle,
    Alert
} from "@mui/material";
import { useSelector } from "react-redux";
import { successCode } from "../../configuration/url";
import { primarybg } from "../../common/primary";
import { Button } from "reactstrap";

const Q15SlotAssign: React.FC = () => {

    let Q15RegistryData = {
        "id": "",
        "q15Date": "",
        "q15Slot": "",
        "q15Time": "",
        "enteredBy": "",
        "timestampCreatedAt": "",
        "timestampUpdatedAt": "",
        "breathing": true,
        "remarks": "",
        "reason": "",
        "shift": "",
        "shiftIncharge": "",
        "timeStamp": "",
        "location": "",
        "activity": "",
        "pid": "",
        "skippedScanning": true,
        "organization": ""
    }
    let [displayData, setDisplayData] = useState(new Array<any>());
    const { patientData } = useSelector((state: any) => state.Patient);
    const { staffData } = useSelector((state: any) => state.Staff);
    let [inputQ15Date, setInputQ15Date] = useState<any>('');
    let [inputUserName, setInputUserName] = useState<any>('');
    let [staffEnteredBy, setStaffEnteredBy] = useState<any>('');
    let [displayLocationData, setDisplayLocationData] = useState(new Array<any>());
    let [displayActivityData, setDisplayActivityData] = useState(new Array<any>());
    const { organization, userData } = useSelector((state: any) => state.Login);
    const [showQ15CheckView, setQ15CheckView] = useState(false);
    let [timeSlotChange, setTimeSlotChange] = useState(1);
    let [calendarChange, setCalendarChange] = useState(4);
    const [search, setSearch] = useState("");
    const [displayDialogQ15Data, setDisplayDialogQ15Data] = useState(false);
    let [showCalendarView, setShowCalendarVew] = useState(false);
    let [inputQ15Data, setInputQ15Data] = useState(Q15RegistryData);
    let [calendarDate, setCalendarDate] = useState<any>(new Date());
    const firstDay = moment((new Date(calendarDate.getFullYear(), calendarDate.getMonth(), calendarDate.getDate() - 3)), "DDD MMM DD YYYY HH:mm:ss").format("ddd");
    const secondDay = moment((new Date(calendarDate.getFullYear(), calendarDate.getMonth(), calendarDate.getDate() - 2)), "DDD MMM DD YYYY HH:mm:ss").format("ddd");
    const thirdDay = moment((new Date(calendarDate.getFullYear(), calendarDate.getMonth(), calendarDate.getDate() - 1)), "DDD MMM DD YYYY HH:mm:ss").format("ddd");
    const forthDay = moment((new Date(calendarDate.getFullYear(), calendarDate.getMonth(), calendarDate.getDate())), "DDD MMM DD YYYY HH:mm:ss").format("ddd");
    const firstDate = moment((new Date(calendarDate.getFullYear(), calendarDate.getMonth(), calendarDate.getDate() - 3)), "DDD MMM DD YYYY HH:mm:ss").format("DD");
    const secondDate = moment((new Date(calendarDate.getFullYear(), calendarDate.getMonth(), calendarDate.getDate() - 2)), "DDD MMM DD YYYY HH:mm:ss").format("DD");
    const thirdDate = moment((new Date(calendarDate.getFullYear(), calendarDate.getMonth(), calendarDate.getDate() - 1)), "DDD MMM DD YYYY HH:mm:ss").format("DD");
    const forthDate = moment((new Date(calendarDate.getFullYear(), calendarDate.getMonth(), calendarDate.getDate())), "DDD MMM DD YYYY HH:mm:ss").format("DD");
    let [alertMessage, setAlertMessage] = useState("");
    let [addPatientName, setAddPatientName] = useState("");
    let [addNewPatient, setAddNewPatient] = useState(new Array<any>());
    let [patientNewData, setPatientData] = useState(new Array<any>());
    let [shiftSlot1, setShiftSlot1] = useState(new Array<any>());
    let [shiftSlot2, setShiftSlot2] = useState(new Array<any>());
    let [shiftSlot3, setShiftSlot3] = useState(new Array<any>());
    function formatDate(epoch: any) {
        let d = new Date(epoch);
        let hours = String((d.getHours())).padStart(2, '0');
        let mins = String((d.getMinutes())).padStart(2, '0');

        return `${hours}:${mins}`;
    }
    useEffect(() => {
        let newStaffName = userData.userDetail.name[0].given + " " + userData.userDetail.name[0].family;
        setStaffEnteredBy(newStaffName);
        setInputUserName(userData.session.username);
        HttpLogin.axios().get("/api/patient/get/activePatientMob/" + organization)
            .then((newInData) => {

                if (newInData.data.message.code === successCode) {
                    setAddNewPatient(newInData.data.data !== null && newInData.data.data !== undefined && newInData.data.data);
                    setPatientData(newInData.data.data !== null && newInData.data.data !== undefined && newInData.data.data.map((k: any) => { return k.basicDetails[0].name[0].given + " " + k.basicDetails[0].name[0].family }))
                } else {
                    setAddNewPatient([]);
                    setPatientData([]);
                }
            })
        HttpLogin.axios().get("/api/q15form/get/wg2rzH0Yjj")
            .then((newInData) => {
                if (newInData.data.message.code === successCode) {
                    let newOutData = newInData.data.data.location;
                    let entries = Object.entries(newOutData).map(([key, value]) => ({
                        label: `${value}`,
                        value: `${key}`
                    }));
                    setDisplayLocationData(entries);
                } else {
                    alert(newInData.data.message.description);
                }
            })
        HttpLogin.axios().get("/api/q15form/get/l6gsqwczMR")
            .then((newInData) => {
                if (newInData.data.message.code === successCode) {
                    let newOutData = newInData.data.data.activity;
                    let entries = Object.entries(newOutData).map(([key, value]) => ({
                        label: `${value}`,
                        value: `${key}`
                    }));
                    setDisplayActivityData(entries);
                } else {
                    alert(newInData.data.message.description);
                }
            })
        HttpLogin.axios().get("/api/org/getById/" + organization)
            .then((res) => {
                if (res.data.message.code === successCode) {
                    var newResult = res.data.data.shift.startTime !== "" ? res.data.data.shift.startTime : "08:00"
                    const createTimeSlots = (fromTime: string, toTime: string, slotLength = 14 * 60) => {
                        let slotStart = new Date(fromTime).valueOf();
                        let slotEnd = new Date(fromTime).valueOf() + slotLength * 1000;
                        let endEpoch = new Date(toTime).valueOf();
                        let ob = [];
                        for (slotEnd; slotEnd <= endEpoch; slotEnd = slotEnd + slotLength * 1000) {
                            ob.push(formatDate(slotStart));
                            slotStart = slotEnd;
                        }
                        return ob;
                    }
                    const from = "2022-05-25 " + newResult;
                    const to = "2022-05-26 " + newResult;
                    const slotLength = 14 * 60; //seconds             
                    var r = createTimeSlots(from, to, slotLength);
                    setShiftSlot1(r.filter((k: any, l: any) => l <= 31));
                    setShiftSlot1(r.filter((k: any, l: any) => l >= 32 && l <= 63));
                    setShiftSlot1(r.filter((k: any, l: any) => l >= 64 && l <= 95));
                } else {

                }
            })
    }, []);

    const handleShowCalendar = () => {
        setShowCalendarVew(true);
    }

    const handleCalendarChange = (event: any) => {
        if (event === 1) {
            inputQ15Date = moment((new Date(calendarDate.getFullYear(), calendarDate.getMonth(), calendarDate.getDate() - 3)), "DDD MMM DD YYYY HH:mm:ss").format("YYYY-MM-DDTHH:mm:ss.000Z")
        } else if (event === 2) {
            inputQ15Date = moment((new Date(calendarDate.getFullYear(), calendarDate.getMonth(), calendarDate.getDate() - 2)), "DDD MMM DD YYYY HH:mm:ss").format("YYYY-MM-DDTHH:mm:ss.000Z")
        } else if (event === 3) {
            inputQ15Date = moment((new Date(calendarDate.getFullYear(), calendarDate.getMonth(), calendarDate.getDate() - 1)), "DDD MMM DD YYYY HH:mm:ss").format("YYYY-MM-DDTHH:mm:ss.000Z")
        } else if (event === 4) {
            inputQ15Date = moment((new Date(calendarDate.getFullYear(), calendarDate.getMonth(), calendarDate.getDate())), "DDD MMM DD YYYY HH:mm:ss").format("YYYY-MM-DDTHH:mm:ss.000Z")
        }
        setInputQ15Date(inputQ15Date);
        setInputQ15Data({ ...inputQ15Data });
        let newDependDate = inputQ15Date !== "" ? moment(inputQ15Date, "YYYY-MM-DDTHH:mm:ss.000+05:30").format("YYYYMMDD") : moment(new Date(), "YYYY-MM-DDTHH:mm:ss.000+05:30").format("YYYYMMDD");
        if (inputQ15Data.pid !== "" && newDependDate !== null && newDependDate !== "" && newDependDate !== undefined) {
            HttpLogin.axios().get(`/api/config/getById/${inputQ15Data.pid}/date/${newDependDate}`)
                .then((response) => {
                    if (response.data.data !== undefined) {

                        setDisplayData(response.data.data);
                        if (localStorage.getItem("CREATEQ15") !== null && localStorage.getItem("CREATEQ15") !== "" && localStorage.getItem("CREATEQ15") !== "No") {
                            let timeAddSlotChange;
                            let newCalendarChange;
                            let newCalendarDate;
                            timeAddSlotChange = localStorage.getItem("TIMESLOTCHANGE");
                            setTimeSlotChange(timeAddSlotChange !== "" && timeAddSlotChange !== null ? parseInt(timeAddSlotChange) : 1);
                            newCalendarChange = localStorage.getItem("Q15CALENDARCHANGE");
                            setCalendarChange(newCalendarChange !== "" && timeAddSlotChange !== null ? parseInt(timeAddSlotChange) : 4);
                            newCalendarDate = localStorage.getItem("Q15DATE");
                            setCalendarDate(newCalendarDate !== "" && newCalendarDate !== null ? new Date(newCalendarDate) : calendarDate);
                            inputQ15Date = moment((new Date(calendarDate.getFullYear(), calendarDate.getMonth(), calendarDate.getDate())), "DDD MMM DD YYYY HH:mm:ss").format("YYYY-MM-DDTHH:mm:ss.000Z")
                            setInputQ15Date(inputQ15Date);
                            inputQ15Data.q15Date = inputQ15Date;
                            setInputQ15Data({ ...inputQ15Data });
                            localStorage.setItem("CREATEQ15", "No");
                            setQ15CheckView(true);
                        }
                    } else {

                    }
                })
        }
        calendarChange = event;
        setCalendarChange(event);
        setCalendarDate(calendarDate);
        setDisplayDialogQ15Data(false);
    }

    const handleTimeSlotChange = (event: any) => {
        timeSlotChange = event;
        setTimeSlotChange(event);
    }

    const handleNewClose = () => {
        localStorage.setItem("CREATEQ15", "No");
        setQ15CheckView(false);
        setDisplayDialogQ15Data(false);
    }

    let [timeSlotSectionChange, setTimeSlotSectionChange] = useState(null);
    const handleDisplayDialogQ15Data = (event: any, newString: any, addData: any) => {
        if (addPatientName !== "") {
            if (addData != "") {
                inputQ15Data.id = addData.id;
                inputQ15Date = new Date(moment(addData.q15Date, "YYYYMMDD").format("YYYY-MM-DDTHH:mm:ss.000Z"));
                inputQ15Data.q15Slot = addData.q15Slot;
                inputQ15Data.enteredBy = inputUserName;
                inputQ15Data.q15Time = addData.q15Time;
                inputQ15Data.location = addData.location;
                inputQ15Data.activity = addData.activity;
                inputQ15Data.remarks = addData.remarks;
                inputQ15Data.reason = addData.reason;
                inputQ15Data.q15Date = inputQ15Date;
                setInputQ15Date(inputQ15Date);
                setInputQ15Data({ ...inputQ15Data });
            } else {
                if (calendarChange === 1) {
                    inputQ15Date = new Date(calendarDate.getFullYear(), calendarDate.getMonth(), calendarDate.getDate() - 3);
                } else if (calendarChange === 2) {
                    inputQ15Date = new Date(calendarDate.getFullYear(), calendarDate.getMonth(), calendarDate.getDate() - 2);
                } else if (calendarChange === 3) {
                    inputQ15Date = new Date(calendarDate.getFullYear(), calendarDate.getMonth(), calendarDate.getDate() - 1);
                } else if (calendarChange === 4) {
                    inputQ15Date = new Date(calendarDate.getFullYear(), calendarDate.getMonth(), calendarDate.getDate());
                }
                inputQ15Data.q15Date = inputQ15Date;
                setInputQ15Date(inputQ15Date);
                inputQ15Data.q15Slot = event;
                inputQ15Data.q15Time = newString;
                inputQ15Data.location = "";
                inputQ15Data.activity = "";
                inputQ15Data.remarks = "";
                inputQ15Data.reason = "";
                inputQ15Data.enteredBy = inputUserName;
                timeSlotSectionChange = event;
                setTimeSlotSectionChange(event);
                setInputQ15Data({ ...inputQ15Data });
            }
            inputQ15Data.shift = ""
            inputQ15Data.q15Date = moment(inputQ15Data.q15Date, "YYYY-MM-DDTHH:mm:ss.000Z").format("YYYYMMDD");
            HttpLogin.axios().get("/api/PSConfig/getByDateAndOrganization/" + inputQ15Data.q15Date + `/${organization}`)
                .then((res) => {

                    if (res.data.data !== undefined) {
                        let newShiftIncharge = res.data.data.shift.filter((l: any) => (l.shiftName === inputQ15Data.shift)).map((k: any) => { return k.rnIncharge })

                        inputQ15Data.shiftIncharge = newShiftIncharge !== null ? newShiftIncharge[0] : "";
                        setInputQ15Data({ ...inputQ15Data });
                    } else {
                        // alert(response.data.message.description);
                    }
                })
            localStorage.setItem("TIMESLOTCHANGE", timeSlotChange.toString());
            localStorage.setItem("Q15CALENDARCHANGE", calendarChange.toString());
            localStorage.setItem("Q15DATE", inputQ15Date);
            setDisplayDialogQ15Data(true);
        } else {
            toast.error("Please select a Patient");
            setDisplayDialogQ15Data(false);
        }
    }
    const [showAlertcr, setShowAlertcr] = useState(false);
    const [showAlerter, setShowAlerter] = useState(false);
    const handleQ15ClickChange = () => {
        localStorage.setItem("CREATEQ15", "Yes");
        if (inputQ15Data.q15Date) {
            inputQ15Data.q15Date = moment(inputQ15Data.q15Date).format("YYYYMMDD");
        }
        inputQ15Data.timeStamp = moment().format("YYYYMMDDHHmmss");
        inputQ15Data.skippedScanning = true
        inputQ15Data.organization = organization
        setInputQ15Data({ ...inputQ15Data });
        if (inputQ15Data.activity === "" || inputQ15Data.location === "") {
            setShowAlerter(true);
            setTimeout(() => {
                setShowAlerter(false);
            }, 1000);
            setAlertMessage("Some data is missing");
        } else {
            const url = "/api/config/register";
            HttpLogin.axios().post(url, inputQ15Data, {
                headers: {
                    'Content-Type': 'application/json',
                    "Access-Control-Allow-Origin": "*",
                    "Access-Control-Allow-Methods": "*",
                    "Access-Control-Allow-Headers": "*"
                }
            })
                .then(res => {

                    if (res.data.message.code === successCode) {
                        toast.success(res.data.message.description);
                        handleNewClose();
                    } else {
                        toast.error(res.data.message.description)
                        handleNewClose();
                    }
                    let newDependDate = moment(inputQ15Data.q15Date).format("YYYYMMDD");
                    HttpLogin.axios().get(`/api/config/getById/${inputQ15Data.pid}/date/${newDependDate}`)
                        .then((response) => {

                            if (response.data.data) {

                                setDisplayData(response.data.data);
                                setTimeSlotSectionChange(null);
                            }
                        })
                        .catch((e) => {
                            console.error("Error fetching updated data:", e);
                        });
                    return res;
                })
                .catch((e) => {
                    toast.error(e);
                    return e;
                });
        }
    }


    return (
        <div className='row px-2' style={{ height: '575px', width: '97%', display: 'flex', flexDirection: 'column', paddingTop: "20px", overflow: 'hidden' }}>
            <div className="row">
                <div className="col-sm-3">
                  <h5>Q15 Routine</h5>
                </div>
                <div className="col-sm-5" style={{ width: '60%', justifyContent: 'end' }} >
                    <Autocomplete
                        className=""
                        id="patientName"
                        options={patientNewData}
                        getOptionLabel={(option: any) => option}
                        value={addPatientName}
                        onChange={(e, v) => {
                            setAddPatientName(v);
                            let newPatientData = addNewPatient.filter((m: any) => m.basicDetails[0].name[0].given + " " + m.basicDetails[0].name[0].family === v).map((k) => { return k.id });
                            inputQ15Data.pid = newPatientData[0];
                            let newDependDate = inputQ15Date !== "" ? moment(inputQ15Date, "YYYY-MM-DDTHH:mm:ss.000+05:30").format("YYYYMMDD") : moment(new Date(), "YYYY-MM-DDTHH:mm:ss.000+05:30").format("YYYYMMDD");
                            if (newPatientData[0] !== "" && newDependDate !== null && newDependDate !== "" && newDependDate !== undefined) {
                                setInputQ15Date(newDependDate);
                                HttpLogin.axios().get(`/api/config/getById/${newPatientData[0]}/date/${newDependDate}`)
                                    .then((response) => {
                                        if (response.data.data !== undefined) {
                                            setDisplayData(response.data.data);
                                            if (localStorage.getItem("CREATEQ15") !== null && localStorage.getItem("CREATEQ15") !== "" && localStorage.getItem("CREATEQ15") !== "No") {
                                                let timeAddSlotChange;
                                                let newCalendarChange;
                                                let newCalendarDate;

                                                timeAddSlotChange = localStorage.getItem("TIMESLOTCHANGE");
                                                setTimeSlotChange(timeAddSlotChange !== "" && timeAddSlotChange !== null ? parseInt(timeAddSlotChange) : 1);
                                                newCalendarChange = localStorage.getItem("Q15CALENDARCHANGE");
                                                setCalendarChange(newCalendarChange !== "" && timeAddSlotChange !== null ? parseInt(timeAddSlotChange) : 4);
                                                newCalendarDate = localStorage.getItem("Q15DATE");
                                                setCalendarDate(newCalendarDate !== "" && newCalendarDate !== null ? new Date(newCalendarDate) : calendarDate);

                                                inputQ15Data.q15Date = inputQ15Date;
                                                setInputQ15Data({ ...inputQ15Data });
                                                localStorage.setItem("CREATEQ15", "No");
                                                setQ15CheckView(true);
                                            }
                                        } else {

                                        }
                                    })
                            } else {
                                inputQ15Data.pid = newPatientData[0];
                            }
                            setInputQ15Data({ ...inputQ15Data });
                        }}
                        renderInput={(params: any) => (
                            <TextField
                                {...params}
                                variant="outlined"
                                label="Select Patient"
                                placeholder="Select Patient"
                                margin="none"
                                fullWidth
                            />
                        )}
                    />
                </div>
                <div className="col-sm-2"></div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                <a style={{ cursor: 'pointer' }} onClick={() => handleCalendarChange(1)}><div style={{ position: 'relative', left: '20px', top: '15px' }} className={calendarChange === 1 ? "patient-Q15-CalendarSelectOutline" : "patient-Q15-CalendarUnselectOutline"}>
                    <div style={{ position: 'relative', left: '2px', top: '49px' }} className={calendarChange === 1 ? "patient-Q15-CalendarSelectLine" : "patient-Q15-CalendarUnSelectLine"}>
                        <div style={{ position: 'relative', left: '16px', top: '-41px' }} className="patient-Q15-CalendarText">{firstDate}</div>
                        <div style={{ position: 'relative', left: '18px', top: '-32px', fontSize: '12px' }} className="patient-Q15-CalendarText">{firstDay}</div>
                    </div>
                </div></a>
                <a style={{ cursor: 'pointer' }} onClick={() => handleCalendarChange(2)}>
                    <div style={{ position: 'relative', left: '39px', top: '15px' }} className={calendarChange === 2 ? "patient-Q15-CalendarSelectOutline" : "patient-Q15-CalendarUnselectOutline"}>
                        <div style={{ position: 'relative', left: '1.5px', top: '49px' }} className={calendarChange === 2 ? "patient-Q15-CalendarSelectLine" : "patient-Q15-CalendarUnSelectLine"}>
                            <div style={{ position: 'relative', left: '16px', top: '-41px' }} className="patient-Q15-CalendarText">{secondDate}</div>
                            <div style={{ position: 'relative', left: '18px', top: '-32px', fontSize: '12px' }} className="patient-Q15-CalendarText">{secondDay}</div>
                        </div>
                    </div></a>
                <a style={{ cursor: 'pointer' }} onClick={() => handleCalendarChange(3)}><div style={{ position: 'relative', left: '58px', top: '15px' }} className={calendarChange === 3 ? "patient-Q15-CalendarSelectOutline" : "patient-Q15-CalendarUnselectOutline"}>
                    <div style={{ position: 'relative', left: '1.5px', top: '49px' }} className={calendarChange === 3 ? "patient-Q15-CalendarSelectLine" : "patient-Q15-CalendarUnSelectLine"}>
                        <div style={{ position: 'relative', left: '16px', top: '-41px' }} className="patient-Q15-CalendarText">{thirdDate}</div>
                        <div style={{ position: 'relative', left: '18px', top: '-32px', fontSize: '12px' }} className="patient-Q15-CalendarText">{thirdDay}</div>
                    </div>
                </div></a>
                <a style={{ cursor: 'pointer' }} onClick={() => handleCalendarChange(4)}><div style={{ position: 'relative', left: '77px', top: '15px' }} className={calendarChange === 4 ? "patient-Q15-CalendarSelectOutline" : "patient-Q15-CalendarUnselectOutline"}>
                    <div style={{ position: 'relative', left: '1.5px', top: '49px' }} className={calendarChange === 4 ? "patient-Q15-CalendarSelectLine" : "patient-Q15-CalendarUnSelectLine"}>
                        <div style={{ position: 'relative', left: '16px', top: '-41px' }} className="patient-Q15-CalendarText">{forthDate}</div>
                        <div style={{ position: 'relative', left: '18px', top: '-32px', fontSize: '12px' }} className="patient-Q15-CalendarText">{forthDay}</div>
                    </div>
                </div></a>
                <a style={{ cursor: 'pointer' }} onClick={handleShowCalendar}><div style={{ position: 'relative', left: '96px', top: '15px' }} className="patient-Q15-CalendarUnselectOutline">
                    <img style={{ position: 'relative', left: '17px', top: '21px', width: '32px', height: '22px' }} src={calendarMuiImage} />
                </div>
                </a>
            </div>

            <div>
                <div style={{ position: 'relative', left: '20px', top: '20px', display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                    <a style={{ cursor: 'pointer' }} onClick={() => handleTimeSlotChange(1)}><div className={timeSlotChange === 1 ? "patient-Q15-TimeSlotSelect" : "patient-Q15-TimeSlotUnSelect"}>0:00 - 5:45</div></a>
                    <a style={{ cursor: 'pointer' }} onClick={() => handleTimeSlotChange(2)}><div style={{ position: 'relative', left: '25px' }} className={timeSlotChange === 2 ? "patient-Q15-TimeSlotSelect" : "patient-Q15-TimeSlotUnSelect"}>6:00 - 11:45</div></a>
                    <a style={{ cursor: 'pointer' }} onClick={() => handleTimeSlotChange(3)}><div style={{ position: 'relative', left: '50px' }} className={timeSlotChange === 3 ? "patient-Q15-TimeSlotSelect" : "patient-Q15-TimeSlotUnSelect"}>12:00 - 17:45</div></a>
                    <a style={{ cursor: 'pointer' }} onClick={() => handleTimeSlotChange(4)}><div style={{ position: 'relative', left: '73px' }} className={timeSlotChange === 4 ? "patient-Q15-TimeSlotSelect" : "patient-Q15-TimeSlotUnSelect"}>18:00 - 23:45</div></a>
                </div>
                <div style={{ position: 'relative', left: '-105px', top: '39px', display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                    <a style={{ cursor: 'pointer' }} onClick={() => handleTimeSlotChange(1)}><div className={timeSlotChange === 1 ? "patient-Q15-TimeSlotSelectLine" : ""}></div></a>
                    <a style={{ cursor: 'pointer' }} onClick={() => handleTimeSlotChange(2)}><div style={{ position: 'relative', left: '99px' }} className={timeSlotChange === 2 ? "patient-Q15-TimeSlotSelectLine" : ""}></div></a>
                    <a style={{ cursor: 'pointer' }} onClick={() => handleTimeSlotChange(3)}><div style={{ position: 'relative', left: '208px' }} className={timeSlotChange === 3 ? "patient-Q15-TimeSlotSelectLine" : ""}></div></a>
                    <a style={{ cursor: 'pointer' }} onClick={() => handleTimeSlotChange(4)}><div style={{ position: 'relative', left: '322px' }} className={timeSlotChange === 4 ? "patient-Q15-TimeSlotSelectLine" : ""}></div></a>
                </div>
                <div>
                    {timeSlotChange === 1 ? <>
                        <div style={{ position: 'relative', left: '42px', top: '51px', display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                            {displayData.filter(i => i.q15Slot === "A00" && i.pid === inputQ15Data.pid && i.q15Date === moment(inputQ15Date).format("YYYYMMDD")).map((k) => { return k; }).length > 0 ?
                                displayData.filter(i => i.q15Slot === "A00" && i.pid === inputQ15Data.pid && i.q15Date === moment(inputQ15Date).format("YYYYMMDD")).map(k => (
                                    <a style={{ cursor: 'pointer', height: '50px' }} onClick={() => handleDisplayDialogQ15Data("A00", "00:00-00:14", k)}>
                                        <div style={{ background: timeSlotSectionChange === "A00" ? "#4977D9" : "", height: '100%' }} className="patient-Q15-SlotSection">
                                            <>
                                                <img src={MaskGroupImage} style={{ width: '103px', height: '47px', borderRadius: '5px', position: 'relative', left: '-35px', top: '-13.1px' }}></img>
                                                <span style={{ position: 'relative', top: '-51px' }} className="patient-Q15-OccupiedSlot-Text">{k.location + "-" + k.activity}</span>
                                            </>
                                        </div>
                                    </a>)) : <a style={{ cursor: 'pointer', height: '50px' }} onClick={() => handleDisplayDialogQ15Data("A00", "00:00-00:14", "")}>
                                    <div style={{ background: timeSlotSectionChange === "A00" ? "#4977D9" : "", height: '100%', display: 'flex', alignItems: 'center'}} className="patient-Q15-SlotSection">
                                        <span style={{ color: timeSlotSectionChange === "A00" ? "#FFF" : "" }} className="patient-Q15-SlotSection-Text">00.00</span>
                                    </div>
                                </a>}
                            {displayData.filter(i => i.q15Slot === "B00" && i.pid === inputQ15Data.pid && i.q15Date === moment(inputQ15Date).format("YYYYMMDD")).map((k) => { return k; }).length > 0 ? displayData.filter(i => i.q15Slot === "B00" && i.pid === inputQ15Data.pid && i.q15Date === moment(inputQ15Date).format("YYYYMMDD")).map(k => (
                                <a style={{ cursor: 'pointer', height: '50px' }} onClick={() => handleDisplayDialogQ15Data("B00", "00:15-00:29", k)}>
                                    <div style={{ position: 'relative', left: '10px', background: timeSlotSectionChange === "B00" ? "#4977D9" : "", height: '100%' }} className="patient-Q15-SlotSection">
                                        <><img src={MaskGroupImage} style={{ width: '103px', height: '47px', borderRadius: '5px', position: 'relative', left: '-35px', top: '-13.1px' }}></img>
                                            <span style={{ position: 'relative', top: '-51px' }} className="patient-Q15-OccupiedSlot-Text">{k.location + "-" + k.activity}</span></>
                                    </div></a>)) :
                                <a style={{ cursor: 'pointer',height: '50px' }} onClick={() => handleDisplayDialogQ15Data("B00", "00:15-00:29", "")}>
                                    <div style={{ position: 'relative', left: '10px', background: timeSlotSectionChange === "B00" ? "#4977D9" : "", height: '100%', display: 'flex', alignItems: 'center' }} className="patient-Q15-SlotSection">
                                        <span style={{ color: timeSlotSectionChange === "B00" ? "#FFF" : "" }} className="patient-Q15-SlotSection-Text">00.15</span>
                                    </div></a>}
                            {displayData.filter(i => i.q15Slot === "C00" && i.pid === inputQ15Data.pid && i.q15Date === moment(inputQ15Date).format("YYYYMMDD")).map((k) => { return k; }).length > 0 ? displayData.filter(i => i.q15Slot === "C00" && i.pid === inputQ15Data.pid && i.q15Date === moment(inputQ15Date).format("YYYYMMDD")).map(k => (
                                <a style={{ cursor: 'pointer', height: '50px' }} onClick={() => handleDisplayDialogQ15Data("C00", "00:30-00:44", k)}>
                                    <div style={{ position: 'relative', left: '20px', background: timeSlotSectionChange === "C00" ? "#4977D9" : "", height: '100%' }} className="patient-Q15-SlotSection">
                                        <><img src={MaskGroupImage} style={{ width: '103px', height: '47px', borderRadius: '5px', position: 'relative', left: '-35px', top: '-13.1px' }}></img>
                                            <span style={{ position: 'relative', top: '-51px' }} className="patient-Q15-OccupiedSlot-Text">{k.location + "-" + k.activity}</span></>
                                    </div></a>)) :
                                <a style={{ cursor: 'pointer', height: '50px' }} onClick={() => handleDisplayDialogQ15Data("C00", "00:30-00:44", "")}>
                                    <div style={{ position: 'relative', left: '20px', background: timeSlotSectionChange === "C00" ? "#4977D9" : "", height: '100%', display: 'flex', alignItems: 'center' }} className="patient-Q15-SlotSection">
                                        <span style={{ color: timeSlotSectionChange === "C00" ? "#FFF" : "" }} className="patient-Q15-SlotSection-Text">00.30</span>
                                    </div></a>}
                            {displayData.filter(i => i.q15Slot === "D00" && i.pid === inputQ15Data.pid && i.q15Date === moment(inputQ15Date).format("YYYYMMDD")).map((k) => { return k; }).length > 0 ? displayData.filter(i => i.q15Slot === "D00" && i.pid === inputQ15Data.pid && i.q15Date === moment(inputQ15Date).format("YYYYMMDD")).map(k => (
                                <a style={{ cursor: 'pointer', height: '50px' }} onClick={() => handleDisplayDialogQ15Data("D00", "00:45-00:59", k)}>
                                    <div style={{ position: 'relative', left: '30px', background: timeSlotSectionChange === "D00" ? "#4977D9" : "", height: '100%' }} className="patient-Q15-SlotSection">
                                        <><img src={MaskGroupImage} style={{ width: '103px', height: '47px', borderRadius: '5px', position: 'relative', left: '-35px', top: '-13.1px' }}></img>
                                            <span style={{ position: 'relative', top: '-51px' }} className="patient-Q15-OccupiedSlot-Text">{k.location + "-" + k.activity}</span></>
                                    </div></a>)) :
                                <a style={{ cursor: 'pointer', height: '50px' }} onClick={() => handleDisplayDialogQ15Data("D00", "00:45-00:59", "")}>
                                    <div style={{ position: 'relative', left: '30px', background: timeSlotSectionChange === "D00" ? "#4977D9" : "", height: '100%', display: 'flex', alignItems: 'center' }} className="patient-Q15-SlotSection">
                                        <span style={{ color: timeSlotSectionChange === "D00" ? "#FFF" : "" }} className="patient-Q15-SlotSection-Text">00.45</span>
                                    </div></a>}
                        </div>
                        <div style={{ position: 'relative', left: '42px', top: '59px', display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                            {displayData.filter(i => i.q15Slot === "A01" && i.pid === inputQ15Data.pid && i.q15Date === moment(inputQ15Date).format("YYYYMMDD")).map((k) => { return k; }).length > 0 ? displayData.filter(i => i.q15Slot === "A01" && i.pid === inputQ15Data.pid && i.q15Date === moment(inputQ15Date).format("YYYYMMDD")).map(k => (
                                <a style={{ cursor: 'pointer', height: '50px' }} onClick={() => handleDisplayDialogQ15Data("A01", "01:00-01:14", k)}>
                                    <div style={{ background: timeSlotSectionChange === "A01" ? "#4977D9" : "", height: '100%' }} className="patient-Q15-SlotSection">
                                        <><img src={MaskGroupImage} style={{ width: '103px', height: '47px', borderRadius: '5px', position: 'relative', left: '-35px', top: '-13.1px' }}></img>
                                            <span style={{ position: 'relative', top: '-51px' }} className="patient-Q15-OccupiedSlot-Text">{k.location + "-" + k.activity}</span></>
                                    </div></a>)) :
                                <a style={{ cursor: 'pointer', height: '50px' }} onClick={() => handleDisplayDialogQ15Data("A01", "01:00-01:14", "")}>
                                    <div style={{ background: timeSlotSectionChange === "A01" ? "#4977D9" : "", height: '100%', display: 'flex', alignItems: 'center' }} className="patient-Q15-SlotSection">
                                        <span style={{ color: timeSlotSectionChange === "A01" ? "#FFF" : "" }} className="patient-Q15-SlotSection-Text">01.00</span>
                                    </div></a>}
                            {displayData.filter(i => i.q15Slot === "B01" && i.pid === inputQ15Data.pid && i.q15Date === moment(inputQ15Date).format("YYYYMMDD")).map((k) => { return k; }).length > 0 ? displayData.filter(i => i.q15Slot === "B01" && i.pid === inputQ15Data.pid && i.q15Date === moment(inputQ15Date).format("YYYYMMDD")).map(k => (
                                <a style={{ cursor: 'pointer', height: '50px' }} onClick={() => handleDisplayDialogQ15Data("B01", "01:15-01:29", k)}>
                                    <div style={{ position: 'relative', left: '10px', background: timeSlotSectionChange === "B01" ? "#4977D9" : "", height: '100%' }} className="patient-Q15-SlotSection">
                                        <><img src={MaskGroupImage} style={{ width: '103px', height: '47px', borderRadius: '5px', position: 'relative', left: '-35px', top: '-13.1px' }}></img>
                                            <span style={{ position: 'relative', top: '-51px' }} className="patient-Q15-OccupiedSlot-Text">{k.location + "-" + k.activity}</span></>
                                    </div></a>)) :
                                <a style={{ cursor: 'pointer', height: '50px' }} onClick={() => handleDisplayDialogQ15Data("B01", "01:15-01:29", "")}>
                                    <div style={{ position: 'relative', left: '10px', background: timeSlotSectionChange === "B01" ? "#4977D9" : "", height: '100%', display: 'flex', alignItems: 'center' }} className="patient-Q15-SlotSection">
                                        <span style={{ color: timeSlotSectionChange === "B01" ? "#FFF" : "" }} className="patient-Q15-SlotSection-Text">01.15</span>
                                    </div></a>}
                            {displayData.filter(i => i.q15Slot === "C01" && i.pid === inputQ15Data.pid && i.q15Date === moment(inputQ15Date).format("YYYYMMDD")).map((k) => { return k; }).length > 0 ? displayData.filter(i => i.q15Slot === "C01" && i.pid === inputQ15Data.pid && i.q15Date === moment(inputQ15Date).format("YYYYMMDD")).map(k => (
                                <a style={{ cursor: 'pointer', height: '50px' }} onClick={() => handleDisplayDialogQ15Data("C01", "01:30-01:44", k)}>
                                    <div style={{ position: 'relative', left: '20px', background: timeSlotSectionChange === "C01" ? "#4977D9" : "", height: '100%' }} className="patient-Q15-SlotSection">
                                        <><img src={MaskGroupImage} style={{ width: '103px', height: '47px', borderRadius: '5px', position: 'relative', left: '-35px', top: '-13.1px' }}></img>
                                            <span style={{ position: 'relative', top: '-51px' }} className="patient-Q15-OccupiedSlot-Text">{k.location + "-" + k.activity}</span></>
                                    </div></a>)) :
                                <a style={{ cursor: 'pointer', height: '50px' }} onClick={() => handleDisplayDialogQ15Data("C01", "01:30-01:44", "")}>
                                    <div style={{ position: 'relative', left: '20px', background: timeSlotSectionChange === "C01" ? "#4977D9" : "", height: '100%', display: 'flex', alignItems: 'center' }} className="patient-Q15-SlotSection">
                                        <span style={{ color: timeSlotSectionChange === "C01" ? "#FFF" : "" }} className="patient-Q15-SlotSection-Text">01.30</span>
                                    </div></a>}
                            {displayData.filter(i => i.q15Slot === "D01" && i.pid === inputQ15Data.pid && i.q15Date === moment(inputQ15Date).format("YYYYMMDD")).map((k) => { return k; }).length > 0 ? displayData.filter(i => i.q15Slot === "D01" && i.pid === inputQ15Data.pid && i.q15Date === moment(inputQ15Date).format("YYYYMMDD")).map(k => (
                                <a style={{ cursor: 'pointer', height: '50px' }} onClick={() => handleDisplayDialogQ15Data("D01", "01:45-01:59", k)}>
                                    <div style={{ position: 'relative', left: '30px', background: timeSlotSectionChange === "D01" ? "#4977D9" : "" , height: '100%'}} className="patient-Q15-SlotSection">
                                        <><img src={MaskGroupImage} style={{ width: '103px', height: '47px', borderRadius: '5px', position: 'relative', left: '-35px', top: '-13.1px' }}></img>
                                            <span style={{ position: 'relative', top: '-51px' }} className="patient-Q15-OccupiedSlot-Text">{k.location + "-" + k.activity}</span></>
                                    </div></a>)) :
                                <a style={{ cursor: 'pointer', height: '50px' }} onClick={() => handleDisplayDialogQ15Data("D01", "01:45-01:59", "")}>
                                    <div style={{ position: 'relative', left: '30px', background: timeSlotSectionChange === "D01" ? "#4977D9" : "", height: '100%', display: 'flex', alignItems: 'center' }} className="patient-Q15-SlotSection">
                                        <span style={{ color: timeSlotSectionChange === "D01" ? "#FFF" : "" }} className="patient-Q15-SlotSection-Text">01.45</span>
                                    </div></a>}
                        </div>
                        <div style={{ position: 'relative', left: '42px', top: '66px', display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                            {displayData.filter(i => i.q15Slot === "A02" && i.pid === inputQ15Data.pid && i.q15Date === moment(inputQ15Date).format("YYYYMMDD")).map((k) => { return k; }).length > 0 ? displayData.filter(i => i.q15Slot === "A02" && i.pid === inputQ15Data.pid && i.q15Date === moment(inputQ15Date).format("YYYYMMDD")).map(k => (
                                <a style={{ cursor: 'pointer', height: '50px' }} onClick={() => handleDisplayDialogQ15Data("A02", "02:00-02:14", k)}>
                                    <div style={{ background: timeSlotSectionChange === "A02" ? "#4977D9" : "", height: '100%' }} className="patient-Q15-SlotSection">
                                        <><img src={MaskGroupImage} style={{ width: '103px', height: '47px', borderRadius: '5px', position: 'relative', left: '-35px', top: '-13.1px' }}></img>
                                            <span style={{ position: 'relative', top: '-51px' }} className="patient-Q15-OccupiedSlot-Text">{k.location + "-" + k.activity}</span></>
                                    </div></a>)) :
                                <a style={{ cursor: 'pointer', height: '50px' }} onClick={() => handleDisplayDialogQ15Data("A02", "02:00-02:14", "")}>
                                    <div style={{ background: timeSlotSectionChange === "A02" ? "#4977D9" : "", height: '100%', display: 'flex', alignItems: 'center' }} className="patient-Q15-SlotSection">
                                        <span style={{ color: timeSlotSectionChange === "A02" ? "#FFF" : "" }} className="patient-Q15-SlotSection-Text">02.00</span>
                                    </div></a>}
                            {displayData.filter(i => i.q15Slot === "B02" && i.pid === inputQ15Data.pid && i.q15Date === moment(inputQ15Date).format("YYYYMMDD")).map((k) => { return k; }).length > 0 ? displayData.filter(i => i.q15Slot === "B02" && i.pid === inputQ15Data.pid && i.q15Date === moment(inputQ15Date).format("YYYYMMDD")).map(k => (
                                <a style={{ cursor: 'pointer', height: '50px' }} onClick={() => handleDisplayDialogQ15Data("B02", "02:15-02:29", k)}>
                                    <div style={{ position: 'relative', left: '10px', background: timeSlotSectionChange === "B02" ? "#4977D9" : "", height: '100%' }} className="patient-Q15-SlotSection">
                                        <><img src={MaskGroupImage} style={{ width: '103px', height: '47px', borderRadius: '5px', position: 'relative', left: '-35px', top: '-13.1px' }}></img>
                                            <span style={{ position: 'relative', top: '-51px' }} className="patient-Q15-OccupiedSlot-Text">{k.location + "-" + k.activity}</span></>
                                    </div></a>)) :
                                <a style={{ cursor: 'pointer',height: '50px' }} onClick={() => handleDisplayDialogQ15Data("B02", "02:15-02:29", "")}>
                                    <div style={{ position: 'relative', left: '10px', background: timeSlotSectionChange === "B02" ? "#4977D9" : "", height: '100%', display: 'flex', alignItems: 'center' }} className="patient-Q15-SlotSection">
                                        <span style={{ color: timeSlotSectionChange === "B02" ? "#FFF" : "" }} className="patient-Q15-SlotSection-Text">02.15</span>
                                    </div></a>}
                            {displayData.filter(i => i.q15Slot === "C02" && i.pid === inputQ15Data.pid && i.q15Date === moment(inputQ15Date).format("YYYYMMDD")).map((k) => { return k; }).length > 0 ? displayData.filter(i => i.q15Slot === "C02" && i.pid === inputQ15Data.pid && i.q15Date === moment(inputQ15Date).format("YYYYMMDD")).map(k => (
                                <a style={{ cursor: 'pointer', height: '50px' }} onClick={() => handleDisplayDialogQ15Data("C02", "02:30-02:44", k)}>
                                    <div style={{ position: 'relative', left: '20px', background: timeSlotSectionChange === "C02" ? "#4977D9" : "", height: '100%' }} className="patient-Q15-SlotSection">
                                        <><img src={MaskGroupImage} style={{ width: '103px', height: '47px', borderRadius: '5px', position: 'relative', left: '-35px', top: '-13.1px' }}></img>
                                            <span style={{ position: 'relative', top: '-51px' }} className="patient-Q15-OccupiedSlot-Text">{k.location + "-" + k.activity}</span></>
                                    </div></a>)) :
                                <a style={{ cursor: 'pointer', height: '50px' }} onClick={() => handleDisplayDialogQ15Data("C02", "02:30-02:44", "")}>
                                    <div style={{ position: 'relative', left: '20px', background: timeSlotSectionChange === "C02" ? "#4977D9" : "", height: '100%', display: 'flex', alignItems: 'center' }} className="patient-Q15-SlotSection">
                                        <span style={{ color: timeSlotSectionChange === "C02" ? "#FFF" : "" }} className="patient-Q15-SlotSection-Text">02.30</span>
                                    </div></a>}
                            {displayData.filter(i => i.q15Slot === "D02" && i.pid === inputQ15Data.pid && i.q15Date === moment(inputQ15Date).format("YYYYMMDD")).map((k) => { return k; }).length > 0 ? displayData.filter(i => i.q15Slot === "D02" && i.pid === inputQ15Data.pid && i.q15Date === moment(inputQ15Date).format("YYYYMMDD")).map(k => (
                                <a style={{ cursor: 'pointer', height: '50px' }} onClick={() => handleDisplayDialogQ15Data("D02", "02:45-02:59", k)}>
                                    <div style={{ position: 'relative', left: '30px', background: timeSlotSectionChange === "D02" ? "#4977D9" : "", height: '100%' }} className="patient-Q15-SlotSection">
                                        <><img src={MaskGroupImage} style={{ width: '103px', height: '47px', borderRadius: '5px', position: 'relative', left: '-35px', top: '-13.1px' }}></img>
                                            <span style={{ position: 'relative', top: '-51px' }} className="patient-Q15-OccupiedSlot-Text">{k.location + "-" + k.activity}</span></>
                                    </div></a>)) :
                                <a style={{ cursor: 'pointer', height: '50px' }} onClick={() => handleDisplayDialogQ15Data("D02", "02:45-02:59", "")}>
                                    <div style={{ position: 'relative', left: '30px', background: timeSlotSectionChange === "D02" ? "#4977D9" : "", height: '100%', display: 'flex', alignItems: 'center' }} className="patient-Q15-SlotSection">
                                        <span style={{ color: timeSlotSectionChange === "D02" ? "#FFF" : "" }} className="patient-Q15-SlotSection-Text">02.45</span>
                                    </div></a>
                            }
                        </div>
                        <div style={{ position: 'relative', left: '42px', top: '73px', display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                            {displayData.filter(i => i.q15Slot === "A03" && i.pid === inputQ15Data.pid && i.q15Date === moment(inputQ15Date).format("YYYYMMDD")).map((k) => { return k; }).length > 0 ? displayData.filter(i => i.q15Slot === "A03" && i.pid === inputQ15Data.pid && i.q15Date === moment(inputQ15Date).format("YYYYMMDD")).map(k => (
                                <a style={{ cursor: 'pointer', height: '50px' }} onClick={() => handleDisplayDialogQ15Data("A03", "03:00-03:14", k)}>
                                    <div style={{ background: timeSlotSectionChange === "A03" ? "#4977D9" : "", height: '100%' }} className="patient-Q15-SlotSection">
                                        <><img src={MaskGroupImage} style={{ width: '103px', height: '47px', borderRadius: '5px', position: 'relative', left: '-35px', top: '-13.1px' }}></img>
                                            <span style={{ position: 'relative', top: '-51px' }} className="patient-Q15-OccupiedSlot-Text">{k.location + "-" + k.activity}</span></>
                                    </div></a>)) :
                                <a style={{ cursor: 'pointer', height: '50px' }} onClick={() => handleDisplayDialogQ15Data("A03", "03:00-03:14", "")}>
                                    <div style={{ background: timeSlotSectionChange === "A03" ? "#4977D9" : "", height: '100%', display: 'flex', alignItems: 'center' }} className="patient-Q15-SlotSection">
                                        <span style={{ color: timeSlotSectionChange === "A03" ? "#FFF" : "" }} className="patient-Q15-SlotSection-Text">03.00</span>
                                    </div></a>}
                            {displayData.filter(i => i.q15Slot === "B03" && i.pid === inputQ15Data.pid && i.q15Date === moment(inputQ15Date).format("YYYYMMDD")).map((k) => { return k; }).length > 0 ? displayData.filter(i => i.q15Slot === "B03" && i.pid === inputQ15Data.pid && i.q15Date === moment(inputQ15Date).format("YYYYMMDD")).map(k => (
                                <a style={{ cursor: 'pointer', height: '50px' }} onClick={() => handleDisplayDialogQ15Data("B03", "03:15-03:29", k)}>
                                    <div style={{ position: 'relative', left: '10px', background: timeSlotSectionChange === "B03" ? "#4977D9" : "", height: '100%' }} className="patient-Q15-SlotSection">
                                        <><img src={MaskGroupImage} style={{ width: '103px', height: '47px', borderRadius: '5px', position: 'relative', left: '-35px', top: '-13.1px' }}></img>
                                            <span style={{ position: 'relative', top: '-51px' }} className="patient-Q15-OccupiedSlot-Text">{k.location + "-" + k.activity}</span></>
                                    </div></a>)) :
                                <a style={{ cursor: 'pointer', height: '50px' }} onClick={() => handleDisplayDialogQ15Data("B03", "03:15-03:29", "")}>
                                    <div style={{ position: 'relative', left: '10px', background: timeSlotSectionChange === "B03" ? "#4977D9" : "", height: '100%', display: 'flex', alignItems: 'center' }} className="patient-Q15-SlotSection">
                                        <span style={{ color: timeSlotSectionChange === "B03" ? "#FFF" : "" }} className="patient-Q15-SlotSection-Text">03.15</span>
                                    </div></a>}
                            {displayData.filter(i => i.q15Slot === "C03" && i.pid === inputQ15Data.pid && i.q15Date === moment(inputQ15Date).format("YYYYMMDD")).map((k) => { return k; }).length > 0 ? displayData.filter(i => i.q15Slot === "C03" && i.pid === inputQ15Data.pid && i.q15Date === moment(inputQ15Date).format("YYYYMMDD")).map(k => (
                                <a style={{ cursor: 'pointer', height: '50px' }} onClick={() => handleDisplayDialogQ15Data("C03", "03:30-03:44", k)}>
                                    <div style={{ position: 'relative', left: '20px', background: timeSlotSectionChange === "C03" ? "#4977D9" : "", height: '100%' }} className="patient-Q15-SlotSection">
                                        <><img src={MaskGroupImage} style={{ width: '103px', height: '47px', borderRadius: '5px', position: 'relative', left: '-35px', top: '-13.1px' }}></img>
                                            <span style={{ position: 'relative', top: '-51px' }} className="patient-Q15-OccupiedSlot-Text">{k.location + "-" + k.activity}</span></>
                                    </div></a>)) :
                                <a style={{ cursor: 'pointer', height: '50px' }} onClick={() => handleDisplayDialogQ15Data("C03", "03:30-03:44", "")}>
                                    <div style={{ position: 'relative', left: '20px', background: timeSlotSectionChange === "C03" ? "#4977D9" : "", height: '100%', display: 'flex', alignItems: 'center' }} className="patient-Q15-SlotSection">
                                        <span style={{ color: timeSlotSectionChange === "C03" ? "#FFF" : "" }} className="patient-Q15-SlotSection-Text">03.30</span>
                                    </div></a>}
                            {displayData.filter(i => i.q15Slot === "D03" && i.pid === inputQ15Data.pid && i.q15Date === moment(inputQ15Date).format("YYYYMMDD")).map((k) => { return k; }).length > 0 ? displayData.filter(i => i.q15Slot === "D03" && i.pid === inputQ15Data.pid && i.q15Date === moment(inputQ15Date).format("YYYYMMDD")).map(k => (
                                <a style={{ cursor: 'pointer', height: '50px' }} onClick={() => handleDisplayDialogQ15Data("D03", "03:45-03:59", k)}>
                                    <div style={{ position: 'relative', left: '30px', background: timeSlotSectionChange === "D03" ? "#4977D9" : "", height: '100%' }} className="patient-Q15-SlotSection">
                                        <><img src={MaskGroupImage} style={{ width: '103px', height: '47px', borderRadius: '5px', position: 'relative', left: '-35px', top: '-13.1px' }}></img>
                                            <span style={{ position: 'relative', top: '-51px' }} className="patient-Q15-OccupiedSlot-Text">{k.location + "-" + k.activity}</span></>
                                    </div></a>)) :
                                <a style={{ cursor: 'pointer', height: '50px' }} onClick={() => handleDisplayDialogQ15Data("D03", "03:45-03:59", "")}>
                                    <div style={{ position: 'relative', left: '30px', background: timeSlotSectionChange === "D03" ? "#4977D9" : "", height: '100%', display: 'flex', alignItems: 'center' }} className="patient-Q15-SlotSection">
                                        <span style={{ color: timeSlotSectionChange === "D03" ? "#FFF" : "" }} className="patient-Q15-SlotSection-Text">03.45</span>
                                    </div></a>}
                        </div>
                        <div style={{ position: 'relative', left: '42px', top: '80px', display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                            {displayData.filter(i => i.q15Slot === "A04" && i.pid === inputQ15Data.pid && i.q15Date === moment(inputQ15Date).format("YYYYMMDD")).map((k) => { return k; }).length > 0 ? displayData.filter(i => i.q15Slot === "A04" && i.pid === inputQ15Data.pid && i.q15Date === moment(inputQ15Date).format("YYYYMMDD")).map(k => (
                                <a style={{ cursor: 'pointer', height: '50px' }} onClick={() => handleDisplayDialogQ15Data("A04", "04:00-04:14", k)}>
                                    <div style={{ background: timeSlotSectionChange === "A04" ? "#4977D9" : "", height: '100%' }} className="patient-Q15-SlotSection">
                                        <><img src={MaskGroupImage} style={{ width: '103px', height: '47px', borderRadius: '5px', position: 'relative', left: '-35px', top: '-13.1px' }}></img>
                                            <span style={{ position: 'relative', top: '-51px' }} className="patient-Q15-OccupiedSlot-Text">{k.location + "-" + k.activity}</span></>
                                    </div></a>)) :
                                <a style={{ cursor: 'pointer', height:"50px" }} onClick={() => handleDisplayDialogQ15Data("A04", "04:00-04:14", "")}>
                                    <div style={{ background: timeSlotSectionChange === "A04" ? "#4977D9" : "" , height: '100%', display: 'flex', alignItems: 'center'}} className="patient-Q15-SlotSection">
                                        <span style={{ color: timeSlotSectionChange === "A04" ? "#FFF" : "" }} className="patient-Q15-SlotSection-Text">04.00</span>
                                    </div></a>}
                            {displayData.filter(i => i.q15Slot === "B04" && i.pid === inputQ15Data.pid && i.q15Date === moment(inputQ15Date).format("YYYYMMDD")).map((k) => { return k; }).length > 0 ? displayData.filter(i => i.q15Slot === "B04" && i.pid === inputQ15Data.pid && i.q15Date === moment(inputQ15Date).format("YYYYMMDD")).map(k => (
                                <a style={{ cursor: 'pointer', height: '50px' }} onClick={() => handleDisplayDialogQ15Data("B04", "04:15-04:29", k)}>
                                    <div style={{ position: 'relative', left: '10px', background: timeSlotSectionChange === "B04" ? "#4977D9" : "" , height: '100%'}} className="patient-Q15-SlotSection">
                                        <><img src={MaskGroupImage} style={{ width: '103px', height: '47px', borderRadius: '5px', position: 'relative', left: '-35px', top: '-13.1px' }}></img>
                                            <span style={{ position: 'relative', top: '-51px' }} className="patient-Q15-OccupiedSlot-Text">{k.location + "-" + k.activity}</span></>
                                    </div></a>)) :
                                <a style={{ cursor: 'pointer', height: '50px' }} onClick={() => handleDisplayDialogQ15Data("B04", "04:15-04:29", "")}>
                                    <div style={{ position: 'relative', left: '10px', background: timeSlotSectionChange === "B04" ? "#4977D9" : "", height: '100%', display: 'flex', alignItems: 'center' }} className="patient-Q15-SlotSection">
                                        <span style={{ color: timeSlotSectionChange === "B04" ? "#FFF" : "" }} className="patient-Q15-SlotSection-Text">04.15</span>
                                    </div></a>}
                            {displayData.filter(i => i.q15Slot === "C04" && i.pid === inputQ15Data.pid && i.q15Date === moment(inputQ15Date).format("YYYYMMDD")).map((k) => { return k; }).length > 0 ? displayData.filter(i => i.q15Slot === "C04" && i.pid === inputQ15Data.pid && i.q15Date === moment(inputQ15Date).format("YYYYMMDD")).map(k => (
                                <a style={{ cursor: 'pointer', height: '50px' }} onClick={() => handleDisplayDialogQ15Data("C04", "04:30-04:44", k)}>
                                    <div style={{ position: 'relative', left: '20px', background: timeSlotSectionChange === "C04" ? "#4977D9" : "", height: '100%' }} className="patient-Q15-SlotSection">
                                        <><img src={MaskGroupImage} style={{ width: '103px', height: '47px', borderRadius: '5px', position: 'relative', left: '-35px', top: '-13.1px' }}></img>
                                            <span style={{ position: 'relative', top: '-51px' }} className="patient-Q15-OccupiedSlot-Text">{k.location + "-" + k.activity}</span></>
                                    </div></a>)) :
                                <a style={{ cursor: 'pointer', height: '50px' }} onClick={() => handleDisplayDialogQ15Data("C04", "04:30-04:44", "")}>
                                    <div style={{ position: 'relative', left: '20px', background: timeSlotSectionChange === "C04" ? "#4977D9" : "", height: '100%', display: 'flex', alignItems: 'center' }} className="patient-Q15-SlotSection">
                                        <span style={{ color: timeSlotSectionChange === "C04" ? "#FFF" : "" }} className="patient-Q15-SlotSection-Text">04.30</span>
                                    </div></a>}
                            {displayData.filter(i => i.q15Slot === "D04" && i.pid === inputQ15Data.pid && i.q15Date === moment(inputQ15Date).format("YYYYMMDD")).map((k) => { return k; }).length > 0 ? displayData.filter(i => i.q15Slot === "D04" && i.pid === inputQ15Data.pid && i.q15Date === moment(inputQ15Date).format("YYYYMMDD")).map(k => (
                                <a style={{ cursor: 'pointer', height: '50px' }} onClick={() => handleDisplayDialogQ15Data("D04", "04:45-04:59", k)}>
                                    <div style={{ position: 'relative', left: '30px', background: timeSlotSectionChange === "D04" ? "#4977D9" : "", height: '100%' }} className="patient-Q15-SlotSection">
                                        <><img src={MaskGroupImage} style={{ width: '103px', height: '47px', borderRadius: '5px', position: 'relative', left: '-35px', top: '-13.1px' }}></img>
                                            <span style={{ position: 'relative', top: '-51px' }} className="patient-Q15-OccupiedSlot-Text">{k.location + "-" + k.activity}</span></>
                                    </div></a>)) :
                                <a style={{ cursor: 'pointer', height: '50px' }} onClick={() => handleDisplayDialogQ15Data("D04", "04:45-04:59", "")}>
                                    <div style={{ position: 'relative', left: '30px', background: timeSlotSectionChange === "D04" ? "#4977D9" : "" , height: '100%', display: 'flex', alignItems: 'center'}} className="patient-Q15-SlotSection">
                                        <span style={{ color: timeSlotSectionChange === "D04" ? "#FFF" : "" }} className="patient-Q15-SlotSection-Text">04.45</span>
                                    </div></a>}
                        </div>
                        <div style={{ position: 'relative', left: '42px', top: '87px', display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                            {displayData.filter(i => i.q15Slot === "A05" && i.pid === inputQ15Data.pid && i.q15Date === moment(inputQ15Date).format("YYYYMMDD")).map((k) => { return k; }).length > 0 ? displayData.filter(i => i.q15Slot === "A05" && i.pid === inputQ15Data.pid && i.q15Date === moment(inputQ15Date).format("YYYYMMDD")).map(k => (
                                <a style={{ cursor: 'pointer', height: '50px' }} onClick={() => handleDisplayDialogQ15Data("A05", "05:00-05:14", k)}>
                                    <div style={{ background: timeSlotSectionChange === "A05" ? "#4977D9" : "", height: '100%' }} className="patient-Q15-SlotSection">
                                        <><img src={MaskGroupImage} style={{ width: '103px', height: '47px', borderRadius: '5px', position: 'relative', left: '-35px', top: '-13.1px' }}></img>
                                            <span style={{ position: 'relative', top: '-51px' }} className="patient-Q15-OccupiedSlot-Text">{k.location + "-" + k.activity}</span></>
                                    </div></a>)) :
                                <a style={{ cursor: 'pointer', height: '50px' }} onClick={() => handleDisplayDialogQ15Data("A05", "05:00-05:14", "")}>
                                    <div style={{ background: timeSlotSectionChange === "A05" ? "#4977D9" : "", height: '100%', display: 'flex', alignItems: 'center' }} className="patient-Q15-SlotSection">
                                        <span style={{ color: timeSlotSectionChange === "A05" ? "#FFF" : "" }} className="patient-Q15-SlotSection-Text">05.00</span>
                                    </div></a>}
                            {displayData.filter(i => i.q15Slot === "B05" && i.pid === inputQ15Data.pid && i.q15Date === moment(inputQ15Date).format("YYYYMMDD")).map((k) => { return k; }).length > 0 ? displayData.filter(i => i.q15Slot === "B05" && i.pid === inputQ15Data.pid && i.q15Date === moment(inputQ15Date).format("YYYYMMDD")).map(k => (
                                <a style={{ cursor: 'pointer', height: '50px' }} onClick={() => handleDisplayDialogQ15Data("B05", "05:15-05:29", k)}>
                                    <div style={{ position: 'relative', left: '10px', background: timeSlotSectionChange === "B05" ? "#4977D9" : "", height: '100%' }} className="patient-Q15-SlotSection">
                                        <><img src={MaskGroupImage} style={{ width: '103px', height: '47px', borderRadius: '5px', position: 'relative', left: '-35px', top: '-13.1px' }}></img>
                                            <span style={{ position: 'relative', top: '-51px' }} className="patient-Q15-OccupiedSlot-Text">{k.location + "-" + k.activity}</span></>
                                    </div></a>)) :
                                <a style={{ cursor: 'pointer', height: '50px' }} onClick={() => handleDisplayDialogQ15Data("B05", "05:15-05:29", "")}>
                                    <div style={{ position: 'relative', left: '10px', background: timeSlotSectionChange === "B05" ? "#4977D9" : "", height: '100%', display: 'flex', alignItems: 'center' }} className="patient-Q15-SlotSection">
                                        <span style={{ color: timeSlotSectionChange === "B05" ? "#FFF" : "" }} className="patient-Q15-SlotSection-Text">05.15</span>
                                    </div></a>}
                            {displayData.filter(i => i.q15Slot === "C05" && i.pid === inputQ15Data.pid && i.q15Date === moment(inputQ15Date).format("YYYYMMDD")).map((k) => { return k; }).length > 0 ? displayData.filter(i => i.q15Slot === "C05" && i.pid === inputQ15Data.pid && i.q15Date === moment(inputQ15Date).format("YYYYMMDD")).map(k => (
                                <a style={{ cursor: 'pointer', height: '50px' }} onClick={() => handleDisplayDialogQ15Data("C05", "05:30-05:44", k)}>
                                    <div style={{ position: 'relative', left: '20px', background: timeSlotSectionChange === "C05" ? "#4977D9" : "", height: '100%' }} className="patient-Q15-SlotSection">
                                        <><img src={MaskGroupImage} style={{ width: '103px', height: '47px', borderRadius: '5px', position: 'relative', left: '-35px', top: '-13.1px' }}></img>
                                            <span style={{ position: 'relative', top: '-51px' }} className="patient-Q15-OccupiedSlot-Text">{k.location + "-" + k.activity}</span></>
                                    </div></a>)) :
                                <a style={{ cursor: 'pointer',height: '50px' }} onClick={() => handleDisplayDialogQ15Data("C05", "05:30-05:44", "")}>
                                    <div style={{ position: 'relative', left: '20px', background: timeSlotSectionChange === "C05" ? "#4977D9" : "", height: '100%', display: 'flex', alignItems: 'center' }} className="patient-Q15-SlotSection">
                                        <span style={{ color: timeSlotSectionChange === "C05" ? "#FFF" : "" }} className="patient-Q15-SlotSection-Text">05.30</span>
                                    </div></a>}
                            {displayData.filter(i => i.q15Slot === "D05" && i.pid === inputQ15Data.pid && i.q15Date === moment(inputQ15Date).format("YYYYMMDD")).map((k) => { return k; }).length > 0 ? displayData.filter(i => i.q15Slot === "D05" && i.pid === inputQ15Data.pid && i.q15Date === moment(inputQ15Date).format("YYYYMMDD")).map(k => (
                                <a style={{ cursor: 'pointer', height: '50px' }} onClick={() => handleDisplayDialogQ15Data("D05", "05:45-05:59", k)}>
                                    <div style={{ position: 'relative', left: '30px', background: timeSlotSectionChange === "D05" ? "#4977D9" : "", height: '100%' }} className="patient-Q15-SlotSection">
                                        <><img src={MaskGroupImage} style={{ width: '103px', height: '47px', borderRadius: '5px', position: 'relative', left: '-35px', top: '-13.1px' }}></img>
                                            <span style={{ position: 'relative', top: '-51px' }} className="patient-Q15-OccupiedSlot-Text">{k.location + "-" + k.activity}</span></>
                                    </div></a>)) :
                                <a style={{ cursor: 'pointer', height: '50px' }} onClick={() => handleDisplayDialogQ15Data("D05", "05:45-05:59", "")}>
                                    <div style={{ position: 'relative', left: '30px', background: timeSlotSectionChange === "D05" ? "#4977D9" : "", height: '100%', display: 'flex', alignItems: 'center'    }} className="patient-Q15-SlotSection">
                                        <span style={{ color: timeSlotSectionChange === "D05" ? "#FFF" : "" }} className="patient-Q15-SlotSection-Text">05.45</span>
                                    </div></a>}
                        </div>
                    </> : timeSlotChange === 2 ? <>
                        <div style={{ position: 'relative', left: '42px', top: '51px', display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                            {displayData.filter(i => i.q15Slot === "A06" && i.pid === inputQ15Data.pid && i.q15Date === moment(inputQ15Date).format("YYYYMMDD")).map((k) => { return k; }).length > 0 ? displayData.filter(i => i.q15Slot === "A06" && i.pid === inputQ15Data.pid && i.q15Date === moment(inputQ15Date).format("YYYYMMDD")).map(k => (
                                <a style={{ cursor: 'pointer', height: '50px' }} onClick={() => handleDisplayDialogQ15Data("A06", "06:00-06:14", k)}>
                                    <div style={{ background: timeSlotSectionChange === "A06" ? "#4977D9" : "", height: '100%' }} className="patient-Q15-SlotSection">
                                        <><img src={MaskGroupImage} style={{ width: '103px', height: '47px', borderRadius: '5px', position: 'relative', left: '-35px', top: '-13.1px' }}></img>
                                            <span style={{ position: 'relative', top: '-51px' }} className="patient-Q15-OccupiedSlot-Text">{k.location + "-" + k.activity}</span></>
                                    </div></a>)) :
                                <a style={{ cursor: 'pointer', height: '50px' }} onClick={() => handleDisplayDialogQ15Data("A06", "06:00-06:14", "")}>
                                    <div style={{ background: timeSlotSectionChange === "A06" ? "#4977D9" : "", height: '100%', display: 'flex', alignItems: 'center' }} className="patient-Q15-SlotSection">
                                        <span style={{ color: timeSlotSectionChange === "A06" ? "#FFF" : "" }} className="patient-Q15-SlotSection-Text">06.00</span>
                                    </div></a>}
                            {displayData.filter(i => i.q15Slot === "B06" && i.pid === inputQ15Data.pid && i.q15Date === moment(inputQ15Date).format("YYYYMMDD")).map((k) => { return k; }).length > 0 ? displayData.filter(i => i.q15Slot === "B06" && i.pid === inputQ15Data.pid && i.q15Date === moment(inputQ15Date).format("YYYYMMDD")).map(k => (
                                <a style={{ cursor: 'pointer', height: '50px' }} onClick={() => handleDisplayDialogQ15Data("B06", "06:15-06:29", k)}>
                                    <div style={{ position: 'relative', left: '10px', background: timeSlotSectionChange === "B06" ? "#4977D9" : "", height: '100%' }} className="patient-Q15-SlotSection">
                                        <><img src={MaskGroupImage} style={{ width: '103px', height: '47px', borderRadius: '5px', position: 'relative', left: '-35px', top: '-13.1px' }}></img>
                                            <span style={{ position: 'relative', top: '-51px' }} className="patient-Q15-OccupiedSlot-Text">{k.location + "-" + k.activity}</span></>
                                    </div></a>)) :
                                <a style={{ cursor: 'pointer', height: '50px' }} onClick={() => handleDisplayDialogQ15Data("B06", "06:15-06:29", "")}>
                                    <div style={{ position: 'relative', left: '10px', background: timeSlotSectionChange === "B06" ? "#4977D9" : "", height: '100%', display: 'flex', alignItems: 'center' }} className="patient-Q15-SlotSection">
                                        <span style={{ color: timeSlotSectionChange === "B06" ? "#FFF" : "" }} className="patient-Q15-SlotSection-Text">06.15</span>
                                    </div></a>}
                            {displayData.filter(i => i.q15Slot === "C06" && i.pid === inputQ15Data.pid && i.q15Date === moment(inputQ15Date).format("YYYYMMDD")).map((k) => { return k; }).length > 0 ? displayData.filter(i => i.q15Slot === "C06" && i.pid === inputQ15Data.pid && i.q15Date === moment(inputQ15Date).format("YYYYMMDD")).map(k => (
                                <a style={{ cursor: 'pointer', height: '50px' }} onClick={() => handleDisplayDialogQ15Data("C06", "06:30-06:44", k)}>
                                    <div style={{ position: 'relative', left: '20px', background: timeSlotSectionChange === "C06" ? "#4977D9" : "", height: '100%' }} className="patient-Q15-SlotSection">
                                        <><img src={MaskGroupImage} style={{ width: '103px', height: '47px', borderRadius: '5px', position: 'relative', left: '-35px', top: '-13.1px' }}></img>
                                            <span style={{ position: 'relative', top: '-51px' }} className="patient-Q15-OccupiedSlot-Text">{k.location + "-" + k.activity}</span></>
                                    </div></a>)) :
                                <a style={{ cursor: 'pointer', height: '50px' }} onClick={() => handleDisplayDialogQ15Data("C06", "06:30-06:44", "")}>
                                    <div style={{ position: 'relative', left: '20px', background: timeSlotSectionChange === "C06" ? "#4977D9" : "", height: '100%', display: 'flex', alignItems: 'center' }} className="patient-Q15-SlotSection">
                                        <span style={{ color: timeSlotSectionChange === "C06" ? "#FFF" : "" }} className="patient-Q15-SlotSection-Text">06.30</span>
                                    </div></a>}
                            {displayData.filter(i => i.q15Slot === "D06" && i.pid === inputQ15Data.pid && i.q15Date === moment(inputQ15Date).format("YYYYMMDD")).map((k) => { return k; }).length > 0 ? displayData.filter(i => i.q15Slot === "D06" && i.pid === inputQ15Data.pid && i.q15Date === moment(inputQ15Date).format("YYYYMMDD")).map(k => (
                                <a style={{ cursor: 'pointer', height: '50px' }} onClick={() => handleDisplayDialogQ15Data("D06", "06:45-06:59", k)}>
                                    <div style={{ position: 'relative', left: '30px', background: timeSlotSectionChange === "D06" ? "#4977D9" : "", height: '100%' }} className="patient-Q15-SlotSection">
                                        <><img src={MaskGroupImage} style={{ width: '103px', height: '47px', borderRadius: '5px', position: 'relative', left: '-35px', top: '-13.1px' }}></img>
                                            <span style={{ position: 'relative', top: '-51px' }} className="patient-Q15-OccupiedSlot-Text">{k.location + "-" + k.activity}</span></>
                                    </div></a>)) :
                                <a style={{ cursor: 'pointer', height: '50px' }} onClick={() => handleDisplayDialogQ15Data("D06", "06:45-06:59", "")}>
                                    <div style={{ position: 'relative', left: '30px', background: timeSlotSectionChange === "D06" ? "#4977D9" : "", height: '100%', display: 'flex', alignItems: 'center' }} className="patient-Q15-SlotSection">
                                        <span style={{ color: timeSlotSectionChange === "D06" ? "#FFF" : "" }} className="patient-Q15-SlotSection-Text">06.45</span>
                                    </div></a>}
                        </div>
                        <div style={{ position: 'relative', left: '42px', top: '59px', display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                            {displayData.filter(i => i.q15Slot === "A07" && i.pid === inputQ15Data.pid && i.q15Date === moment(inputQ15Date).format("YYYYMMDD")).map((k) => { return k; }).length > 0 ? displayData.filter(i => i.q15Slot === "A07" && i.pid === inputQ15Data.pid && i.q15Date === moment(inputQ15Date).format("YYYYMMDD")).map(k => (
                                <a style={{ cursor: 'pointer', height: '50px' }} onClick={() => handleDisplayDialogQ15Data("A07", "07:00-07:14", k)}>
                                    <div style={{ background: timeSlotSectionChange === "A07" ? "#4977D9" : "", height: '100%' }} className="patient-Q15-SlotSection">
                                        <><img src={MaskGroupImage} style={{ width: '103px', height: '47px', borderRadius: '5px', position: 'relative', left: '-35px', top: '-13.1px' }}></img>
                                            <span style={{ position: 'relative', top: '-51px' }} className="patient-Q15-OccupiedSlot-Text">{k.location + "-" + k.activity}</span></>
                                    </div></a>)) :
                                <a style={{ cursor: 'pointer', height: '50px' }} onClick={() => handleDisplayDialogQ15Data("A07", "07:00-07:14", "")}>
                                    <div style={{ background: timeSlotSectionChange === "A07" ? "#4977D9" : "", height: '100%', display: 'flex', alignItems: 'center' }} className="patient-Q15-SlotSection">
                                        <span style={{ color: timeSlotSectionChange === "A07" ? "#FFF" : "" }} className="patient-Q15-SlotSection-Text">07.00</span>
                                    </div></a>}
                            {displayData.filter(i => i.q15Slot === "B07" && i.pid === inputQ15Data.pid && i.q15Date === moment(inputQ15Date).format("YYYYMMDD")).map((k) => { return k; }).length > 0 ? displayData.filter(i => i.q15Slot === "B07" && i.pid === inputQ15Data.pid && i.q15Date === moment(inputQ15Date).format("YYYYMMDD")).map(k => (
                                <a style={{ cursor: 'pointer', height: '50px' }} onClick={() => handleDisplayDialogQ15Data("B07", "07:15-07:29", k)}>
                                    <div style={{ position: 'relative', left: '10px', background: timeSlotSectionChange === "B07" ? "#4977D9" : "", height: '100%' }} className="patient-Q15-SlotSection">
                                        <><img src={MaskGroupImage} style={{ width: '103px', height: '47px', borderRadius: '5px', position: 'relative', left: '-35px', top: '-13.1px' }}></img>
                                            <span style={{ position: 'relative', top: '-51px' }} className="patient-Q15-OccupiedSlot-Text">{k.location + "-" + k.activity}</span></>
                                    </div></a>)) :
                                <a style={{ cursor: 'pointer', height: '50px' }} onClick={() => handleDisplayDialogQ15Data("B07", "07:15-07:29", "")}>
                                    <div style={{ position: 'relative', left: '10px', background: timeSlotSectionChange === "B07" ? "#4977D9" : "", height: '100%', display: 'flex', alignItems: 'center' }} className="patient-Q15-SlotSection">
                                        <span style={{ color: timeSlotSectionChange === "B07" ? "#FFF" : "" }} className="patient-Q15-SlotSection-Text">07.15</span>
                                    </div></a>}
                            {displayData.filter(i => i.q15Slot === "C07" && i.pid === inputQ15Data.pid && i.q15Date === moment(inputQ15Date).format("YYYYMMDD")).map((k) => { return k; }).length > 0 ? displayData.filter(i => i.q15Slot === "C07" && i.pid === inputQ15Data.pid && i.q15Date === moment(inputQ15Date).format("YYYYMMDD")).map(k => (
                                <a style={{ cursor: 'pointer', height: '50px' }} onClick={() => handleDisplayDialogQ15Data("C07", "07:30-07:44", k)}>
                                    <div style={{ position: 'relative', left: '20px', background: timeSlotSectionChange === "C07" ? "#4977D9" : "", height: '100%' }} className="patient-Q15-SlotSection">
                                        <><img src={MaskGroupImage} style={{ width: '103px', height: '47px', borderRadius: '5px', position: 'relative', left: '-35px', top: '-13.1px' }}></img>
                                            <span style={{ position: 'relative', top: '-51px' }} className="patient-Q15-OccupiedSlot-Text">{k.location + "-" + k.activity}</span></>
                                    </div></a>)) :
                                <a style={{ cursor: 'pointer', height: '50px' }} onClick={() => handleDisplayDialogQ15Data("C07", "07:30-07:44", "")}>
                                    <div style={{ position: 'relative', left: '20px', background: timeSlotSectionChange === "C07" ? "#4977D9" : "", height: '100%', display: 'flex', alignItems: 'center' }} className="patient-Q15-SlotSection">
                                        <span style={{ color: timeSlotSectionChange === "C07" ? "#FFF" : "" }} className="patient-Q15-SlotSection-Text">07.30</span>
                                    </div></a>}
                            {displayData.filter(i => i.q15Slot === "D07" && i.pid === inputQ15Data.pid && i.q15Date === moment(inputQ15Date).format("YYYYMMDD")).map((k) => { return k; }).length > 0 ? displayData.filter(i => i.q15Slot === "D07" && i.pid === inputQ15Data.pid && i.q15Date === moment(inputQ15Date).format("YYYYMMDD")).map(k => (
                                <a style={{ cursor: 'pointer', height: '50px' }} onClick={() => handleDisplayDialogQ15Data("D07", "07:45-07:59", k)}>
                                    <div style={{ position: 'relative', left: '30px', background: timeSlotSectionChange === "D07" ? "#4977D9" : "" , height: '100%'}} className="patient-Q15-SlotSection">
                                        <><img src={MaskGroupImage} style={{ width: '103px', height: '47px', borderRadius: '5px', position: 'relative', left: '-35px', top: '-13.1px' }}></img>
                                            <span style={{ position: 'relative', top: '-51px' }} className="patient-Q15-OccupiedSlot-Text">{k.location + "-" + k.activity}</span></>
                                    </div></a>)) :
                                <a style={{ cursor: 'pointer', height: '50px' }} onClick={() => handleDisplayDialogQ15Data("D07", "07:45-07:59", "")}>
                                    <div style={{ position: 'relative', left: '30px', background: timeSlotSectionChange === "D07" ? "#4977D9" : "", height: '100%', display: 'flex', alignItems: 'center' }} className="patient-Q15-SlotSection">
                                        <span style={{ color: timeSlotSectionChange === "D07" ? "#FFF" : "" }} className="patient-Q15-SlotSection-Text">07.45</span>
                                    </div></a>}
                        </div>
                        <div style={{ position: 'relative', left: '42px', top: '66px', display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                            {displayData.filter(i => i.q15Slot === "A08" && i.pid === inputQ15Data.pid && i.q15Date === moment(inputQ15Date).format("YYYYMMDD")).map((k) => { return k; }).length > 0 ? displayData.filter(i => i.q15Slot === "A08" && i.pid === inputQ15Data.pid && i.q15Date === moment(inputQ15Date).format("YYYYMMDD")).map(k => (
                                <a style={{ cursor: 'pointer', height: '50px' }} onClick={() => handleDisplayDialogQ15Data("A08", "08:00-08:14", k)}>
                                    <div style={{ background: timeSlotSectionChange === "A08" ? "#4977D9" : "", height: '100%' }} className="patient-Q15-SlotSection">
                                        <><img src={MaskGroupImage} style={{ width: '103px', height: '47px', borderRadius: '5px', position: 'relative', left: '-35px', top: '-13.1px' }}></img>
                                            <span style={{ position: 'relative', top: '-51px' }} className="patient-Q15-OccupiedSlot-Text">{k.location + "-" + k.activity}</span></>
                                    </div></a>)) :
                                <a style={{ cursor: 'pointer', height: '50px' }} onClick={() => handleDisplayDialogQ15Data("A08", "08:00-08:14", "")}>
                                    <div style={{ background: timeSlotSectionChange === "A08" ? "#4977D9" : "", height: '100%', display: 'flex', alignItems: 'center' }} className="patient-Q15-SlotSection">
                                        <span style={{ color: timeSlotSectionChange === "A08" ? "#FFF" : "" }} className="patient-Q15-SlotSection-Text">08.00</span>
                                    </div></a>}
                            {displayData.filter(i => i.q15Slot === "B08" && i.pid === inputQ15Data.pid && i.q15Date === moment(inputQ15Date).format("YYYYMMDD")).map((k) => { return k; }).length > 0 ? displayData.filter(i => i.q15Slot === "B08" && i.pid === inputQ15Data.pid && i.q15Date === moment(inputQ15Date).format("YYYYMMDD")).map(k => (
                                <a style={{ cursor: 'pointer', height: '50px' }} onClick={() => handleDisplayDialogQ15Data("B08", "08:15-08:29", k)}>
                                    <div style={{ position: 'relative', left: '10px', background: timeSlotSectionChange === "B08" ? "#4977D9" : "" , height: '100%'}} className="patient-Q15-SlotSection">
                                        <><img src={MaskGroupImage} style={{ width: '103px', height: '47px', borderRadius: '5px', position: 'relative', left: '-35px', top: '-13.1px' }}></img>
                                            <span style={{ position: 'relative', top: '-51px' }} className="patient-Q15-OccupiedSlot-Text">{k.location + "-" + k.activity}</span></>
                                    </div></a>)) :
                                <a style={{ cursor: 'pointer', height: '50px' }} onClick={() => handleDisplayDialogQ15Data("B08", "08:15-08:29", "")}>
                                    <div style={{ position: 'relative', left: '10px', background: timeSlotSectionChange === "B08" ? "#4977D9" : "", height: '100%', display: 'flex', alignItems: 'center' }} className="patient-Q15-SlotSection">
                                        <span style={{ color: timeSlotSectionChange === "B08" ? "#FFF" : "" }} className="patient-Q15-SlotSection-Text">08.15</span>
                                    </div></a>}
                            {displayData.filter(i => i.q15Slot === "C08" && i.pid === inputQ15Data.pid && i.q15Date === moment(inputQ15Date).format("YYYYMMDD")).map((k) => { return k; }).length > 0 ? displayData.filter(i => i.q15Slot === "C08" && i.pid === inputQ15Data.pid && i.q15Date === moment(inputQ15Date).format("YYYYMMDD")).map(k => (
                                <a style={{ cursor: 'pointer', height: '50px' }} onClick={() => handleDisplayDialogQ15Data("C08", "08:30-08:44", k)}>
                                    <div style={{ position: 'relative', left: '20px', background: timeSlotSectionChange === "C08" ? "#4977D9" : "", height: '100%' }} className="patient-Q15-SlotSection">
                                        <><img src={MaskGroupImage} style={{ width: '103px', height: '47px', borderRadius: '5px', position: 'relative', left: '-35px', top: '-13.1px' }}></img>
                                            <span style={{ position: 'relative', top: '-51px' }} className="patient-Q15-OccupiedSlot-Text">{k.location + "-" + k.activity}</span></>
                                    </div></a>)) :
                                <a style={{ cursor: 'pointer', height: '50px' }} onClick={() => handleDisplayDialogQ15Data("C08", "08:30-08:44", "")}>
                                    <div style={{ position: 'relative', left: '20px', background: timeSlotSectionChange === "C08" ? "#4977D9" : "", height: '100%', display: 'flex', alignItems: 'center' }} className="patient-Q15-SlotSection">
                                        <span style={{ color: timeSlotSectionChange === "C08" ? "#FFF" : "" }} className="patient-Q15-SlotSection-Text">08.30</span>
                                    </div></a>}
                            {displayData.filter(i => i.q15Slot === "D08" && i.pid === inputQ15Data.pid && i.q15Date === moment(inputQ15Date).format("YYYYMMDD")).map((k) => { return k; }).length > 0 ? displayData.filter(i => i.q15Slot === "D08" && i.pid === inputQ15Data.pid && i.q15Date === moment(inputQ15Date).format("YYYYMMDD")).map(k => (
                                <a style={{ cursor: 'pointer', height: '50px' }} onClick={() => handleDisplayDialogQ15Data("D08", "08:45-08:59", k)}>
                                    <div style={{ position: 'relative', left: '30px', background: timeSlotSectionChange === "D08" ? "#4977D9" : "", height: '100%' }} className="patient-Q15-SlotSection">
                                        <><img src={MaskGroupImage} style={{ width: '103px', height: '47px', borderRadius: '5px', position: 'relative', left: '-35px', top: '-13.1px' }}></img>
                                            <span style={{ position: 'relative', top: '-51px' }} className="patient-Q15-OccupiedSlot-Text">{k.location + "-" + k.activity}</span></>
                                    </div></a>)) :
                                <a style={{ cursor: 'pointer', height: '50px' }} onClick={() => handleDisplayDialogQ15Data("D08", "08:45-08:59", "")}>
                                    <div style={{ position: 'relative', left: '30px', background: timeSlotSectionChange === "D08" ? "#4977D9" : "", height: '100%', display: 'flex', alignItems: 'center' }} className="patient-Q15-SlotSection">
                                        <span style={{ color: timeSlotSectionChange === "D08" ? "#FFF" : "" }} className="patient-Q15-SlotSection-Text">08.45</span>
                                    </div></a>}
                        </div>
                        <div style={{ position: 'relative', left: '42px', top: '73px', display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                            {displayData.filter(i => i.q15Slot === "A09" && i.pid === inputQ15Data.pid && i.q15Date === moment(inputQ15Date).format("YYYYMMDD")).map((k) => { return k; }).length > 0 ? displayData.filter(i => i.q15Slot === "A09" && i.pid === inputQ15Data.pid && i.q15Date === moment(inputQ15Date).format("YYYYMMDD")).map(k => (
                                <a style={{ cursor: 'pointer', height: '50px' }} onClick={() => handleDisplayDialogQ15Data("A09", "09:00-09:14", k)}>
                                    <div style={{ background: timeSlotSectionChange === "A09" ? "#4977D9" : "", height: '100%' }} className="patient-Q15-SlotSection">
                                        <><img src={MaskGroupImage} style={{ width: '103px', height: '47px', borderRadius: '5px', position: 'relative', left: '-35px', top: '-13.1px' }}></img>
                                            <span style={{ position: 'relative', top: '-51px' }} className="patient-Q15-OccupiedSlot-Text">{k.location + "-" + k.activity}</span></>
                                    </div></a>)) :
                                <a style={{ cursor: 'pointer', height: '50px' }} onClick={() => handleDisplayDialogQ15Data("A09", "09:00-09:14", "")}>
                                    <div style={{ background: timeSlotSectionChange === "A09" ? "#4977D9" : "", height: '100%', display: 'flex', alignItems: 'center' }} className="patient-Q15-SlotSection">
                                        <span style={{ color: timeSlotSectionChange === "A09" ? "#FFF" : "" }} className="patient-Q15-SlotSection-Text">09.00</span>
                                    </div></a>}
                            {displayData.filter(i => i.q15Slot === "B09" && i.pid === inputQ15Data.pid && i.q15Date === moment(inputQ15Date).format("YYYYMMDD")).map((k) => { return k; }).length > 0 ? displayData.filter(i => i.q15Slot === "B09" && i.pid === inputQ15Data.pid && i.q15Date === moment(inputQ15Date).format("YYYYMMDD")).map(k => (
                                <a style={{ cursor: 'pointer', height: '50px' }} onClick={() => handleDisplayDialogQ15Data("B09", "09:15-09:29", k)}>
                                    <div style={{ position: 'relative', left: '10px', background: timeSlotSectionChange === "B09" ? "#4977D9" : "", height: '100%' }} className="patient-Q15-SlotSection">
                                        <><img src={MaskGroupImage} style={{ width: '103px', height: '47px', borderRadius: '5px', position: 'relative', left: '-35px', top: '-13.1px' }}></img>
                                            <span style={{ position: 'relative', top: '-51px' }} className="patient-Q15-OccupiedSlot-Text">{k.location + "-" + k.activity}</span></>
                                    </div></a>)) :
                                <a style={{ cursor: 'pointer', height: '50px' }} onClick={() => handleDisplayDialogQ15Data("B09", "09:15-09:29", "")}>
                                    <div style={{ position: 'relative', left: '10px', background: timeSlotSectionChange === "B09" ? "#4977D9" : "", height: '100%', display: 'flex', alignItems: 'center' }} className="patient-Q15-SlotSection">
                                        <span style={{ color: timeSlotSectionChange === "B09" ? "#FFF" : "" }} className="patient-Q15-SlotSection-Text">09.15</span>
                                    </div></a>}
                            {displayData.filter(i => i.q15Slot === "C09" && i.pid === inputQ15Data.pid && i.q15Date === moment(inputQ15Date).format("YYYYMMDD")).map((k) => { return k; }).length > 0 ? displayData.filter(i => i.q15Slot === "C09" && i.pid === inputQ15Data.pid && i.q15Date === moment(inputQ15Date).format("YYYYMMDD")).map(k => (
                                <a style={{ cursor: 'pointer', height: '50px' }} onClick={() => handleDisplayDialogQ15Data("C09", "09:30-09:44", k)}>
                                    <div style={{ position: 'relative', left: '20px', background: timeSlotSectionChange === "C09" ? "#4977D9" : "", height: '100%' }} className="patient-Q15-SlotSection">
                                        <><img src={MaskGroupImage} style={{ width: '103px', height: '47px', borderRadius: '5px', position: 'relative', left: '-35px', top: '-13.1px' }}></img>
                                            <span style={{ position: 'relative', top: '-51px' }} className="patient-Q15-OccupiedSlot-Text">{k.location + "-" + k.activity}</span></>
                                    </div></a>)) :
                                <a style={{ cursor: 'pointer', height: '50px' }} onClick={() => handleDisplayDialogQ15Data("C09", "09:30-09:44", "")}>
                                    <div style={{ position: 'relative', left: '20px', background: timeSlotSectionChange === "C09" ? "#4977D9" : "", height: '100%', display: 'flex', alignItems: 'center' }} className="patient-Q15-SlotSection">
                                        <span style={{ color: timeSlotSectionChange === "C09" ? "#FFF" : "" }} className="patient-Q15-SlotSection-Text">09.30</span>
                                    </div></a>}
                            {displayData.filter(i => i.q15Slot === "D09" && i.pid === inputQ15Data.pid && i.q15Date === moment(inputQ15Date).format("YYYYMMDD")).map((k) => { return k; }).length > 0 ? displayData.filter(i => i.q15Slot === "D09" && i.pid === inputQ15Data.pid && i.q15Date === moment(inputQ15Date).format("YYYYMMDD")).map(k => (
                                <a style={{ cursor: 'pointer', height: '50px' }} onClick={() => handleDisplayDialogQ15Data("D09", "09:45-09:59", k)}>
                                    <div style={{ position: 'relative', left: '30px', background: timeSlotSectionChange === "D09" ? "#4977D9" : "", height: '100%' }} className="patient-Q15-SlotSection">
                                        <><img src={MaskGroupImage} style={{ width: '103px', height: '47px', borderRadius: '5px', position: 'relative', left: '-35px', top: '-13.1px' }}></img>
                                            <span style={{ position: 'relative', top: '-51px' }} className="patient-Q15-OccupiedSlot-Text">{k.location + "-" + k.activity}</span></>
                                    </div></a>)) :
                                <a style={{ cursor: 'pointer', height: '50px' }} onClick={() => handleDisplayDialogQ15Data("D09", "09:45-09:59", "")}>
                                    <div style={{ position: 'relative', left: '30px', background: timeSlotSectionChange === "D09" ? "#4977D9" : "", height: '100%', display: 'flex', alignItems: 'center' }} className="patient-Q15-SlotSection">
                                        <span style={{ color: timeSlotSectionChange === "D09" ? "#FFF" : "" }} className="patient-Q15-SlotSection-Text">09.45</span>
                                    </div></a>}
                        </div>
                        <div style={{ position: 'relative', left: '42px', top: '80px', display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                            {displayData.filter(i => i.q15Slot === "A10" && i.pid === inputQ15Data.pid && i.q15Date === moment(inputQ15Date).format("YYYYMMDD")).map((k) => { return k; }).length > 0 ? displayData.filter(i => i.q15Slot === "A10" && i.pid === inputQ15Data.pid && i.q15Date === moment(inputQ15Date).format("YYYYMMDD")).map(k => (
                                <a style={{ cursor: 'pointer', height: '50px' }} onClick={() => handleDisplayDialogQ15Data("A10", "10:00-10:14", k)}>
                                    <div style={{ background: timeSlotSectionChange === "A10" ? "#4977D9" : "", height: '100%' }} className="patient-Q15-SlotSection">
                                        <><img src={MaskGroupImage} style={{ width: '103px', height: '47px', borderRadius: '5px', position: 'relative', left: '-35px', top: '-13.1px' }}></img>
                                            <span style={{ position: 'relative', top: '-51px' }} className="patient-Q15-OccupiedSlot-Text">{k.location + "-" + k.activity}</span></>
                                    </div></a>)) :
                                <a style={{ cursor: 'pointer', height: '50px' }} onClick={() => handleDisplayDialogQ15Data("A10", "10:00-10:14", "")}>
                                    <div style={{ background: timeSlotSectionChange === "A10" ? "#4977D9" : "", height: '100%', display: 'flex', alignItems: 'center' }} className="patient-Q15-SlotSection">
                                        <span style={{ color: timeSlotSectionChange === "A10" ? "#FFF" : "" }} className="patient-Q15-SlotSection-Text">10.00</span>
                                    </div></a>}
                            {displayData.filter(i => i.q15Slot === "B10" && i.pid === inputQ15Data.pid && i.q15Date === moment(inputQ15Date).format("YYYYMMDD")).map((k) => { return k; }).length > 0 ? displayData.filter(i => i.q15Slot === "B10" && i.pid === inputQ15Data.pid && i.q15Date === moment(inputQ15Date).format("YYYYMMDD")).map(k => (
                                <a style={{ cursor: 'pointer', height: '50px' }} onClick={() => handleDisplayDialogQ15Data("B10", "10:15-10:29", k)}>
                                    <div style={{ position: 'relative', left: '10px', background: timeSlotSectionChange === "B10" ? "#4977D9" : "", height: '100%' }} className="patient-Q15-SlotSection">
                                        <><img src={MaskGroupImage} style={{ width: '103px', height: '47px', borderRadius: '5px', position: 'relative', left: '-35px', top: '-13.1px' }}></img>
                                            <span style={{ position: 'relative', top: '-51px' }} className="patient-Q15-OccupiedSlot-Text">{k.location + "-" + k.activity}</span></>
                                    </div></a>)) :
                                <a style={{ cursor: 'pointer', height: '50px' }} onClick={() => handleDisplayDialogQ15Data("B10", "10:15-10:29", "")}>
                                    <div style={{ position: 'relative', left: '10px', background: timeSlotSectionChange === "B10" ? "#4977D9" : "", height: '100%', display: 'flex', alignItems: 'center' }} className="patient-Q15-SlotSection">
                                        <span style={{ color: timeSlotSectionChange === "B10" ? "#FFF" : "" }} className="patient-Q15-SlotSection-Text">10.15</span>
                                    </div></a>}
                            {displayData.filter(i => i.q15Slot === "C10" && i.pid === inputQ15Data.pid && i.q15Date === moment(inputQ15Date).format("YYYYMMDD")).map((k) => { return k; }).length > 0 ? displayData.filter(i => i.q15Slot === "C10" && i.pid === inputQ15Data.pid && i.q15Date === moment(inputQ15Date).format("YYYYMMDD")).map(k => (
                                <a style={{ cursor: 'pointer', height: '50px' }} onClick={() => handleDisplayDialogQ15Data("C10", "10:30-10:44", k)}>
                                    <div style={{ position: 'relative', left: '20px', background: timeSlotSectionChange === "C10" ? "#4977D9" : "", height: '100%' }} className="patient-Q15-SlotSection">
                                        <><img src={MaskGroupImage} style={{ width: '103px', height: '47px', borderRadius: '5px', position: 'relative', left: '-35px', top: '-13.1px' }}></img>
                                            <span style={{ position: 'relative', top: '-51px' }} className="patient-Q15-OccupiedSlot-Text">{k.location + "-" + k.activity}</span></>
                                    </div></a>)) :
                                <a style={{ cursor: 'pointer', height: '50px' }} onClick={() => handleDisplayDialogQ15Data("C10", "10:30-10:44", "")}>
                                    <div style={{ position: 'relative', left: '20px', background: timeSlotSectionChange === "C10" ? "#4977D9" : "", height: '100%', display: 'flex', alignItems: 'center' }} className="patient-Q15-SlotSection">
                                        <span style={{ color: timeSlotSectionChange === "C10" ? "#FFF" : "" }} className="patient-Q15-SlotSection-Text">10.30</span>
                                    </div></a>}
                            {displayData.filter(i => i.q15Slot === "D10" && i.pid === inputQ15Data.pid && i.q15Date === moment(inputQ15Date).format("YYYYMMDD")).map((k) => { return k; }).length > 0 ? displayData.filter(i => i.q15Slot === "D10" && i.pid === inputQ15Data.pid && i.q15Date === moment(inputQ15Date).format("YYYYMMDD")).map(k => (
                                <a style={{ cursor: 'pointer', height: '50px' }} onClick={() => handleDisplayDialogQ15Data("D10", "10:45-10:59", k)}>
                                    <div style={{ position: 'relative', left: '30px', background: timeSlotSectionChange === "D10" ? "#4977D9" : "", height: '100%' }} className="patient-Q15-SlotSection">
                                        <><img src={MaskGroupImage} style={{ width: '103px', height: '47px', borderRadius: '5px', position: 'relative', left: '-35px', top: '-13.1px' }}></img>
                                            <span style={{ position: 'relative', top: '-51px' }} className="patient-Q15-OccupiedSlot-Text">{k.location + "-" + k.activity}</span></>
                                    </div></a>)) :
                                <a style={{ cursor: 'pointer',height: '50px' }} onClick={() => handleDisplayDialogQ15Data("D10", "10:45-10:59", "")}>
                                    <div style={{ position: 'relative', left: '30px', background: timeSlotSectionChange === "D10" ? "#4977D9" : "", height: '100%', display: 'flex', alignItems: 'center' }} className="patient-Q15-SlotSection">
                                        <span style={{ color: timeSlotSectionChange === "D10" ? "#FFF" : "" }} className="patient-Q15-SlotSection-Text">10.45</span>
                                    </div></a>}
                        </div>
                        <div style={{ position: 'relative', left: '42px', top: '87px', display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                            {displayData.filter(i => i.q15Slot === "A11" && i.pid === inputQ15Data.pid && i.q15Date === moment(inputQ15Date).format("YYYYMMDD")).map((k) => { return k; }).length > 0 ? displayData.filter(i => i.q15Slot === "A11" && i.pid === inputQ15Data.pid && i.q15Date === moment(inputQ15Date).format("YYYYMMDD")).map(k => (
                                <a style={{ cursor: 'pointer', height: '50px' }} onClick={() => handleDisplayDialogQ15Data("A11", "11:00-11:14", k)}>
                                    <div style={{ background: timeSlotSectionChange === "A11" ? "#4977D9" : "", height: '100%' }} className="patient-Q15-SlotSection">
                                        <><img src={MaskGroupImage} style={{ width: '103px', height: '47px', borderRadius: '5px', position: 'relative', left: '-35px', top: '-13.1px' }}></img>
                                            <span style={{ position: 'relative', top: '-51px' }} className="patient-Q15-OccupiedSlot-Text">{k.location + "-" + k.activity}</span></>
                                    </div></a>)) :
                                <a style={{ cursor: 'pointer', height: '50px' }} onClick={() => handleDisplayDialogQ15Data("A11", "11:00-11:14", "")}>
                                    <div style={{ background: timeSlotSectionChange === "A11" ? "#4977D9" : "", height: '100%', display: 'flex', alignItems: 'center' }} className="patient-Q15-SlotSection">
                                        <span style={{ color: timeSlotSectionChange === "A11" ? "#FFF" : "" }} className="patient-Q15-SlotSection-Text">11.00</span>
                                    </div></a>}
                            {displayData.filter(i => i.q15Slot === "B11" && i.pid === inputQ15Data.pid && i.q15Date === moment(inputQ15Date).format("YYYYMMDD")).map((k) => { return k; }).length > 0 ? displayData.filter(i => i.q15Slot === "B11" && i.pid === inputQ15Data.pid && i.q15Date === moment(inputQ15Date).format("YYYYMMDD")).map(k => (
                                <a style={{ cursor: 'pointer', height: '50px' }} onClick={() => handleDisplayDialogQ15Data("B11", "11:15-11:29", k)}>
                                    <div style={{ position: 'relative', left: '10px', background: timeSlotSectionChange === "B11" ? "#4977D9" : "", height: '100%' }} className="patient-Q15-SlotSection">
                                        <><img src={MaskGroupImage} style={{ width: '103px', height: '47px', borderRadius: '5px', position: 'relative', left: '-35px', top: '-13.1px' }}></img>
                                            <span style={{ position: 'relative', top: '-51px' }} className="patient-Q15-OccupiedSlot-Text">{k.location + "-" + k.activity}</span></>
                                    </div></a>)) :
                                <a style={{ cursor: 'pointer', height: '50px' }} onClick={() => handleDisplayDialogQ15Data("B11", "11:15-11:29", "")}>
                                    <div style={{ position: 'relative', left: '10px', background: timeSlotSectionChange === "B11" ? "#4977D9" : "", height: '100%', display: 'flex', alignItems: 'center' }} className="patient-Q15-SlotSection">
                                        <span style={{ color: timeSlotSectionChange === "B11" ? "#FFF" : "" }} className="patient-Q15-SlotSection-Text">11.15</span>
                                    </div></a>}
                            {displayData.filter(i => i.q15Slot === "C11" && i.pid === inputQ15Data.pid && i.q15Date === moment(inputQ15Date).format("YYYYMMDD")).map((k) => { return k; }).length > 0 ? displayData.filter(i => i.q15Slot === "C11" && i.pid === inputQ15Data.pid && i.q15Date === moment(inputQ15Date).format("YYYYMMDD")).map(k => (
                                <a style={{ cursor: 'pointer', height: '50px' }} onClick={() => handleDisplayDialogQ15Data("C11", "11:30-11:44", k)}>
                                    <div style={{ position: 'relative', left: '20px', background: timeSlotSectionChange === "C11" ? "#4977D9" : "" , height: '100%'}} className="patient-Q15-SlotSection">
                                        <><img src={MaskGroupImage} style={{ width: '103px', height: '47px', borderRadius: '5px', position: 'relative', left: '-35px', top: '-13.1px' }}></img>
                                            <span style={{ position: 'relative', top: '-51px' }} className="patient-Q15-OccupiedSlot-Text">{k.location + "-" + k.activity}</span></>
                                    </div></a>)) :
                                <a style={{ cursor: 'pointer', height: '50px' }} onClick={() => handleDisplayDialogQ15Data("C11", "11:30-11:44", "")}>
                                    <div style={{ position: 'relative', left: '20px', background: timeSlotSectionChange === "C11" ? "#4977D9" : "", height: '100%', display: 'flex', alignItems: 'center' }} className="patient-Q15-SlotSection">
                                        <span style={{ color: timeSlotSectionChange === "C11" ? "#FFF" : "" }} className="patient-Q15-SlotSection-Text">11.30</span>
                                    </div></a>}
                            {displayData.filter(i => i.q15Slot === "D11" && i.pid === inputQ15Data.pid && i.q15Date === moment(inputQ15Date).format("YYYYMMDD")).map((k) => { return k; }).length > 0 ? displayData.filter(i => i.q15Slot === "D11" && i.pid === inputQ15Data.pid && i.q15Date === moment(inputQ15Date).format("YYYYMMDD")).map(k => (
                                <a style={{ cursor: 'pointer', height: '50px' }} onClick={() => handleDisplayDialogQ15Data("D11", "11:45-11:59", k)}>
                                    <div style={{ position: 'relative', left: '30px', background: timeSlotSectionChange === "D11" ? "#4977D9" : "", height: '100%' }} className="patient-Q15-SlotSection">
                                        <><img src={MaskGroupImage} style={{ width: '103px', height: '47px', borderRadius: '5px', position: 'relative', left: '-35px', top: '-13.1px' }}></img>
                                            <span style={{ position: 'relative', top: '-51px' }} className="patient-Q15-OccupiedSlot-Text">{k.location + "-" + k.activity}</span></>
                                    </div></a>)) :
                                <a style={{ cursor: 'pointer', height: '50px' }} onClick={() => handleDisplayDialogQ15Data("D11", "11:45-11:59", "")}>
                                    <div style={{ position: 'relative', left: '30px', background: timeSlotSectionChange === "D11" ? "#4977D9" : "", height: '100%', display: 'flex', alignItems: 'center' }} className="patient-Q15-SlotSection">
                                        <span style={{ color: timeSlotSectionChange === "D11" ? "#FFF" : "" }} className="patient-Q15-SlotSection-Text">11.45</span>
                                    </div></a>}
                        </div>
                    </> : timeSlotChange === 3 ? <>
                        <div style={{ position: 'relative', left: '42px', top: '51px', display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                            {displayData.filter(i => i.q15Slot === "A12" && i.pid === inputQ15Data.pid && i.q15Date === moment(inputQ15Date).format("YYYYMMDD")).map((k) => { return k; }).length > 0 ? displayData.filter(i => i.q15Slot === "A12" && i.pid === inputQ15Data.pid && i.q15Date === moment(inputQ15Date).format("YYYYMMDD")).map(k => (
                                <a style={{ cursor: 'pointer', height: '50px' }} onClick={() => handleDisplayDialogQ15Data("A12", "12:00-12:14", k)}>
                                    <div style={{ background: timeSlotSectionChange === "A12" ? "#4977D9" : "", height: '100%' }} className="patient-Q15-SlotSection">
                                        <><img src={MaskGroupImage} style={{ width: '103px', height: '47px', borderRadius: '5px', position: 'relative', left: '-35px', top: '-13.1px' }}></img>
                                            <span style={{ position: 'relative', top: '-51px' }} className="patient-Q15-OccupiedSlot-Text">{k.location + "-" + k.activity}</span></>
                                    </div></a>)) :
                                <a style={{ cursor: 'pointer', height: '50px' }} onClick={() => handleDisplayDialogQ15Data("A12", "12:00-12:14", "")}>
                                    <div style={{ background: timeSlotSectionChange === "A12" ? "#4977D9" : "", height: '100%', display: 'flex', alignItems: 'center' }} className="patient-Q15-SlotSection">
                                        <span style={{ color: timeSlotSectionChange === "A12" ? "#FFF" : "" }} className="patient-Q15-SlotSection-Text">12.00</span>
                                    </div></a>}
                            {displayData.filter(i => i.q15Slot === "B12" && i.pid === inputQ15Data.pid && i.q15Date === moment(inputQ15Date).format("YYYYMMDD")).map((k) => { return k; }).length > 0 ? displayData.filter(i => i.q15Slot === "B12" && i.pid === inputQ15Data.pid && i.q15Date === moment(inputQ15Date).format("YYYYMMDD")).map(k => (
                                <a style={{ cursor: 'pointer', height: '50px' }} onClick={() => handleDisplayDialogQ15Data("B12", "12:15-12:29", k)}>
                                    <div style={{ position: 'relative', left: '10px', background: timeSlotSectionChange === "B12" ? "#4977D9" : "", height: '100%' }} className="patient-Q15-SlotSection">
                                        <><img src={MaskGroupImage} style={{ width: '103px', height: '47px', borderRadius: '5px', position: 'relative', left: '-35px', top: '-13.1px' }}></img>
                                            <span style={{ position: 'relative', top: '-51px' }} className="patient-Q15-OccupiedSlot-Text">{k.location + "-" + k.activity}</span></>
                                    </div></a>)) :
                                <a style={{ cursor: 'pointer', height: '50px' }} onClick={() => handleDisplayDialogQ15Data("B12", "12:15-12:29", "")}>
                                    <div style={{ position: 'relative', left: '10px', background: timeSlotSectionChange === "B12" ? "#4977D9" : "", height: '100%', display: 'flex', alignItems: 'center' }} className="patient-Q15-SlotSection">
                                        <span style={{ color: timeSlotSectionChange === "B12" ? "#FFF" : "" }} className="patient-Q15-SlotSection-Text">12.15</span>
                                    </div></a>}
                            {displayData.filter(i => i.q15Slot === "C12" && i.pid === inputQ15Data.pid && i.q15Date === moment(inputQ15Date).format("YYYYMMDD")).map((k) => { return k; }).length > 0 ? displayData.filter(i => i.q15Slot === "C12" && i.pid === inputQ15Data.pid && i.q15Date === moment(inputQ15Date).format("YYYYMMDD")).map(k => (
                                <a style={{ cursor: 'pointer', height: '50px' }} onClick={() => handleDisplayDialogQ15Data("C12", "12:30-12:44", k)}>
                                    <div style={{ position: 'relative', left: '20px', background: timeSlotSectionChange === "C12" ? "#4977D9" : "", height: '100%' }} className="patient-Q15-SlotSection">
                                        <><img src={MaskGroupImage} style={{ width: '103px', height: '47px', borderRadius: '5px', position: 'relative', left: '-35px', top: '-13.1px' }}></img>
                                            <span style={{ position: 'relative', top: '-51px' }} className="patient-Q15-OccupiedSlot-Text">{k.location + "-" + k.activity}</span></>
                                    </div></a>)) :
                                <a style={{ cursor: 'pointer', height: '50px' }} onClick={() => handleDisplayDialogQ15Data("C12", "12:30-12:44", "")}>
                                    <div style={{ position: 'relative', left: '20px', background: timeSlotSectionChange === "C12" ? "#4977D9" : "", height: '100%', display: 'flex', alignItems: 'center' }} className="patient-Q15-SlotSection">
                                        <span style={{ color: timeSlotSectionChange === "C12" ? "#FFF" : "" }} className="patient-Q15-SlotSection-Text">12.30</span>
                                    </div></a>}
                            {displayData.filter(i => i.q15Slot === "D12" && i.pid === inputQ15Data.pid && i.q15Date === moment(inputQ15Date).format("YYYYMMDD")).map((k) => { return k; }).length > 0 ? displayData.filter(i => i.q15Slot === "D12" && i.pid === inputQ15Data.pid && i.q15Date === moment(inputQ15Date).format("YYYYMMDD")).map(k => (
                                <a style={{ cursor: 'pointer', height: '50px' }} onClick={() => handleDisplayDialogQ15Data("D12", "12:45-12:59", k)}>
                                    <div style={{ position: 'relative', left: '30px', background: timeSlotSectionChange === "D12" ? "#4977D9" : "", height: '100%' }} className="patient-Q15-SlotSection">
                                        <><img src={MaskGroupImage} style={{ width: '103px', height: '47px', borderRadius: '5px', position: 'relative', left: '-35px', top: '-13.1px' }}></img>
                                            <span style={{ position: 'relative', top: '-51px' }} className="patient-Q15-OccupiedSlot-Text">{k.location + "-" + k.activity}</span></>
                                    </div></a>)) :
                                <a style={{ cursor: 'pointer', height: '50px' }} onClick={() => handleDisplayDialogQ15Data("D12", "12:45-12:59", "")}>
                                    <div style={{ position: 'relative', left: '30px', background: timeSlotSectionChange === "D12" ? "#4977D9" : "", height: '100%', display: 'flex', alignItems: 'center' }} className="patient-Q15-SlotSection">
                                        <span style={{ color: timeSlotSectionChange === "D12" ? "#FFF" : "" }} className="patient-Q15-SlotSection-Text">12.45</span>
                                    </div></a>}
                        </div>
                        <div style={{ position: 'relative', left: '42px', top: '59px', display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                            {displayData.filter(i => i.q15Slot === "A13" && i.pid === inputQ15Data.pid && i.q15Date === moment(inputQ15Date).format("YYYYMMDD")).map((k) => { return k; }).length > 0 ? displayData.filter(i => i.q15Slot === "A13" && i.pid === inputQ15Data.pid && i.q15Date === moment(inputQ15Date).format("YYYYMMDD")).map(k => (
                                <a style={{ cursor: 'pointer', height: '50px' }} onClick={() => handleDisplayDialogQ15Data("A13", "13:00-13:14", k)}>
                                    <div style={{ background: timeSlotSectionChange === "A13" ? "#4977D9" : "", height: '100%' }} className="patient-Q15-SlotSection">
                                        <><img src={MaskGroupImage} style={{ width: '103px', height: '47px', borderRadius: '5px', position: 'relative', left: '-35px', top: '-13.1px' }}></img>
                                            <span style={{ position: 'relative', top: '-51px' }} className="patient-Q15-OccupiedSlot-Text">{k.location + "-" + k.activity}</span></>
                                    </div></a>)) :
                                <a style={{ cursor: 'pointer', height: '50px' }} onClick={() => handleDisplayDialogQ15Data("A13", "13:00-13:14", "")}>
                                    <div style={{ background: timeSlotSectionChange === "A13" ? "#4977D9" : "", height: '100%', display: 'flex', alignItems: 'center' }} className="patient-Q15-SlotSection">
                                        <span style={{ color: timeSlotSectionChange === "A13" ? "#FFF" : "" }} className="patient-Q15-SlotSection-Text">13.00</span>
                                    </div></a>}
                            {displayData.filter(i => i.q15Slot === "B13" && i.pid === inputQ15Data.pid && i.q15Date === moment(inputQ15Date).format("YYYYMMDD")).map((k) => { return k; }).length > 0 ? displayData.filter(i => i.q15Slot === "B13" && i.pid === inputQ15Data.pid && i.q15Date === moment(inputQ15Date).format("YYYYMMDD")).map(k => (
                                <a style={{ cursor: 'pointer', height: '50px' }} onClick={() => handleDisplayDialogQ15Data("B13", "13:15-13:29", k)}>
                                    <div style={{ position: 'relative', left: '10px', background: timeSlotSectionChange === "B13" ? "#4977D9" : "", height: '100%' }} className="patient-Q15-SlotSection">
                                        <><img src={MaskGroupImage} style={{ width: '103px', height: '47px', borderRadius: '5px', position: 'relative', left: '-35px', top: '-13.1px' }}></img>
                                            <span style={{ position: 'relative', top: '-51px' }} className="patient-Q15-OccupiedSlot-Text">{k.location + "-" + k.activity}</span></>
                                    </div></a>)) :
                                <a style={{ cursor: 'pointer', height: '50px' }} onClick={() => handleDisplayDialogQ15Data("B13", "13:15-13:29", "")}>
                                    <div style={{ position: 'relative', left: '10px', background: timeSlotSectionChange === "B13" ? "#4977D9" : "", height: '100%', display: 'flex', alignItems: 'center' }} className="patient-Q15-SlotSection">
                                        <span style={{ color: timeSlotSectionChange === "B13" ? "#FFF" : "" }} className="patient-Q15-SlotSection-Text">13.15</span>
                                    </div></a>}
                            {displayData.filter(i => i.q15Slot === "C13" && i.pid === inputQ15Data.pid && i.q15Date === moment(inputQ15Date).format("YYYYMMDD")).map((k) => { return k; }).length > 0 ? displayData.filter(i => i.q15Slot === "C13" && i.pid === inputQ15Data.pid && i.q15Date === moment(inputQ15Date).format("YYYYMMDD")).map(k => (
                                <a style={{ cursor: 'pointer', height: '50px' }} onClick={() => handleDisplayDialogQ15Data("C13", "13:30-13:44", k)}>
                                    <div style={{ position: 'relative', left: '20px', background: timeSlotSectionChange === "C13" ? "#4977D9" : "", height: '100%' }} className="patient-Q15-SlotSection">
                                        <><img src={MaskGroupImage} style={{ width: '103px', height: '47px', borderRadius: '5px', position: 'relative', left: '-35px', top: '-13.1px' }}></img>
                                            <span style={{ position: 'relative', top: '-51px' }} className="patient-Q15-OccupiedSlot-Text">{k.location + "-" + k.activity}</span></>
                                    </div></a>)) :
                                <a style={{ cursor: 'pointer', height: '50px' }} onClick={() => handleDisplayDialogQ15Data("C13", "13:30-13:44", "")}>
                                    <div style={{ position: 'relative', left: '20px', background: timeSlotSectionChange === "C13" ? "#4977D9" : "", height: '100%', display: 'flex', alignItems: 'center' }} className="patient-Q15-SlotSection">
                                        <span style={{ color: timeSlotSectionChange === "C13" ? "#FFF" : "" }} className="patient-Q15-SlotSection-Text">13.30</span>
                                    </div></a>}
                            {displayData.filter(i => i.q15Slot === "D13" && i.pid === inputQ15Data.pid && i.q15Date === moment(inputQ15Date).format("YYYYMMDD")).map((k) => { return k; }).length > 0 ? displayData.filter(i => i.q15Slot === "D13" && i.pid === inputQ15Data.pid && i.q15Date === moment(inputQ15Date).format("YYYYMMDD")).map(k => (
                                <a style={{ cursor: 'pointer', height: '50px' }} onClick={() => handleDisplayDialogQ15Data("D13", "13:45-13:59", k)}>
                                    <div style={{ position: 'relative', left: '30px', background: timeSlotSectionChange === "D13" ? "#4977D9" : "", height: '100%' }} className="patient-Q15-SlotSection">
                                        <><img src={MaskGroupImage} style={{ width: '103px', height: '47px', borderRadius: '5px', position: 'relative', left: '-35px', top: '-13.1px' }}></img>
                                            <span style={{ position: 'relative', top: '-51px' }} className="patient-Q15-OccupiedSlot-Text">{k.location + "-" + k.activity}</span></>
                                    </div></a>)) :
                                <a style={{ cursor: 'pointer', height: '50px' }} onClick={() => handleDisplayDialogQ15Data("D13", "13:45-13:59", "")}>
                                    <div style={{ position: 'relative', left: '30px', background: timeSlotSectionChange === "D13" ? "#4977D9" : "", height: '100%', display: 'flex', alignItems: 'center' }} className="patient-Q15-SlotSection">
                                        <span style={{ color: timeSlotSectionChange === "D13" ? "#FFF" : "" }} className="patient-Q15-SlotSection-Text">13.45</span>
                                    </div></a>
                            }
                        </div>
                        <div style={{ position: 'relative', left: '42px', top: '66px', display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                            {displayData.filter(i => i.q15Slot === "A14" && i.pid === inputQ15Data.pid && i.q15Date === moment(inputQ15Date).format("YYYYMMDD")).map((k) => { return k; }).length > 0 ? displayData.filter(i => i.q15Slot === "A14" && i.pid === inputQ15Data.pid && i.q15Date === moment(inputQ15Date).format("YYYYMMDD")).map(k => (
                                <a style={{ cursor: 'pointer', height: '50px' }} onClick={() => handleDisplayDialogQ15Data("A14", "14:00-14:14", k)}>
                                    <div style={{ background: timeSlotSectionChange === "A14" ? "#4977D9" : "", height: '100%' }} className="patient-Q15-SlotSection">
                                        <><img src={MaskGroupImage} style={{ width: '103px', height: '47px', borderRadius: '5px', position: 'relative', left: '-35px', top: '-13.1px' }}></img>
                                            <span style={{ position: 'relative', top: '-51px' }} className="patient-Q15-OccupiedSlot-Text">{k.location + "-" + k.activity}</span></>
                                    </div></a>)) :
                                <a style={{ cursor: 'pointer', height: '50px' }} onClick={() => handleDisplayDialogQ15Data("A14", "14:00-14:14", "")}>
                                    <div style={{ background: timeSlotSectionChange === "A14" ? "#4977D9" : "", height: '100%', display: 'flex', alignItems: 'center' }} className="patient-Q15-SlotSection">
                                        <span style={{ color: timeSlotSectionChange === "A14" ? "#FFF" : "" }} className="patient-Q15-SlotSection-Text">14.00</span>
                                    </div></a>}
                            {displayData.filter(i => i.q15Slot === "B14" && i.pid === inputQ15Data.pid && i.q15Date === moment(inputQ15Date).format("YYYYMMDD")).map((k) => { return k; }).length > 0 ? displayData.filter(i => i.q15Slot === "B14" && i.pid === inputQ15Data.pid && i.q15Date === moment(inputQ15Date).format("YYYYMMDD")).map(k => (
                                <a style={{ cursor: 'pointer', height: '50px' }} onClick={() => handleDisplayDialogQ15Data("B14", "14:15-14:29", k)}>
                                    <div style={{ position: 'relative', left: '10px', background: timeSlotSectionChange === "B14" ? "#4977D9" : "", height: '100%' }} className="patient-Q15-SlotSection">
                                        <><img src={MaskGroupImage} style={{ width: '103px', height: '47px', borderRadius: '5px', position: 'relative', left: '-35px', top: '-13.1px' }}></img>
                                            <span style={{ position: 'relative', top: '-51px' }} className="patient-Q15-OccupiedSlot-Text">{k.location + "-" + k.activity}</span></>
                                    </div></a>)) :
                                <a style={{ cursor: 'pointer', height: '50px' }} onClick={() => handleDisplayDialogQ15Data("B14", "14:15-14:29", "")}>
                                    <div style={{ position: 'relative', left: '10px', background: timeSlotSectionChange === "B14" ? "#4977D9" : "", height: '100%', display: 'flex', alignItems: 'center' }} className="patient-Q15-SlotSection">
                                        <span style={{ color: timeSlotSectionChange === "B14" ? "#FFF" : "" }} className="patient-Q15-SlotSection-Text">14.15</span>
                                    </div></a>}
                            {displayData.filter(i => i.q15Slot === "C14" && i.pid === inputQ15Data.pid && i.q15Date === moment(inputQ15Date).format("YYYYMMDD")).map((k) => { return k; }).length > 0 ? displayData.filter(i => i.q15Slot === "C14" && i.pid === inputQ15Data.pid && i.q15Date === moment(inputQ15Date).format("YYYYMMDD")).map(k => (
                                <a style={{ cursor: 'pointer', height: '50px' }} onClick={() => handleDisplayDialogQ15Data("C14", "14:30-14:44", k)}>
                                    <div style={{ position: 'relative', left: '20px', background: timeSlotSectionChange === "C14" ? "#4977D9" : "", height: '100%' }} className="patient-Q15-SlotSection">
                                        <><img src={MaskGroupImage} style={{ width: '103px', height: '47px', borderRadius: '5px', position: 'relative', left: '-35px', top: '-13.1px' }}></img>
                                            <span style={{ position: 'relative', top: '-51px' }} className="patient-Q15-OccupiedSlot-Text">{k.location + "-" + k.activity}</span></>
                                    </div></a>)) :
                                <a style={{ cursor: 'pointer', height: '50px' }} onClick={() => handleDisplayDialogQ15Data("C14", "14:30-14:44", "")}>
                                    <div style={{ position: 'relative', left: '20px', background: timeSlotSectionChange === "C14" ? "#4977D9" : "", height: '100%', display: 'flex', alignItems: 'center' }} className="patient-Q15-SlotSection">
                                        <span style={{ color: timeSlotSectionChange === "C14" ? "#FFF" : "" }} className="patient-Q15-SlotSection-Text">14.30</span>
                                    </div></a>}
                            {displayData.filter(i => i.q15Slot === "D14" && i.pid === inputQ15Data.pid && i.q15Date === moment(inputQ15Date).format("YYYYMMDD")).map((k) => { return k; }).length > 0 ? displayData.filter(i => i.q15Slot === "D14" && i.pid === inputQ15Data.pid && i.q15Date === moment(inputQ15Date).format("YYYYMMDD")).map(k => (
                                <a style={{ cursor: 'pointer', height: '50px' }} onClick={() => handleDisplayDialogQ15Data("D14", "14:45-14:59", k)}>
                                    <div style={{ position: 'relative', left: '30px', background: timeSlotSectionChange === "D14" ? "#4977D9" : "", height: '100%' }} className="patient-Q15-SlotSection">
                                        <><img src={MaskGroupImage} style={{ width: '103px', height: '47px', borderRadius: '5px', position: 'relative', left: '-35px', top: '-13.1px' }}></img>
                                            <span style={{ position: 'relative', top: '-51px' }} className="patient-Q15-OccupiedSlot-Text">{k.location + "-" + k.activity}</span></>
                                    </div></a>)) :
                                <a style={{ cursor: 'pointer', height: '50px' }} onClick={() => handleDisplayDialogQ15Data("D14", "14:45-14:59", "")}>
                                    <div style={{ position: 'relative', left: '30px', background: timeSlotSectionChange === "D14" ? "#4977D9" : "", height: '100%', display: 'flex', alignItems: 'center' }} className="patient-Q15-SlotSection">
                                        <span style={{ color: timeSlotSectionChange === "D14" ? "#FFF" : "" }} className="patient-Q15-SlotSection-Text">14.45</span>
                                    </div></a>}
                        </div>
                        <div style={{ position: 'relative', left: '42px', top: '73px', display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                            {displayData.filter(i => i.q15Slot === "A15" && i.pid === inputQ15Data.pid && i.q15Date === moment(inputQ15Date).format("YYYYMMDD")).map((k) => { return k; }).length > 0 ? displayData.filter(i => i.q15Slot === "A15" && i.pid === inputQ15Data.pid && i.q15Date === moment(inputQ15Date).format("YYYYMMDD")).map(k => (
                                <a style={{ cursor: 'pointer', height: '50px' }} onClick={() => handleDisplayDialogQ15Data("A15", "15:00-15:14", k)}>
                                    <div style={{ background: timeSlotSectionChange === "A15" ? "#4977D9" : "", height: '100%' }} className="patient-Q15-SlotSection">
                                        <><img src={MaskGroupImage} style={{ width: '103px', height: '47px', borderRadius: '5px', position: 'relative', left: '-35px', top: '-13.1px' }}></img>
                                            <span style={{ position: 'relative', top: '-51px' }} className="patient-Q15-OccupiedSlot-Text">{k.location + "-" + k.activity}</span></>
                                    </div></a>)) :
                                <a style={{ cursor: 'pointer', height: '50px' }} onClick={() => handleDisplayDialogQ15Data("A15", "15:00-15:14", "")}>
                                    <div style={{ background: timeSlotSectionChange === "A15" ? "#4977D9" : "", height: '100%', display: 'flex', alignItems: 'center' }} className="patient-Q15-SlotSection">
                                        <span style={{ color: timeSlotSectionChange === "A15" ? "#FFF" : "" }} className="patient-Q15-SlotSection-Text">15.00</span>
                                    </div></a>}
                            {displayData.filter(i => i.q15Slot === "B15" && i.pid === inputQ15Data.pid && i.q15Date === moment(inputQ15Date).format("YYYYMMDD")).map((k) => { return k; }).length > 0 ? displayData.filter(i => i.q15Slot === "B15" && i.pid === inputQ15Data.pid && i.q15Date === moment(inputQ15Date).format("YYYYMMDD")).map(k => (
                                <a style={{ cursor: 'pointer', height: '50px' }} onClick={() => handleDisplayDialogQ15Data("B15", "15:15-15:29", k)}>
                                    <div style={{ position: 'relative', left: '10px', background: timeSlotSectionChange === "B15" ? "#4977D9" : "", height: '100%' }} className="patient-Q15-SlotSection">
                                        <><img src={MaskGroupImage} style={{ width: '103px', height: '47px', borderRadius: '5px', position: 'relative', left: '-35px', top: '-13.1px' }}></img>
                                            <span style={{ position: 'relative', top: '-51px' }} className="patient-Q15-OccupiedSlot-Text">{k.location + "-" + k.activity}</span></>
                                    </div></a>)) :
                                <a style={{ cursor: 'pointer', height: '50px' }} onClick={() => handleDisplayDialogQ15Data("B15", "15:15-15:29", "")}>
                                    <div style={{ position: 'relative', left: '10px', background: timeSlotSectionChange === "B15" ? "#4977D9" : "", height: '100%', display: 'flex', alignItems: 'center' }} className="patient-Q15-SlotSection">
                                        <span style={{ color: timeSlotSectionChange === "B15" ? "#FFF" : "" }} className="patient-Q15-SlotSection-Text">15.15</span>
                                    </div></a>}
                            {displayData.filter(i => i.q15Slot === "C15" && i.pid === inputQ15Data.pid && i.q15Date === moment(inputQ15Date).format("YYYYMMDD")).map((k) => { return k; }).length > 0 ? displayData.filter(i => i.q15Slot === "C15" && i.pid === inputQ15Data.pid && i.q15Date === moment(inputQ15Date).format("YYYYMMDD")).map(k => (
                                <a style={{ cursor: 'pointer', height: '50px' }} onClick={() => handleDisplayDialogQ15Data("C15", "15:30-15:44", k)}>
                                    <div style={{ position: 'relative', left: '20px', background: timeSlotSectionChange === "C15" ? "#4977D9" : "", height: '100%' }} className="patient-Q15-SlotSection">
                                        <><img src={MaskGroupImage} style={{ width: '103px', height: '47px', borderRadius: '5px', position: 'relative', left: '-35px', top: '-13.1px' }}></img>
                                            <span style={{ position: 'relative', top: '-51px' }} className="patient-Q15-OccupiedSlot-Text">{k.location + "-" + k.activity}</span></>
                                    </div></a>)) :
                                <a style={{ cursor: 'pointer', height: '50px' }} onClick={() => handleDisplayDialogQ15Data("C15", "15:30-15:44", "")}>
                                    <div style={{ position: 'relative', left: '20px', background: timeSlotSectionChange === "C15" ? "#4977D9" : "", height: '100%', display: 'flex', alignItems: 'center' }} className="patient-Q15-SlotSection">
                                        <span style={{ color: timeSlotSectionChange === "C15" ? "#FFF" : "" }} className="patient-Q15-SlotSection-Text">15.30</span>
                                    </div></a>}
                            {displayData.filter(i => i.q15Slot === "D15" && i.pid === inputQ15Data.pid && i.q15Date === moment(inputQ15Date).format("YYYYMMDD")).map((k) => { return k; }).length > 0 ? displayData.filter(i => i.q15Slot === "D15" && i.pid === inputQ15Data.pid && i.q15Date === moment(inputQ15Date).format("YYYYMMDD")).map(k => (
                                <a style={{ cursor: 'pointer', height: '50px' }} onClick={() => handleDisplayDialogQ15Data("D15", "15:45-15:59", k)}>
                                    <div style={{ position: 'relative', left: '30px', background: timeSlotSectionChange === "D15" ? "#4977D9" : "", height: '100%' }} className="patient-Q15-SlotSection">
                                        <><img src={MaskGroupImage} style={{ width: '103px', height: '47px', borderRadius: '5px', position: 'relative', left: '-35px', top: '-13.1px' }}></img>
                                            <span style={{ position: 'relative', top: '-51px' }} className="patient-Q15-OccupiedSlot-Text">{k.location + "-" + k.activity}</span></>
                                    </div></a>)) :
                                <a style={{ cursor: 'pointer', height: '50px' }} onClick={() => handleDisplayDialogQ15Data("D15", "15:45-15:59", "")}>
                                    <div style={{ position: 'relative', left: '30px', background: timeSlotSectionChange === "D15" ? "#4977D9" : "", height: '100%', display: 'flex', alignItems: 'center' }} className="patient-Q15-SlotSection">
                                        <span style={{ color: timeSlotSectionChange === "D15" ? "#FFF" : "" }} className="patient-Q15-SlotSection-Text">15.45</span>
                                    </div></a>}
                        </div>
                        <div style={{ position: 'relative', left: '42px', top: '80px', display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                            {displayData.filter(i => i.q15Slot === "A16" && i.pid === inputQ15Data.pid && i.q15Date === moment(inputQ15Date).format("YYYYMMDD")).map((k) => { return k; }).length > 0 ? displayData.filter(i => i.q15Slot === "A16" && i.pid === inputQ15Data.pid && i.q15Date === moment(inputQ15Date).format("YYYYMMDD")).map(k => (
                                <a style={{ cursor: 'pointer', height: '50px' }} onClick={() => handleDisplayDialogQ15Data("A16", "16:00-16:14", k)}>
                                    <div style={{ background: timeSlotSectionChange === "A16" ? "#4977D9" : "", height: '100%' }} className="patient-Q15-SlotSection">
                                        <><img src={MaskGroupImage} style={{ width: '103px', height: '47px', borderRadius: '5px', position: 'relative', left: '-35px', top: '-13.1px' }}></img>
                                            <span style={{ position: 'relative', top: '-51px' }} className="patient-Q15-OccupiedSlot-Text">{k.location + "-" + k.activity}</span></>
                                    </div></a>)) :
                                <a style={{ cursor: 'pointer', height: '50px' }} onClick={() => handleDisplayDialogQ15Data("A16", "16:00-16:14", "")}>
                                    <div style={{ background: timeSlotSectionChange === "A16" ? "#4977D9" : "", height: '100%', display: 'flex', alignItems: 'center' }} className="patient-Q15-SlotSection">
                                        <span style={{ color: timeSlotSectionChange === "A16" ? "#FFF" : "" }} className="patient-Q15-SlotSection-Text">16.00</span>
                                    </div></a>}
                            {displayData.filter(i => i.q15Slot === "B16" && i.pid === inputQ15Data.pid && i.q15Date === moment(inputQ15Date).format("YYYYMMDD")).map((k) => { return k; }).length > 0 ? displayData.filter(i => i.q15Slot === "B16" && i.pid === inputQ15Data.pid && i.q15Date === moment(inputQ15Date).format("YYYYMMDD")).map(k => (
                                <a style={{ cursor: 'pointer', height: '50px' }} onClick={() => handleDisplayDialogQ15Data("B16", "16:15-16:29", k)}>
                                    <div style={{ position: 'relative', left: '10px', background: timeSlotSectionChange === "B16" ? "#4977D9" : "", height: '100%' }} className="patient-Q15-SlotSection">
                                        <><img src={MaskGroupImage} style={{ width: '103px', height: '47px', borderRadius: '5px', position: 'relative', left: '-35px', top: '-13.1px' }}></img>
                                            <span style={{ position: 'relative', top: '-51px' }} className="patient-Q15-OccupiedSlot-Text">{k.location + "-" + k.activity}</span></>
                                    </div></a>)) :
                                <a style={{ cursor: 'pointer', height: '50px' }} onClick={() => handleDisplayDialogQ15Data("B16", "16:15-16:29", "")}>
                                    <div style={{ position: 'relative', left: '10px', background: timeSlotSectionChange === "B16" ? "#4977D9" : "", height: '100%', display: 'flex', alignItems: 'center' }} className="patient-Q15-SlotSection">
                                        <span style={{ color: timeSlotSectionChange === "B16" ? "#FFF" : "" }} className="patient-Q15-SlotSection-Text">16.15</span>
                                    </div></a>}
                            {displayData.filter(i => i.q15Slot === "C16" && i.pid === inputQ15Data.pid && i.q15Date === moment(inputQ15Date).format("YYYYMMDD")).map((k) => { return k; }).length > 0 ? displayData.filter(i => i.q15Slot === "C16" && i.pid === inputQ15Data.pid && i.q15Date === moment(inputQ15Date).format("YYYYMMDD")).map(k => (
                                <a style={{ cursor: 'pointer', height: '50px' }} onClick={() => handleDisplayDialogQ15Data("C16", "16:30-16:44", k)}>
                                    <div style={{ position: 'relative', left: '20px', background: timeSlotSectionChange === "C16" ? "#4977D9" : "", height: '100%' }} className="patient-Q15-SlotSection">
                                        <><img src={MaskGroupImage} style={{ width: '103px', height: '47px', borderRadius: '5px', position: 'relative', left: '-35px', top: '-13.1px' }}></img>
                                            <span style={{ position: 'relative', top: '-51px' }} className="patient-Q15-OccupiedSlot-Text">{k.location + "-" + k.activity}</span></>
                                    </div></a>)) :
                                <a style={{ cursor: 'pointer', height: '50px' }} onClick={() => handleDisplayDialogQ15Data("C16", "16:30-16:44", "")}>
                                    <div style={{ position: 'relative', left: '20px', background: timeSlotSectionChange === "C16" ? "#4977D9" : "", height: '100%', display: 'flex', alignItems: 'center' }} className="patient-Q15-SlotSection">
                                        <span style={{ color: timeSlotSectionChange === "C16" ? "#FFF" : "" }} className="patient-Q15-SlotSection-Text">16.30</span>
                                    </div></a>}
                            {displayData.filter(i => i.q15Slot === "D16" && i.pid === inputQ15Data.pid && i.q15Date === moment(inputQ15Date).format("YYYYMMDD")).map((k) => { return k; }).length > 0 ? displayData.filter(i => i.q15Slot === "D16" && i.pid === inputQ15Data.pid && i.q15Date === moment(inputQ15Date).format("YYYYMMDD")).map(k => (
                                <a style={{ cursor: 'pointer', height: '50px' }} onClick={() => handleDisplayDialogQ15Data("D16", "16:45-16:59", k)}>
                                    <div style={{ position: 'relative', left: '30px', background: timeSlotSectionChange === "D16" ? "#4977D9" : "", height: '100%' }} className="patient-Q15-SlotSection">
                                        <><img src={MaskGroupImage} style={{ width: '103px', height: '47px', borderRadius: '5px', position: 'relative', left: '-35px', top: '-13.1px' }}></img>
                                            <span style={{ position: 'relative', top: '-51px' }} className="patient-Q15-OccupiedSlot-Text">{k.location + "-" + k.activity}</span></>
                                    </div></a>)) :
                                <a style={{ cursor: 'pointer', height: '50px' }} onClick={() => handleDisplayDialogQ15Data("D16", "16:45-16:59", "")}>
                                    <div style={{ position: 'relative', left: '30px', background: timeSlotSectionChange === "D16" ? "#4977D9" : "", height: '100%', display: 'flex', alignItems: 'center' }} className="patient-Q15-SlotSection">
                                        <span style={{ color: timeSlotSectionChange === "D16" ? "#FFF" : "" }} className="patient-Q15-SlotSection-Text">16.45</span>
                                    </div></a>
                            }
                        </div>
                        <div style={{ position: 'relative', left: '42px', top: '87px', display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                            {displayData.filter(i => i.q15Slot === "A17" && i.pid === inputQ15Data.pid && i.q15Date === moment(inputQ15Date).format("YYYYMMDD")).map((k) => { return k; }).length > 0 ? displayData.filter(i => i.q15Slot === "A17" && i.pid === inputQ15Data.pid && i.q15Date === moment(inputQ15Date).format("YYYYMMDD")).map(k => (
                                <a style={{ cursor: 'pointer', height: '50px' }} onClick={() => handleDisplayDialogQ15Data("A17", "17:00-17:14", k)}>
                                    <div style={{ background: timeSlotSectionChange === "A17" ? "#4977D9" : "" , height: '100%'}} className="patient-Q15-SlotSection">
                                        <><img src={MaskGroupImage} style={{ width: '103px', height: '47px', borderRadius: '5px', position: 'relative', left: '-35px', top: '-13.1px' }}></img>
                                            <span style={{ position: 'relative', top: '-51px' }} className="patient-Q15-OccupiedSlot-Text">{k.location + "-" + k.activity}</span></>
                                    </div></a>)) :
                                <a style={{ cursor: 'pointer', height: '50px' }} onClick={() => handleDisplayDialogQ15Data("A17", "17:00-17:14", "")}>
                                    <div style={{ background: timeSlotSectionChange === "A17" ? "#4977D9" : "", height: '100%', display: 'flex', alignItems: 'center' }} className="patient-Q15-SlotSection">
                                        <span style={{ color: timeSlotSectionChange === "A17" ? "#FFF" : "" }} className="patient-Q15-SlotSection-Text">17.00</span>
                                    </div></a>}
                            {displayData.filter(i => i.q15Slot === "B17" && i.pid === inputQ15Data.pid && i.q15Date === moment(inputQ15Date).format("YYYYMMDD")).map((k) => { return k; }).length > 0 ? displayData.filter(i => i.q15Slot === "B17" && i.pid === inputQ15Data.pid && i.q15Date === moment(inputQ15Date).format("YYYYMMDD")).map(k => (
                                <a style={{ cursor: 'pointer', height: '50px' }} onClick={() => handleDisplayDialogQ15Data("B17", "17:15-17:29", k)}>
                                    <div style={{ position: 'relative', left: '10px', background: timeSlotSectionChange === "B17" ? "#4977D9" : "", height: '100%' }} className="patient-Q15-SlotSection">
                                        <><img src={MaskGroupImage} style={{ width: '103px', height: '47px', borderRadius: '5px', position: 'relative', left: '-35px', top: '-13.1px' }}></img>
                                            <span style={{ position: 'relative', top: '-51px' }} className="patient-Q15-OccupiedSlot-Text">{k.location + "-" + k.activity}</span></>
                                    </div></a>)) :
                                <a style={{ cursor: 'pointer', height: '50px' }} onClick={() => handleDisplayDialogQ15Data("B17", "17:15-17:29", "")}>
                                    <div style={{ position: 'relative', left: '10px', background: timeSlotSectionChange === "B17" ? "#4977D9" : "", height: '100%', display: 'flex', alignItems: 'center' }} className="patient-Q15-SlotSection">
                                        <span style={{ color: timeSlotSectionChange === "B17" ? "#FFF" : "" }} className="patient-Q15-SlotSection-Text">17.15</span>
                                    </div></a>}
                            {displayData.filter(i => i.q15Slot === "C17" && i.pid === inputQ15Data.pid && i.q15Date === moment(inputQ15Date).format("YYYYMMDD")).map((k) => { return k; }).length > 0 ? displayData.filter(i => i.q15Slot === "C17" && i.pid === inputQ15Data.pid && i.q15Date === moment(inputQ15Date).format("YYYYMMDD")).map(k => (
                                <a style={{ cursor: 'pointer', height: '50px' }} onClick={() => handleDisplayDialogQ15Data("C17", "17:30-17:44", k)}>
                                    <div style={{ position: 'relative', left: '20px', background: timeSlotSectionChange === "C17" ? "#4977D9" : "", height: '100%' }} className="patient-Q15-SlotSection">
                                        <><img src={MaskGroupImage} style={{ width: '103px', height: '47px', borderRadius: '5px', position: 'relative', left: '-35px', top: '-13.1px' }}></img>
                                            <span style={{ position: 'relative', top: '-51px' }} className="patient-Q15-OccupiedSlot-Text">{k.location + "-" + k.activity}</span></>
                                    </div></a>)) :
                                <a style={{ cursor: 'pointer', height: '50px' }} onClick={() => handleDisplayDialogQ15Data("C17", "17:30-17:44", "")}>
                                    <div style={{ position: 'relative', left: '20px', background: timeSlotSectionChange === "C17" ? "#4977D9" : "", height: '100%', display: 'flex', alignItems: 'center' }} className="patient-Q15-SlotSection">
                                        <span style={{ color: timeSlotSectionChange === "C17" ? "#FFF" : "" }} className="patient-Q15-SlotSection-Text">17.30</span>
                                    </div></a>}
                            {displayData.filter(i => i.q15Slot === "D17" && i.pid === inputQ15Data.pid && i.q15Date === moment(inputQ15Date).format("YYYYMMDD")).map((k) => { return k; }).length > 0 ? displayData.filter(i => i.q15Slot === "D17" && i.pid === inputQ15Data.pid && i.q15Date === moment(inputQ15Date).format("YYYYMMDD")).map(k => (
                                <a style={{ cursor: 'pointer', height: '50px' }} onClick={() => handleDisplayDialogQ15Data("D17", "17:45-17:59", k)}>
                                    <div style={{ position: 'relative', left: '30px', background: timeSlotSectionChange === "D17" ? "#4977D9" : "", height: '100%' }} className="patient-Q15-SlotSection">
                                        <><img src={MaskGroupImage} style={{ width: '103px', height: '47px', borderRadius: '5px', position: 'relative', left: '-35px', top: '-13.1px' }}></img>
                                            <span style={{ position: 'relative', top: '-51px' }} className="patient-Q15-OccupiedSlot-Text">{k.location + "-" + k.activity}</span></>
                                    </div></a>)) :
                                <a style={{ cursor: 'pointer', height: '50px' }} onClick={() => handleDisplayDialogQ15Data("D17", "17:45-17:59", "")}>
                                    <div style={{ position: 'relative', left: '30px', background: timeSlotSectionChange === "D17" ? "#4977D9" : "", height: '100%', display: 'flex', alignItems: 'center' }} className="patient-Q15-SlotSection">
                                        <span style={{ color: timeSlotSectionChange === "D17" ? "#FFF" : "" }} className="patient-Q15-SlotSection-Text">17.45</span>
                                    </div></a>}
                        </div>
                    </> : timeSlotChange === 4 ? <>
                        <div style={{ position: 'relative', left: '42px', top: '51px', display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                            {displayData.filter(i => i.q15Slot === "A18" && i.pid === inputQ15Data.pid && i.q15Date === moment(inputQ15Date).format("YYYYMMDD")).map((k) => { return k; }).length > 0 ? displayData.filter(i => i.q15Slot === "A18" && i.pid === inputQ15Data.pid && i.q15Date === moment(inputQ15Date).format("YYYYMMDD")).map(k => (
                                <a style={{ cursor: 'pointer', height: '50px' }} onClick={() => handleDisplayDialogQ15Data("A18", "18:00-18:14", k)}>
                                    <div style={{ background: timeSlotSectionChange === "A18" ? "#4977D9" : "", height: '100%' }} className="patient-Q15-SlotSection">
                                        <><img src={MaskGroupImage} style={{ width: '103px', height: '47px', borderRadius: '5px', position: 'relative', left: '-35px', top: '-13.1px' }}></img>
                                            <span style={{ position: 'relative', top: '-51px' }} className="patient-Q15-OccupiedSlot-Text">{k.location + "-" + k.activity}</span></>
                                    </div></a>)) :
                                <a style={{ cursor: 'pointer', height: '50px' }} onClick={() => handleDisplayDialogQ15Data("A18", "18:00-18:14", "")}>
                                    <div style={{ background: timeSlotSectionChange === "A18" ? "#4977D9" : "", height: '100%', display: 'flex', alignItems: 'center' }} className="patient-Q15-SlotSection">
                                        <span style={{ color: timeSlotSectionChange === "A18" ? "#FFF" : "" }} className="patient-Q15-SlotSection-Text">18.00</span>
                                    </div></a>}
                            {displayData.filter(i => i.q15Slot === "B18" && i.pid === inputQ15Data.pid && i.q15Date === moment(inputQ15Date).format("YYYYMMDD")).map((k) => { return k; }).length > 0 ? displayData.filter(i => i.q15Slot === "B18" && i.pid === inputQ15Data.pid && i.q15Date === moment(inputQ15Date).format("YYYYMMDD")).map(k => (
                                <a style={{ cursor: 'pointer', height: '50px' }} onClick={() => handleDisplayDialogQ15Data("B18", "18:15-18:29", k)}>
                                    <div style={{ position: 'relative', left: '10px', background: timeSlotSectionChange === "B18" ? "#4977D9" : "" , height: '100%'}} className="patient-Q15-SlotSection">
                                        <><img src={MaskGroupImage} style={{ width: '103px', height: '47px', borderRadius: '5px', position: 'relative', left: '-35px', top: '-13.1px' }}></img>
                                            <span style={{ position: 'relative', top: '-51px' }} className="patient-Q15-OccupiedSlot-Text">{k.location + "-" + k.activity}</span></>
                                    </div></a>)) :
                                <a style={{ cursor: 'pointer', height: '50px' }} onClick={() => handleDisplayDialogQ15Data("B18", "18:15-18:29", "")}>
                                    <div style={{ position: 'relative', left: '10px', background: timeSlotSectionChange === "B18" ? "#4977D9" : "", height: '100%', display: 'flex', alignItems: 'center' }} className="patient-Q15-SlotSection">
                                        <span style={{ color: timeSlotSectionChange === "B18" ? "#FFF" : "" }} className="patient-Q15-SlotSection-Text">18.15</span>
                                    </div></a>}
                            {displayData.filter(i => i.q15Slot === "C18" && i.pid === inputQ15Data.pid && i.q15Date === moment(inputQ15Date).format("YYYYMMDD")).map((k) => { return k; }).length > 0 ? displayData.filter(i => i.q15Slot === "C18" && i.pid === inputQ15Data.pid && i.q15Date === moment(inputQ15Date).format("YYYYMMDD")).map(k => (
                                <a style={{ cursor: 'pointer', height: '50px' }} onClick={() => handleDisplayDialogQ15Data("C18", "18:30-18:44", k)}>
                                    <div style={{ position: 'relative', left: '20px', background: timeSlotSectionChange === "C18" ? "#4977D9" : "", height: '100%' }} className="patient-Q15-SlotSection">
                                        <><img src={MaskGroupImage} style={{ width: '103px', height: '47px', borderRadius: '5px', position: 'relative', left: '-35px', top: '-13.1px' }}></img>
                                            <span style={{ position: 'relative', top: '-51px' }} className="patient-Q15-OccupiedSlot-Text">{k.location + "-" + k.activity}</span></>
                                    </div></a>)) :
                                <a style={{ cursor: 'pointer', height: "50px" }} onClick={() => handleDisplayDialogQ15Data("C18", "18:30-18:44", "")}>
                                    <div style={{ position: 'relative', left: '20px', background: timeSlotSectionChange === "C18" ? "#4977D9" : "", height: '100%', display: 'flex', alignItems: 'center' }} className="patient-Q15-SlotSection">
                                        <span style={{ color: timeSlotSectionChange === "C18" ? "#FFF" : "" }} className="patient-Q15-SlotSection-Text">18.30</span>
                                    </div></a>}
                            {displayData.filter(i => i.q15Slot === "D18" && i.pid === inputQ15Data.pid && i.q15Date === moment(inputQ15Date).format("YYYYMMDD")).map((k) => { return k; }).length > 0 ? displayData.filter(i => i.q15Slot === "D18" && i.pid === inputQ15Data.pid && i.q15Date === moment(inputQ15Date).format("YYYYMMDD")).map(k => (
                                <a style={{ cursor: 'pointer', height: '50px' }} onClick={() => handleDisplayDialogQ15Data("D18", "18:45-18:59", k)}>
                                    <div style={{ position: 'relative', left: '30px', background: timeSlotSectionChange === "D18" ? "#4977D9" : "", height: '100%' }} className="patient-Q15-SlotSection">
                                        <><img src={MaskGroupImage} style={{ width: '103px', height: '47px', borderRadius: '5px', position: 'relative', left: '-35px', top: '-13.1px' }}></img>
                                            <span style={{ position: 'relative', top: '-51px' }} className="patient-Q15-OccupiedSlot-Text">{k.location + "-" + k.activity}</span></>
                                    </div></a>)) :
                                <a style={{ cursor: 'pointer', height: '50px' }} onClick={() => handleDisplayDialogQ15Data("D18", "18:45-18:59", "")}>
                                    <div style={{ position: 'relative', left: '30px', background: timeSlotSectionChange === "D18" ? "#4977D9" : "", height: '100%', display: 'flex', alignItems: 'center' }} className="patient-Q15-SlotSection">
                                        <span style={{ color: timeSlotSectionChange === "D18" ? "#FFF" : "" }} className="patient-Q15-SlotSection-Text">18.45</span>
                                    </div></a>}
                        </div>
                        <div style={{ position: 'relative', left: '42px', top: '59px', display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                            {displayData.filter(i => i.q15Slot === "A19" && i.pid === inputQ15Data.pid && i.q15Date === moment(inputQ15Date).format("YYYYMMDD")).map((k) => { return k; }).length > 0 ? displayData.filter(i => i.q15Slot === "A19" && i.pid === inputQ15Data.pid && i.q15Date === moment(inputQ15Date).format("YYYYMMDD")).map(k => (
                                <a style={{ cursor: 'pointer', height: '50px' }} onClick={() => handleDisplayDialogQ15Data("A19", "19:00-19:14", k)}>
                                    <div style={{ background: timeSlotSectionChange === "A19" ? "#4977D9" : "", height: '100%' }} className="patient-Q15-SlotSection">
                                        <><img src={MaskGroupImage} style={{ width: '103px', height: '47px', borderRadius: '5px', position: 'relative', left: '-35px', top: '-13.1px' }}></img>
                                            <span style={{ position: 'relative', top: '-51px' }} className="patient-Q15-OccupiedSlot-Text">{k.location + "-" + k.activity}</span></>
                                    </div></a>)) :
                                <a style={{ cursor: 'pointer', height: '50px' }} onClick={() => handleDisplayDialogQ15Data("A19", "19:00-19:14", "")}>
                                    <div style={{ background: timeSlotSectionChange === "A19" ? "#4977D9" : "", height: '100%', display: 'flex', alignItems: 'center' }} className="patient-Q15-SlotSection">
                                        <span style={{ color: timeSlotSectionChange === "A19" ? "#FFF" : "" }} className="patient-Q15-SlotSection-Text">19.00</span>
                                    </div></a>}

                            {displayData.filter(i => i.q15Slot === "B19" && i.pid === inputQ15Data.pid && i.q15Date === moment(inputQ15Date).format("YYYYMMDD")).map((k) => { return k; }).length > 0 ? displayData.filter(i => i.q15Slot === "B19" && i.pid === inputQ15Data.pid && i.q15Date === moment(inputQ15Date).format("YYYYMMDD")).map(k => (
                                <a style={{ cursor: 'pointer', height: '50px' }} onClick={() => handleDisplayDialogQ15Data("B19", "19:15-19:29", k)}>
                                    <div style={{ position: 'relative', left: '10px', background: timeSlotSectionChange === "B19" ? "#4977D9" : "", height: '100%' }} className="patient-Q15-SlotSection">
                                        <><img src={MaskGroupImage} style={{ width: '103px', height: '47px', borderRadius: '5px', position: 'relative', left: '-35px', top: '-13.1px' }}></img>
                                            <span style={{ position: 'relative', top: '-51px' }} className="patient-Q15-OccupiedSlot-Text">{k.location + "-" + k.activity}</span></>
                                    </div></a>)) :
                                <a style={{ cursor: 'pointer', height: '50px' }} onClick={() => handleDisplayDialogQ15Data("B19", "19:15-19:29", "")}>
                                    <div style={{ position: 'relative', left: '10px', background: timeSlotSectionChange === "B19" ? "#4977D9" : "", height: '100%', display: 'flex', alignItems: 'center' }} className="patient-Q15-SlotSection">
                                        <span style={{ color: timeSlotSectionChange === "B19" ? "#FFF" : "" }} className="patient-Q15-SlotSection-Text">19.15</span>
                                    </div></a>}
                            {displayData.filter(i => i.q15Slot === "C19" && i.pid === inputQ15Data.pid && i.q15Date === moment(inputQ15Date).format("YYYYMMDD")).map((k) => { return k; }).length > 0 ? displayData.filter(i => i.q15Slot === "C19" && i.pid === inputQ15Data.pid && i.q15Date === moment(inputQ15Date).format("YYYYMMDD")).map(k => (
                                <a style={{ cursor: 'pointer', height: '50px' }} onClick={() => handleDisplayDialogQ15Data("C19", "19:30-19:44", k)}>
                                    <div style={{ position: 'relative', left: '20px', background: timeSlotSectionChange === "C19" ? "#4977D9" : "" , height: '100%'}} className="patient-Q15-SlotSection">
                                        <><img src={MaskGroupImage} style={{ width: '103px', height: '47px', borderRadius: '5px', position: 'relative', left: '-35px', top: '-13.1px' }}></img>
                                            <span style={{ position: 'relative', top: '-51px' }} className="patient-Q15-OccupiedSlot-Text">{k.location + "-" + k.activity}</span></>
                                    </div></a>)) :
                                <a style={{ cursor: 'pointer', height: '50px' }} onClick={() => handleDisplayDialogQ15Data("C19", "19:30-19:44", "")}>
                                    <div style={{ position: 'relative', left: '20px', background: timeSlotSectionChange === "C19" ? "#4977D9" : "", height: '100%', display: 'flex', alignItems: 'center' }} className="patient-Q15-SlotSection">
                                        <span style={{ color: timeSlotSectionChange === "C19" ? "#FFF" : "" }} className="patient-Q15-SlotSection-Text">19.30</span>
                                    </div></a>}
                            {displayData.filter(i => i.q15Slot === "D19" && i.pid === inputQ15Data.pid && i.q15Date === moment(inputQ15Date).format("YYYYMMDD")).map((k) => { return k; }).length > 0 ? displayData.filter(i => i.q15Slot === "D19" && i.pid === inputQ15Data.pid && i.q15Date === moment(inputQ15Date).format("YYYYMMDD")).map(k => (
                                <a style={{ cursor: 'pointer', height: '50px' }} onClick={() => handleDisplayDialogQ15Data("D19", "19:45-19:59", k)}>
                                    <div style={{ position: 'relative', left: '30px', background: timeSlotSectionChange === "D19" ? "#4977D9" : "" , height: '100%'}} className="patient-Q15-SlotSection">
                                        <><img src={MaskGroupImage} style={{ width: '103px', height: '47px', borderRadius: '5px', position: 'relative', left: '-35px', top: '-13.1px' }}></img>
                                            <span style={{ position: 'relative', top: '-51px' }} className="patient-Q15-OccupiedSlot-Text">{k.location + "-" + k.activity}</span></>
                                    </div></a>)) :
                                <a style={{ cursor: 'pointer', height: '50px' }} onClick={() => handleDisplayDialogQ15Data("D19", "19:45-19:59", "")}>
                                    <div style={{ position: 'relative', left: '30px', background: timeSlotSectionChange === "D19" ? "#4977D9" : "", height: '100%', display: 'flex', alignItems: 'center' }} className="patient-Q15-SlotSection">
                                        <span style={{ color: timeSlotSectionChange === "D19" ? "#FFF" : "" }} className="patient-Q15-SlotSection-Text">19.45</span>
                                    </div></a>}
                        </div>
                        <div style={{ position: 'relative', left: '42px', top: '66px', display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                            {displayData.filter(i => i.q15Slot === "A20" && i.pid === inputQ15Data.pid && i.q15Date === moment(inputQ15Date).format("YYYYMMDD")).map((k) => { return k; }).length > 0 ? displayData.filter(i => i.q15Slot === "A20" && i.pid === inputQ15Data.pid && i.q15Date === moment(inputQ15Date).format("YYYYMMDD")).map(k => (
                                <a style={{ cursor: 'pointer', height: '50px' }} onClick={() => handleDisplayDialogQ15Data("A20", "20:00-20:14", k)}>
                                    <div style={{ background: timeSlotSectionChange === "A20" ? "#4977D9" : "", height: '100%' }} className="patient-Q15-SlotSection">
                                        <><img src={MaskGroupImage} style={{ width: '103px', height: '47px', borderRadius: '5px', position: 'relative', left: '-35px', top: '-13.1px' }}></img>
                                            <span style={{ position: 'relative', top: '-51px' }} className="patient-Q15-OccupiedSlot-Text">{k.location + "-" + k.activity}</span></>
                                    </div></a>)) :
                                <a style={{ cursor: 'pointer', height: '50px' }} onClick={() => handleDisplayDialogQ15Data("A20", "20:00-20:14", "")}>
                                    <div style={{ background: timeSlotSectionChange === "A20" ? "#4977D9" : "", height: '100%', display: 'flex', alignItems: 'center' }} className="patient-Q15-SlotSection">
                                        <span style={{ color: timeSlotSectionChange === "A20" ? "#FFF" : "" }} className="patient-Q15-SlotSection-Text">20.00</span>
                                    </div></a>}
                            {displayData.filter(i => i.q15Slot === "B20" && i.pid === inputQ15Data.pid && i.q15Date === moment(inputQ15Date).format("YYYYMMDD")).map((k) => { return k; }).length > 0 ? displayData.filter(i => i.q15Slot === "B20" && i.pid === inputQ15Data.pid && i.q15Date === moment(inputQ15Date).format("YYYYMMDD")).map(k => (
                                <a style={{ cursor: 'pointer', height: '50px' }} onClick={() => handleDisplayDialogQ15Data("B20", "20:15-20:29", k)}>
                                    <div style={{ position: 'relative', left: '10px', background: timeSlotSectionChange === "B20" ? "#4977D9" : "", height: '100%' }} className="patient-Q15-SlotSection">
                                        <><img src={MaskGroupImage} style={{ width: '103px', height: '47px', borderRadius: '5px', position: 'relative', left: '-35px', top: '-13.1px' }}></img>
                                            <span style={{ position: 'relative', top: '-51px' }} className="patient-Q15-OccupiedSlot-Text">{k.location + "-" + k.activity}</span></>
                                    </div></a>)) :
                                <a style={{ cursor: 'pointer', height: '50px' }} onClick={() => handleDisplayDialogQ15Data("B20", "20:15-20:29", "")}>
                                    <div style={{ position: 'relative', left: '10px', background: timeSlotSectionChange === "B20" ? "#4977D9" : "" , height: '100%', display: 'flex', alignItems: 'center'}} className="patient-Q15-SlotSection">
                                        <span style={{ color: timeSlotSectionChange === "B20" ? "#FFF" : "" }} className="patient-Q15-SlotSection-Text">20.15</span>
                                    </div></a>}
                            {displayData.filter(i => i.q15Slot === "C20" && i.pid === inputQ15Data.pid && i.q15Date === moment(inputQ15Date).format("YYYYMMDD")).map((k) => { return k; }).length > 0 ? displayData.filter(i => i.q15Slot === "C20" && i.pid === inputQ15Data.pid && i.q15Date === moment(inputQ15Date).format("YYYYMMDD")).map(k => (
                                <a style={{ cursor: 'pointer', height: '50px' }} onClick={() => handleDisplayDialogQ15Data("C20", "20:30-20:44", k)}>
                                    <div style={{ position: 'relative', left: '20px', background: timeSlotSectionChange === "C20" ? "#4977D9" : "" , height: '100%'}} className="patient-Q15-SlotSection">
                                        <><img src={MaskGroupImage} style={{ width: '103px', height: '47px', borderRadius: '5px', position: 'relative', left: '-35px', top: '-13.1px' }}></img>
                                            <span style={{ position: 'relative', top: '-51px' }} className="patient-Q15-OccupiedSlot-Text">{k.location + "-" + k.activity}</span></>
                                    </div></a>)) :
                                <a style={{ cursor: 'pointer', height: '50px' }} onClick={() => handleDisplayDialogQ15Data("C20", "20:30-20:44", "")}>
                                    <div style={{ position: 'relative', left: '20px', background: timeSlotSectionChange === "C20" ? "#4977D9" : "", height: '100%', display: 'flex', alignItems: 'center' }} className="patient-Q15-SlotSection">
                                        <span style={{ color: timeSlotSectionChange === "C20" ? "#FFF" : "" }} className="patient-Q15-SlotSection-Text">20.30</span>
                                    </div></a>}
                            {displayData.filter(i => i.q15Slot === "D20" && i.pid === inputQ15Data.pid && i.q15Date === moment(inputQ15Date).format("YYYYMMDD")).map((k) => { return k; }).length > 0 ? displayData.filter(i => i.q15Slot === "D20" && i.pid === inputQ15Data.pid && i.q15Date === moment(inputQ15Date).format("YYYYMMDD")).map(k => (
                                <a style={{ cursor: 'pointer', height: '50px' }} onClick={() => handleDisplayDialogQ15Data("D20", "20:45-20:59", k)}>
                                    <div style={{ position: 'relative', left: '30px', background: timeSlotSectionChange === "D20" ? "#4977D9" : "", height: '100%' }} className="patient-Q15-SlotSection">
                                        <><img src={MaskGroupImage} style={{ width: '103px', height: '47px', borderRadius: '5px', position: 'relative', left: '-35px', top: '-13.1px' }}></img>
                                            <span style={{ position: 'relative', top: '-51px' }} className="patient-Q15-OccupiedSlot-Text">{k.location + "-" + k.activity}</span></>
                                    </div></a>)) :
                                <a style={{ cursor: 'pointer', height: '50px' }} onClick={() => handleDisplayDialogQ15Data("D20", "20:45-20:59", "")}>
                                    <div style={{ position: 'relative', left: '30px', background: timeSlotSectionChange === "D20" ? "#4977D9" : "", height: '100%', display: 'flex', alignItems: 'center' }} className="patient-Q15-SlotSection">
                                        <span style={{ color: timeSlotSectionChange === "D20" ? "#FFF" : "" }} className="patient-Q15-SlotSection-Text">20.45</span>
                                    </div></a>}
                        </div>
                        <div style={{ position: 'relative', left: '42px', top: '73px', display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                            {displayData.filter(i => i.q15Slot === "A21" && i.pid === inputQ15Data.pid && i.q15Date === moment(inputQ15Date).format("YYYYMMDD")).map((k) => { return k; }).length > 0 ? displayData.filter(i => i.q15Slot === "A21" && i.pid === inputQ15Data.pid && i.q15Date === moment(inputQ15Date).format("YYYYMMDD")).map(k => (
                                <a style={{ cursor: 'pointer', height: '50px' }} onClick={() => handleDisplayDialogQ15Data("A21", "21:00-21:14", k)}>
                                    <div style={{ background: timeSlotSectionChange === "A21" ? "#4977D9" : "" , height: '100%'}} className="patient-Q15-SlotSection">
                                        <><img src={MaskGroupImage} style={{ width: '103px', height: '47px', borderRadius: '5px', position: 'relative', left: '-35px', top: '-13.1px' }}></img>
                                            <span style={{ position: 'relative', top: '-51px' }} className="patient-Q15-OccupiedSlot-Text">{k.location + "-" + k.activity}</span></>
                                    </div></a>)) :
                                <a style={{ cursor: 'pointer', height: '50px' }} onClick={() => handleDisplayDialogQ15Data("A21", "21:00-21:14", "")}>
                                    <div style={{ background: timeSlotSectionChange === "A21" ? "#4977D9" : "", height: '100%', display: 'flex', alignItems: 'center' }} className="patient-Q15-SlotSection">
                                        <span style={{ color: timeSlotSectionChange === "A21" ? "#FFF" : "" }} className="patient-Q15-SlotSection-Text">21.00</span>
                                    </div></a>}
                            {displayData.filter(i => i.q15Slot === "B21" && i.pid === inputQ15Data.pid && i.q15Date === moment(inputQ15Date).format("YYYYMMDD")).map((k) => { return k; }).length > 0 ? displayData.filter(i => i.q15Slot === "B21" && i.pid === inputQ15Data.pid && i.q15Date === moment(inputQ15Date).format("YYYYMMDD")).map(k => (
                                <a style={{ cursor: 'pointer', height: '50px' }} onClick={() => handleDisplayDialogQ15Data("B21", "21:15-21:29", k)}>
                                    <div style={{ position: 'relative', left: '10px', background: timeSlotSectionChange === "B21" ? "#4977D9" : "", height: '100%' }} className="patient-Q15-SlotSection">
                                        <><img src={MaskGroupImage} style={{ width: '103px', height: '47px', borderRadius: '5px', position: 'relative', left: '-35px', top: '-13.1px' }}></img>
                                            <span style={{ position: 'relative', top: '-51px' }} className="patient-Q15-OccupiedSlot-Text">{k.location + "-" + k.activity}</span></>
                                    </div></a>)) :
                                <a style={{ cursor: 'pointer',height: '50px' }} onClick={() => handleDisplayDialogQ15Data("B21", "21:15-21:29", "")}>
                                    <div style={{ position: 'relative', left: '10px', background: timeSlotSectionChange === "B21" ? "#4977D9" : "", height: '100%', display: 'flex', alignItems: 'center' }} className="patient-Q15-SlotSection">
                                        <span style={{ color: timeSlotSectionChange === "B21" ? "#FFF" : "" }} className="patient-Q15-SlotSection-Text">21.15</span>
                                    </div></a>}
                            {displayData.filter(i => i.q15Slot === "C21" && i.pid === inputQ15Data.pid && i.q15Date === moment(inputQ15Date).format("YYYYMMDD")).map((k) => { return k; }).length > 0 ? displayData.filter(i => i.q15Slot === "C21" && i.pid === inputQ15Data.pid && i.q15Date === moment(inputQ15Date).format("YYYYMMDD")).map(k => (
                                <a style={{ cursor: 'pointer', height: '50px' }} onClick={() => handleDisplayDialogQ15Data("C21", "21:30-21:44", k)}>
                                    <div style={{ position: 'relative', left: '20px', background: timeSlotSectionChange === "C21" ? "#4977D9" : "", height: '100%' }} className="patient-Q15-SlotSection">
                                        <><img src={MaskGroupImage} style={{ width: '103px', height: '47px', borderRadius: '5px', position: 'relative', left: '-35px', top: '-13.1px' }}></img>
                                            <span style={{ position: 'relative', top: '-51px' }} className="patient-Q15-OccupiedSlot-Text">{k.location + "-" + k.activity}</span></>
                                    </div></a>)) :
                                <a style={{ cursor: 'pointer', height: '50px' }} onClick={() => handleDisplayDialogQ15Data("C21", "21:30-21:44", "")}>
                                    <div style={{ position: 'relative', left: '20px', background: timeSlotSectionChange === "C21" ? "#4977D9" : "", height: '100%', display: 'flex', alignItems: 'center' }} className="patient-Q15-SlotSection">
                                        <span style={{ color: timeSlotSectionChange === "C21" ? "#FFF" : "" }} className="patient-Q15-SlotSection-Text">21.30</span>
                                    </div></a>}
                            {displayData.filter(i => i.q15Slot === "D21" && i.pid === inputQ15Data.pid && i.q15Date === moment(inputQ15Date).format("YYYYMMDD")).map((k) => { return k; }).length > 0 ? displayData.filter(i => i.q15Slot === "D21" && i.pid === inputQ15Data.pid && i.q15Date === moment(inputQ15Date).format("YYYYMMDD")).map(k => (
                                <a style={{ cursor: 'pointer', height: '50px' }} onClick={() => handleDisplayDialogQ15Data("D21", "21:45-21:59", k)}>
                                    <div style={{ position: 'relative', left: '30px', background: timeSlotSectionChange === "D21" ? "#4977D9" : "", height: '100%' }} className="patient-Q15-SlotSection">
                                        <><img src={MaskGroupImage} style={{ width: '103px', height: '47px', borderRadius: '5px', position: 'relative', left: '-35px', top: '-13.1px' }}></img>
                                            <span style={{ position: 'relative', top: '-51px' }} className="patient-Q15-OccupiedSlot-Text">{k.location + "-" + k.activity}</span></>
                                    </div></a>)) :
                                <a style={{ cursor: 'pointer', height: '50px' }} onClick={() => handleDisplayDialogQ15Data("D21", "21:45-21:59", "")}>
                                    <div style={{ position: 'relative', left: '30px', background: timeSlotSectionChange === "D21" ? "#4977D9" : "" , height: '100%', display: 'flex', alignItems: 'center'}} className="patient-Q15-SlotSection">
                                        <span style={{ color: timeSlotSectionChange === "D21" ? "#FFF" : "" }} className="patient-Q15-SlotSection-Text">21.45</span>
                                    </div></a>}
                        </div>
                        <div style={{ position: 'relative', left: '42px', top: '80px', display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                            {displayData.filter(i => i.q15Slot === "A22" && i.pid === inputQ15Data.pid && i.q15Date === moment(inputQ15Date).format("YYYYMMDD")).map((k) => { return k; }).length > 0 ? displayData.filter(i => i.q15Slot === "A22" && i.pid === inputQ15Data.pid && i.q15Date === moment(inputQ15Date).format("YYYYMMDD")).map(k => (
                                <a style={{ cursor: 'pointer', height: '50px' }} onClick={() => handleDisplayDialogQ15Data("A22", "22:00-22:14", k)}>
                                    <div style={{ background: timeSlotSectionChange === "A22" ? "#4977D9" : "", height: '100%' }} className="patient-Q15-SlotSection">
                                        <><img src={MaskGroupImage} style={{ width: '103px', height: '47px', borderRadius: '5px', position: 'relative', left: '-35px', top: '-13.1px' }}></img>
                                            <span style={{ position: 'relative', top: '-51px' }} className="patient-Q15-OccupiedSlot-Text">{k.location + "-" + k.activity}</span></>
                                    </div></a>)) :
                                <a style={{ cursor: 'pointer', height: '50px' }} onClick={() => handleDisplayDialogQ15Data("A22", "22:00-22:14", "")}>
                                    <div style={{ background: timeSlotSectionChange === "A22" ? "#4977D9" : "", height: '100%', display: 'flex', alignItems: 'center' }} className="patient-Q15-SlotSection">
                                        <span style={{ color: timeSlotSectionChange === "A22" ? "#FFF" : "" }} className="patient-Q15-SlotSection-Text">22.00</span>
                                    </div></a>}
                            {displayData.filter(i => i.q15Slot === "B22" && i.pid === inputQ15Data.pid && i.q15Date === moment(inputQ15Date).format("YYYYMMDD")).map((k) => { return k; }).length > 0 ? displayData.filter(i => i.q15Slot === "B22" && i.pid === inputQ15Data.pid && i.q15Date === moment(inputQ15Date).format("YYYYMMDD")).map(k => (
                                <a style={{ cursor: 'pointer', height: '50px' }} onClick={() => handleDisplayDialogQ15Data("B22", "22:15-22:29", k)}>
                                    <div style={{ position: 'relative', left: '10px', background: timeSlotSectionChange === "B22" ? "#4977D9" : "", height: '100%' }} className="patient-Q15-SlotSection">
                                        <><img src={MaskGroupImage} style={{ width: '103px', height: '47px', borderRadius: '5px', position: 'relative', left: '-35px', top: '-13.1px' }}></img>
                                            <span style={{ position: 'relative', top: '-51px' }} className="patient-Q15-OccupiedSlot-Text">{k.location + "-" + k.activity}</span></>
                                    </div></a>)) :
                                <a style={{ cursor: 'pointer', height: "50px" }} onClick={() => handleDisplayDialogQ15Data("B22", "22:15-22:29", "")}>
                                    <div style={{ position: 'relative', left: '10px', background: timeSlotSectionChange === "B22" ? "#4977D9" : "", height: '100%', display: 'flex', alignItems: 'center' }} className="patient-Q15-SlotSection">
                                        <span style={{ color: timeSlotSectionChange === "B22" ? "#FFF" : "" }} className="patient-Q15-SlotSection-Text">22.15</span>
                                    </div></a>}
                            {displayData.filter(i => i.q15Slot === "C22" && i.pid === inputQ15Data.pid && i.q15Date === moment(inputQ15Date).format("YYYYMMDD")).map((k) => { return k; }).length > 0 ? displayData.filter(i => i.q15Slot === "C22" && i.pid === inputQ15Data.pid && i.q15Date === moment(inputQ15Date).format("YYYYMMDD")).map(k => (
                                <a style={{ cursor: 'pointer', height: '50px' }} onClick={() => handleDisplayDialogQ15Data("C22", "22:30-22:44", k)}>
                                    <div style={{ position: 'relative', left: '20px', background: timeSlotSectionChange === "C22" ? "#4977D9" : "", height: '100%' }} className="patient-Q15-SlotSection">
                                        <><img src={MaskGroupImage} style={{ width: '103px', height: '47px', borderRadius: '5px', position: 'relative', left: '-35px', top: '-13.1px' }}></img>
                                            <span style={{ position: 'relative', top: '-51px' }} className="patient-Q15-OccupiedSlot-Text">{k.location + "-" + k.activity}</span></>
                                    </div></a>)) :
                                <a style={{ cursor: 'pointer',height: '50px' }} onClick={() => handleDisplayDialogQ15Data("C22", "22:30-22:44", "")}>
                                    <div style={{ position: 'relative', left: '20px', background: timeSlotSectionChange === "C22" ? "#4977D9" : "", height: '100%', display: 'flex', alignItems: 'center' }} className="patient-Q15-SlotSection">
                                        <span style={{ color: timeSlotSectionChange === "C22" ? "#FFF" : "" }} className="patient-Q15-SlotSection-Text">22.30</span>
                                    </div></a>}
                            {displayData.filter(i => i.q15Slot === "D22" && i.pid === inputQ15Data.pid && i.q15Date === moment(inputQ15Date).format("YYYYMMDD")).map((k) => { return k; }).length > 0 ? displayData.filter(i => i.q15Slot === "D22" && i.pid === inputQ15Data.pid && i.q15Date === moment(inputQ15Date).format("YYYYMMDD")).map(k => (
                                <a style={{ cursor: 'pointer', height: '50px' }} onClick={() => handleDisplayDialogQ15Data("D22", "22:45-22:59", k)}>
                                    <div style={{ position: 'relative', left: '30px', background: timeSlotSectionChange === "D22" ? "#4977D9" : "", height: '100%' }} className="patient-Q15-SlotSection">
                                        <><img src={MaskGroupImage} style={{ width: '103px', height: '47px', borderRadius: '5px', position: 'relative', left: '-35px', top: '-13.1px' }}></img>
                                            <span style={{ position: 'relative', top: '-51px' }} className="patient-Q15-OccupiedSlot-Text">{k.location + "-" + k.activity}</span></>
                                    </div></a>)) :
                                <a style={{ cursor: 'pointer', height: '50px' }} onClick={() => handleDisplayDialogQ15Data("D22", "22:45-22:59", "")}>
                                    <div style={{ position: 'relative', left: '30px', background: timeSlotSectionChange === "D22" ? "#4977D9" : "", height: '100%', display: 'flex', alignItems: 'center' }} className="patient-Q15-SlotSection">
                                        <span style={{ color: timeSlotSectionChange === "D22" ? "#FFF" : "" }} className="patient-Q15-SlotSection-Text">22.45</span>
                                    </div></a>}
                        </div>
                        <div style={{ position: 'relative', left: '42px', top: '87px', display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                            {displayData.filter(i => i.q15Slot === "A23" && i.pid === inputQ15Data.pid && i.q15Date === moment(inputQ15Date).format("YYYYMMDD")).map((k) => { return k; }).length > 0 ? displayData.filter(i => i.q15Slot === "A23" && i.pid === inputQ15Data.pid && i.q15Date === moment(inputQ15Date).format("YYYYMMDD")).map(k => (
                                <a style={{ cursor: 'pointer', height: '50px' }} onClick={() => handleDisplayDialogQ15Data("A23", "23:00-23:14", k)}>
                                    <div style={{ background: timeSlotSectionChange === "A23" ? "#4977D9" : "", height: '100%' }} className="patient-Q15-SlotSection">
                                        <><img src={MaskGroupImage} style={{ width: '103px', height: '47px', borderRadius: '5px', position: 'relative', left: '-35px', top: '-13.1px' }}></img>
                                            <span style={{ position: 'relative', top: '-51px' }} className="patient-Q15-OccupiedSlot-Text">{k.location + "-" + k.activity}</span></>
                                    </div></a>)) :
                                <a style={{ cursor: 'pointer', height: '50px' }} onClick={() => handleDisplayDialogQ15Data("A23", "23:00-23:14", "")}>
                                    <div style={{ background: timeSlotSectionChange === "A23" ? "#4977D9" : "", height: '100%', display: 'flex', alignItems: 'center' }} className="patient-Q15-SlotSection">
                                        <span style={{ color: timeSlotSectionChange === "A23" ? "#FFF" : "" }} className="patient-Q15-SlotSection-Text">23.00</span>
                                    </div></a>}
                            {displayData.filter(i => i.q15Slot === "B23" && i.pid === inputQ15Data.pid && i.q15Date === moment(inputQ15Date).format("YYYYMMDD")).map((k) => { return k; }).length > 0 ? displayData.filter(i => i.q15Slot === "B23" && i.pid === inputQ15Data.pid && i.q15Date === moment(inputQ15Date).format("YYYYMMDD")).map(k => (
                                <a style={{ cursor: 'pointer', height: '50px' }} onClick={() => handleDisplayDialogQ15Data("B23", "23:15-23:29", k)}>
                                    <div style={{ position: 'relative', left: '10px', background: timeSlotSectionChange === "B23" ? "#4977D9" : "", height: '100%' }} className="patient-Q15-SlotSection">
                                        <><img src={MaskGroupImage} style={{ width: '103px', height: '47px', borderRadius: '5px', position: 'relative', left: '-35px', top: '-13.1px' }}></img>
                                            <span style={{ position: 'relative', top: '-51px' }} className="patient-Q15-OccupiedSlot-Text">{k.location + "-" + k.activity}</span></>
                                    </div></a>)) :
                                <a style={{ cursor: 'pointer', height: '50px' }} onClick={() => handleDisplayDialogQ15Data("B23", "23:15-23:29", "")}>
                                    <div style={{ position: 'relative', left: '10px', background: timeSlotSectionChange === "B23" ? "#4977D9" : "", height: '100%', display: 'flex', alignItems: 'center' }} className="patient-Q15-SlotSection">
                                        <span style={{ color: timeSlotSectionChange === "B23" ? "#FFF" : "" }} className="patient-Q15-SlotSection-Text">23.15</span>
                                    </div></a>}
                            {displayData.filter(i => i.q15Slot === "C23" && i.pid === inputQ15Data.pid && i.q15Date === moment(inputQ15Date).format("YYYYMMDD")).map((k) => { return k; }).length > 0 ? displayData.filter(i => i.q15Slot === "C23" && i.pid === inputQ15Data.pid && i.q15Date === moment(inputQ15Date).format("YYYYMMDD")).map(k => (
                                <a style={{ cursor: 'pointer', height: '50px' }} onClick={() => handleDisplayDialogQ15Data("C23", "23:30-23:44", k)}>
                                    <div style={{ position: 'relative', left: '20px', background: timeSlotSectionChange === "C23" ? "#4977D9" : "", height: '100%' }} className="patient-Q15-SlotSection">
                                        <><img src={MaskGroupImage} style={{ width: '103px', height: '47px', borderRadius: '5px', position: 'relative', left: '-35px', top: '-13.1px' }}></img>
                                            <span style={{ position: 'relative', top: '-51px' }} className="patient-Q15-OccupiedSlot-Text">{k.location + "-" + k.activity}</span></>
                                    </div></a>)) :
                                <a style={{ cursor: 'pointer', height: '50px' }} onClick={() => handleDisplayDialogQ15Data("C23", "23:30-23:44", "")}>
                                    <div style={{ position: 'relative', left: '20px', background: timeSlotSectionChange === "C23" ? "#4977D9" : "", height: '100%', display: 'flex', alignItems: 'center' }} className="patient-Q15-SlotSection">
                                        <span style={{ color: timeSlotSectionChange === "C23" ? "#FFF" : "" }} className="patient-Q15-SlotSection-Text">23.30</span>
                                    </div></a>}
                            {displayData.filter(i => i.q15Slot === "D23" && i.pid === inputQ15Data.pid && i.q15Date === moment(inputQ15Date).format("YYYYMMDD")).map((k) => { return k; }).length > 0 ? displayData.filter(i => i.q15Slot === "D23" && i.pid === inputQ15Data.pid && i.q15Date === moment(inputQ15Date).format("YYYYMMDD")).map(k => (
                                <a style={{ cursor: 'pointer', height: '50px' }} onClick={() => handleDisplayDialogQ15Data("D23", "23:45-23:59", k)}>
                                    <div style={{ position: 'relative', left: '30px', background: timeSlotSectionChange === "D23" ? "#4977D9" : "", height: '100%' }} className="patient-Q15-SlotSection">
                                        <><img src={MaskGroupImage} style={{ width: '103px', height: '47px', borderRadius: '5px', position: 'relative', left: '-35px', top: '-13.1px' }}></img>
                                            <span style={{ position: 'relative', top: '-51px' }} className="patient-Q15-OccupiedSlot-Text">{k.location + "-" + k.activity}</span></>
                                    </div></a>)) :
                                <a style={{ cursor: 'pointer', height: '50px' }} onClick={() => handleDisplayDialogQ15Data("D23", "23:45-23:59", "")}>
                                    <div style={{ position: 'relative', left: '30px', background: timeSlotSectionChange === "D23" ? "#4977D9" : "", height: '100%', display: 'flex', alignItems: 'center' }} className="patient-Q15-SlotSection">
                                        <span style={{ color: timeSlotSectionChange === "D23" ? "#FFF" : "" }} className="patient-Q15-SlotSection-Text">23.45</span>
                                    </div></a>}
                        </div>
                    </> : <></>}
                </div>
            </div>
            <Dialog maxWidth={'md'} PaperProps={{ sx: { position: 'absolute', width: '316px', height: '570px', maxHeight: 'calc(100% - 237px)' } }} style={{ left: '826px', top: '42px', height: '570px' }} open={showCalendarView} onClose={() => setShowCalendarVew(false)} >
                <DialogContent style={{ padding: '0px', overflow: 'hidden' }}>
                    <DialogContentText>
                        <div style={{}}>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DateCalendar className="patient-Q15-Calendar"
                                    value={calendarDate}
                                    onChange={(newValue: any) => {
                                        calendarDate = newValue;
                                        setCalendarDate(calendarDate);
                                        setInputQ15Date(newValue);
                                        setCalendarChange(4);
                                        inputQ15Data.q15Date = newValue;
                                        let newDependDate = newValue !== "" ? moment(newValue, "YYYY-MM-DDTHH:mm:ss.000+05:30").format("YYYYMMDD") : moment(new Date(), "YYYY-MM-DDTHH:mm:ss.000+05:30").format("YYYYMMDD");
                                        if (inputQ15Data.pid !== "" && newDependDate !== null && newDependDate !== "" && newDependDate !== undefined) {

                                            HttpLogin.axios().get(`/api/config/getById/${inputQ15Data.pid}/date/${newDependDate}`)
                                                .then((response) => {
                                                    if (response.data.data !== undefined) {

                                                        setDisplayData(response.data.data);
                                                        if (localStorage.getItem("CREATEQ15") !== null && localStorage.getItem("CREATEQ15") !== "" && localStorage.getItem("CREATEQ15") !== "No") {
                                                            let timeAddSlotChange;
                                                            let newCalendarChange;
                                                            let newCalendarDate;

                                                            timeAddSlotChange = localStorage.getItem("TIMESLOTCHANGE");
                                                            setTimeSlotChange(timeAddSlotChange !== "" && timeAddSlotChange !== null ? parseInt(timeAddSlotChange) : 1);
                                                            newCalendarChange = localStorage.getItem("Q15CALENDARCHANGE");
                                                            setCalendarChange(newCalendarChange !== "" && timeAddSlotChange !== null ? parseInt(timeAddSlotChange) : 4);
                                                            newCalendarDate = localStorage.getItem("Q15DATE");
                                                            setCalendarDate(newCalendarDate !== "" && newCalendarDate !== null ? new Date(newCalendarDate) : calendarDate);
                                                            inputQ15Date = moment((new Date(calendarDate.getFullYear(), calendarDate.getMonth(), calendarDate.getDate())), "DDD MMM DD YYYY HH:mm:ss").format("YYYYMMDD")
                                                            setInputQ15Date(inputQ15Date);
                                                            inputQ15Data.q15Date = inputQ15Date;
                                                            setInputQ15Data({ ...inputQ15Data });
                                                            localStorage.setItem("CREATEQ15", "No");
                                                            setQ15CheckView(true);
                                                        }
                                                    } else {
                                                        // alert(response.data.message.description);
                                                    }
                                                })
                                        }
                                        setInputQ15Data({ ...inputQ15Data });
                                        setShowCalendarVew(false);
                                    }} />
                            </LocalizationProvider>
                        </div>
                    </DialogContentText>
                </DialogContent>
            </Dialog>
        <Dialog maxWidth={'lg'} PaperProps={{sx: {width: '595px',maxHeight: 'calc(100% - 40px)', top: 25,
    },
  }}
  className="p-5"
  open={displayDialogQ15Data}
  onClose={handleNewClose}
>
  <DialogContent style={{ overflowY: 'auto' }} className="p-4">
    <DialogContentText style={{ paddingLeft: '15px' }}>
      <div style={{ display: 'flex', width: '96%', justifyContent: 'end' }}>
        {showAlertcr && (
          <Stack style={{ height: '83px', width: '380px' }} spacing={2}>
            <Alert severity="success">
              <AlertTitle>{alertMessage}</AlertTitle>
            </Alert>
          </Stack>
        )}
        {showAlerter && (
          <Stack style={{ height: '83px', width: '332px' }} spacing={2}>
            <Alert severity="error">
              <AlertTitle>{alertMessage}</AlertTitle>
            </Alert>
          </Stack>
        )}
      </div>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <div>
        {addPatientName !== '' ? (
      <div style={{ display: 'flex', alignItems: 'center', opacity: 0.6, gap: '10px' }}>
        <img
          src={patientImage}
          style={{ width: '37px', height: '37px', borderRadius: '50%' }}
          alt="Patient"
        />
        {/* <span style={{ fontSize: '16px', fontWeight: '500' }}>{addPatientName}</span> */}
        <span style={{ fontSize: '16px', fontWeight: 'bold', color: '#000' }}>
  {addPatientName}
</span>

      </div>
    ) : (
      <span></span>
          )}
          {/* <div
            style={{ fontSize: '16px' }}
            className="patient-Q15-dialogTitle"
          >
            Enter Date and Time
          </div> */}
          <div
            style={{ fontSize: '16px', opacity: '0.5' }}
            className="patient-Q15-dialogTitle"
          >
            {/* {inputQ15Data.q15Time} */}
          </div>
          <div
            style={{ width: '95%' }}
            className="d-flex flex-row gap-2 pt-2"
          >
            <div className="col-6">
              <DatePicker
                autoFocus={displayDialogQ15Data}
                label="Date"
                value={inputQ15Date}
                onChange={(newValue) => {
                  inputQ15Date = newValue;
                  setInputQ15Data({ ...inputQ15Data });
                }}
                slotProps={{
                  textField: {
                    variant: 'outlined',
                    size: 'medium',
                    fullWidth: true,
                    color: 'primary',
                  },
                }}
              />
            </div>
            <div className="col-6">
              <div className="d-flex flex-row gap-2">
                <TextField
                  id="q15TimePeriod"
                  value={inputQ15Data.q15Time.slice(0, 5)}
                  color="primary"
                  variant="outlined"
                  type="text"
                  label="Start Time"
                  size="medium"
                  margin="none"
                />
                <TextField
                  id="q15TimePeriod1"
                  value={inputQ15Data.q15Time.slice(6, 11)}
                  color="primary"
                  variant="outlined"
                  type="text"
                  label="End Time"
                  size="medium"
                  margin="none"
                />
              </div>
            </div>
          </div>
          <div className="pt-3">
            <TextField
              id="q15EnteredBy"
              value={staffEnteredBy}
              disabled={true}
              style={{ width: '96.5%' }}
              color="primary"
              variant="outlined"
              type="text"
              label="Enter Staff Name"
              size="medium"
              margin="none"
              required
            />
          </div>
          <div className="pt-3 d-flex flex-row">
            <div className="col-5">
              <FormControl
                style={{ width: '95%' }}
                variant="outlined"
              >
                <InputLabel color="primary">Location Legend</InputLabel>
                <Select
                  color="primary"
                  size="medium"
                  label="Location Legend"
                  name="q15Location"
                  value={inputQ15Data.location}
                  onChange={(e) => {
                    inputQ15Data.location = e.target.value;
                    setInputQ15Data({ ...inputQ15Data });
                  }}
                >
                  {displayLocationData.map((newData, i) => {
                    return (
                      <MenuItem
                        key={i}
                        value={newData.value}
                      >
                        {newData.label}
                      </MenuItem>
                    );
                  })}
                </Select>
                <FormHelperText />
              </FormControl>
            </div>
            <div className="col-7">
              <FormControl
                style={{ width: '94.5%' }}
                variant="outlined"
              >
                <InputLabel color="primary">Condition Legend</InputLabel>
                <Select
                  color="primary"
                  size="medium"
                  label="Condition Legend"
                  name="q15Activity"
                  value={inputQ15Data.activity}
                  onChange={(e) => {
                    inputQ15Data.activity = e.target.value;
                    setInputQ15Data({ ...inputQ15Data });
                  }}
                >
                  {displayActivityData.map((newData, i) => {
                    return (
                      <MenuItem
                        key={i}
                        value={newData.value}
                      >
                        {newData.label}
                      </MenuItem>
                    );
                  })}
                </Select>
                <FormHelperText />
              </FormControl>
            </div>
          </div>
          <div className="mt-2">
            <TextField
              id="q15EnteredBy"
              value={inputQ15Data.remarks}
              onChange={(e) => {
                inputQ15Data.remarks = e.target.value;
                setInputQ15Data({ ...inputQ15Data });
              }}
              style={{ width: '96.5%' }}
              multiline
              rows={2}
              color="primary"
              variant="outlined"
              type="text"
              label="Notes/remarks"
              size="medium"
              margin="none"
              required
            />
          </div>
          <div className="mt-2">
            <TextField
              id="q15EnteredBy"
              value={inputQ15Data.reason}
              onChange={(e) => {
                inputQ15Data.reason = e.target.value;
                setInputQ15Data({ ...inputQ15Data });
              }}
              style={{ width: '96.5%' }}
              multiline
              rows={2}
              color="primary"
              variant="outlined"
              type="text"
              label="Beacon Scanning Override"
              size="medium"
              margin="none"
              required
            />
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-end',
              gap: '10px',
              paddingBottom: '15px', 
              paddingRight: '15px',
            }}
            className="mt-4"
          >
            <Button
              style={{ cursor: 'pointer' }}
              onClick={handleNewClose}
            >
              Cancel
            </Button>
            <Button
              style={{
                cursor: 'pointer',
                backgroundColor: primarybg,
                borderColor: 'transparent',
              }}
              onClick={handleQ15ClickChange}
            >
              Save
            </Button>
          </div>
        </div>
      </LocalizationProvider>
    </DialogContentText>
  </DialogContent>
</Dialog>

        </div>
    );
};

export default Q15SlotAssign