import { TextField } from '@mui/material'
import { Button } from 'primereact/button'
import React, { useState } from 'react'
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import { authorize } from '../../helpers/common'
import axios from 'axios'
import { baseURL, successCode } from '../../configuration/url'
import { toast } from 'react-toastify'
import LogoutConfirmationModal from '../../components/LogoutModel'

interface EditSettingModalProps {
  isOpen: boolean
  handleModalColse: () => void
  formValue: any
  setFormValue: any
  OrgId: any
}

const EditSettingModal: React.FC<EditSettingModalProps> = ({isOpen, handleModalColse, formValue, setFormValue, OrgId}) => {
    // console.log(formValue, 'form valueeeeeeee')
    const [types, setTypes] = useState<string>('');
    const [getLocation, setGetLocation] = useState<any>('');
    const [addData, setAddData] = useState<any[]>([]);
    const [deleteRecord, setDeleteRecord] = useState<any>('');
    const [recordInx, setRecordInx] = useState<any>('');
    const [deleteConfirm, setDeleteConfirm] = useState<boolean>(false);



      const addRecord = () => {
        const LocalData = {
            id : '',
            value: getLocation,
            type: 'location'
        }
        const listData : any[] = formValue?.list
        listData.push(LocalData)
        // console.log(listData, 'listData')

        setFormValue((prevData: any) => ({
            ...prevData,
            list: listData
          }));
          setGetLocation('');
    };
      
      const handleKeyDown = (e: any) => {
        if (e.key === 'Enter') {
          addRecord();
        }
      };
    
      const confirmDeleteRecord = (deleteRecord: string, inx: number) => {
        setDeleteRecord(deleteRecord);
        setRecordInx(inx);
        setDeleteConfirm(true);
      };
    
      const recordDeleted = (deleteRecord: any, index: number) => {
        if (index !== -1) {
          const newData = formValue?.list?.filter((item: any, i: number) => i !== index);
          setFormValue((prevData: any) => ({
            ...prevData,
            list: newData
          }));
          recordDeletedCls();
        }
      };
    
      const recordDeletedCls = () => {
        setDeleteRecord('');
        setRecordInx('');
        setDeleteConfirm(false);
      };
    
      const  handleSubmit = async () => {
        const requestBody = {
            id: '',
            organizationId: OrgId,
            dropdown: 'location',
            list: formValue?.list
          };
          const {header1} = authorize();
          const response = await axios.post(`${baseURL}/dropdowns/addByOrgId`, requestBody, { headers: header1 });
          if (response.data.message.code === successCode) {
            toast.success(response.data.message.description)
            handleModalColse()
          } else {
            toast.error(response.data.message.description)
          }

      }
      const handelClose = ( ) => {
        handleModalColse()
        setFormValue('')
      }
  return (
    <div>
        <Modal isOpen={isOpen} size="lg" centered style={{ width: '580px' }}>
            <ModalHeader toggle={() => handelClose()}>
              Edit
            </ModalHeader>
            <ModalBody>
          <div className="row w-100" style={{ alignItems: 'center', justifyContent: 'center', marginTop: '10px' }}>
            <div className="col-md-12 mb-2 d-flex flex-row">
              <TextField
                label="Enter Your Type"
                fullWidth
                value={formValue?.dropdown}
                // onChange={(e: any) => {
                //   setTypes(e.target.value);
                // }}
                disabled
              />
            </div>

            <div className="col-md-12 mb-2 d-flex flex-row">
              <div className="col-md-11">
                <TextField
                  label="Enter Data"
                  fullWidth
                  value={getLocation}
                  onChange={(e: any) => {
                    setGetLocation(e.target.value);
                  }}
                  onKeyDown={(e) => { handleKeyDown(e) }}
                />
              </div>
              <div className="col-md-1 d-flex align-items-center justify-content-center " role="button">
                <i
                  style={{ backgroundColor: "#d7dee5", borderRadius: "4px" }}
                  onClick={addRecord}
                  className="material-icons"
                >
                  add
                </i>
              </div>
            </div>

            <div className="d-flex flex-wrap">
              {Array.isArray(formValue?.list) && formValue?.list?.map((item: any, index: number) => {
                return (
                  <div className="col-md-6 d-flex flex-row p-1" key={index}>
                    <div className="col-md-9">
                      <input
                        type="text"
                        value={item?.value}
                        readOnly
                      />
                    </div>

                    <div className="col-md-2 d-flex align-items-center justify-content-center" role="button">
                      <i
                        style={{ backgroundColor: "#d7dee5", borderRadius: "4px" }}
                        onClick={() => confirmDeleteRecord(item, index)}
                        className="material-icons"
                      >
                        remove
                      </i>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>

            </ModalBody>
        <ModalFooter className="gap-2">
          <Button
            label="Cancel"
            severity="secondary"
            style={{
              color: '#000',
              backgroundColor: '#94a0b7',
              fontSize: '12px',
              fontWeight: 'bold'
            }}
            onClick={() => handelClose()}
          ></Button>
          <Button
            label="Save Changes"
            style={{
              backgroundColor: '#0f3995',
              fontSize: '12px',
              fontWeight: 'bold'
            }}
            onClick={() => { handleSubmit() }}
          ></Button>
        </ModalFooter>
        </Modal>
        <LogoutConfirmationModal
        open={deleteConfirm}
        handleConfirm={() => recordDeleted(deleteRecord, recordInx)}
        handleClose={() => recordDeletedCls()}
        message={`Do you want to delete this Location ${deleteRecord?.value}? `}
      />
    </div>
  )
}

export default EditSettingModal