// import { Avatar, IconButton } from '@mui/material'
// import React from 'react'
// import { FaBroadcastTower } from 'react-icons/fa'
// import './StaffCardPg.css'
// import { useSelector } from 'react-redux';

// interface CardProps {
//     staffRole: string
//     patientName: string;
//     role: string;
//     sos: string;
//     heartRate: string;
// }

// const Card: React.FC<CardProps> = ({ patientName, role, sos, heartRate, staffRole }) => {
//     const {staffData} = useSelector((state: any) => state.Staff);
//     console.log("staff",staffData.profile)
//     return (
//         <div className='col-sm-3 d-flex align-items-center justify-content-center flex-column w-100'>
//             <div className='row d-flex align-items-center flex-column' style={{ width: '85%', backgroundColor: staffRole === 'guest' ? '#F8B195' : '#F3F6FF', height: '35%', borderTopLeftRadius: '6px', borderTopRightRadius: '6px' }}>
//                 <div style={{ display: 'flex', alignItems: 'center', padding: '5px' }}>
//                     <div style={{ marginRight: '5px' }}>
//                         <Avatar sx={{ width: 32, height: 32, backgroundColor: '#9F9FA2' }}>
//                         </Avatar>

//                     </div>
//                     <div style={{ display: 'flex', flexDirection: 'column' }}>
//                         <p className='truncate-hover' style={{ margin: '0', marginBottom: '0px', fontSize: '12px'}} >{patientName}</p>
//                         <p style={{ margin: '0', fontSize: '12px' }}>{role}</p>
//                     </div>
//                 </div>
//             </div>
//             <div className='row d-flex justify-content-around' style={{ width: '85%', height: '50%', borderBottomLeftRadius: '6px', borderBottomRightRadius: '6px', backgroundColor: parseInt(heartRate) < 60 ? '#FBF3E3' : parseInt(heartRate) > 100 ? '#FFECEC' : '#fff' }}>

//                 <div className='col-md-4 p-0' style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
//                     <div>
//                         <IconButton>
//                             <FaBroadcastTower size={'20px'} />
//                         </IconButton>
//                     </div>
//                     {sos &&
//                     <div style={{ fontSize: '9px' }}>
//                     <p style={{ margin: '0', marginBottom: '-2px' }}>SOS Device:</p>
//                     <p >{sos}</p>
//                 </div>
//                     }
//                 </div>
//             </div>
//         </div>
//     );
// }



// export default Card;
import { Avatar, IconButton } from '@mui/material';
import { FaBroadcastTower } from 'react-icons/fa';
import './StaffCardPg.css';
import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import { getAllStaff } from "../../slices/thunk";

interface CardProps {
    staffRole: string;
    patientName: string;
    role: string;
    sos: string;
    heartRate: string;
}

const Card: React.FC<CardProps> = ({ patientName, role, sos, heartRate, staffRole }) => {
    const dispatch = useDispatch<any>();
    const { staffData } = useSelector((state: any) => state.Staff);
    const { organization } = useSelector((state: any) => state.Login);
    const profiles = staffData?.map((staff: any) => ({
        profilePhoto: staff.profile || '',
        given: staff.name?.[0]?.given,
        family: staff.name?.[0]?.family,
    })).filter(Boolean);
    const matchingProfile = profiles.find(
        (profile: any) =>
            `${profile.given} ${profile.family}`.toLowerCase() === patientName.toLowerCase()
    );
    const profilePhoto = matchingProfile?.profilePhoto || '';
    useEffect(() => {
        getAllStaff(dispatch, organization, -1, '');
    }, [dispatch, organization]);

    return (
        <div className="col-sm-3 d-flex align-items-center justify-content-center flex-column w-100">
            <div
                className="row d-flex align-items-center flex-column"
                style={{
                    width: '85%',
                    backgroundColor: staffRole === 'guest' ? '#F8B195' : '#F3F6FF',
                    height: '35%',
                    borderTopLeftRadius: '6px',
                    borderTopRightRadius: '6px',
                }}
            >
                <div style={{ display: 'flex', alignItems: 'center', padding: '5px' }}>
                    <div style={{ display: 'flex', alignItems: 'center', padding: '5px' }}>
                        <div style={{ marginRight: '5px' }}>
                            {profilePhoto && (profilePhoto.startsWith('http') || profilePhoto.startsWith('data:image')) ? (
                                <img
                                    style={{ width: 32, height: 32, backgroundColor: '#9F9FA2', borderRadius: '15px' }}
                                    src={profilePhoto}
                                />
                            ) : (
                                <Avatar sx={{ width: 32, height: 32, backgroundColor: '#9F9FA2' }} />
                            )}
                        </div>
                    </div>

                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <p
                            className="truncate-hover"
                            style={{ margin: '0', marginBottom: '0px', fontSize: '12px' }}
                        >
                            {patientName}
                        </p>
                        <p style={{ margin: '0', fontSize: '12px' }}>{role}</p>
                    </div>
                </div>
            </div>
            <div
                className="row d-flex justify-content-around"
                style={{
                    width: '85%',
                    height: '50%',
                    borderBottomLeftRadius: '6px',
                    borderBottomRightRadius: '6px',
                    backgroundColor:
                        parseInt(heartRate) < 60
                            ? '#FBF3E3'
                            : parseInt(heartRate) > 100
                                ? '#FFECEC'
                                : '#fff',
                }}
            >
                <div
                    className="col-md-4 p-0"
                    style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
                >
                    <div>
                        <IconButton>
                            <FaBroadcastTower size={'20px'} />
                        </IconButton>
                    </div>
                    {sos && (
                        <div style={{ fontSize: '9px' }}>
                            <p style={{ margin: '0', marginBottom: '-2px' }}>SOS Device:</p>
                            <p>{sos}</p>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Card;
