import { Autocomplete, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import LogoutConfirmationModal from '../../components/LogoutModel';
import { Button } from 'primereact/button';
import axios from 'axios';
import { baseURL, successCode } from '../../configuration/url';
import { authorize } from '../../helpers/common';
import { toast } from 'react-toastify';

interface SettingCreationProps {
  isOpen: boolean;
  CloseModal: () => void;
  OrgId: string
}
const SettingCreation: React.FC<SettingCreationProps> = ({ isOpen, CloseModal, OrgId }) => {
  const [formValue, setFormValue] = useState<any>();
  const [types, setTypes] = useState<any>('');
  const [getLocation, setGetLocation] = useState<any>('');
  const [addData, setAddData] = useState<any[]>([]);
  const [deleteRecord, setDeleteRecord] = useState<any>('');
  const [recordInx, setRecordInx] = useState<any>('');
  const [deleteConfirm, setDeleteConfirm] = useState<boolean>(false);
  const [dropdowns,setDropdowns] = useState<any>([])

  useEffect(() => {
    const handleDorpDown = async () => {
      try {
        const response = await axios.get(`${baseURL}/dropdowns/getByDropdown?dropdown=setting`)
        if (response.data.message.code === successCode) {
          setDropdowns(response.data.data[0].list)
        }
      } catch (error: any) {
        console.error('API Error :',error)
      }
    }
    handleDorpDown();
  }, [])

  const handleModalColse = () => {
    CloseModal();
    setTypes('')
    setAddData([])
  };

  const addRecord = () => {
    setAddData(prevData => [...prevData, getLocation]);
    console.log(getLocation,addData ,'add datata')
    setGetLocation('');
  };

  const handleKeyDown = (e: any) => {
    if (e.key === 'Enter') {
      addRecord();
    }
  };

  const confirmDeleteRecord = (deleteRecord: string, inx: number) => {
    setDeleteRecord(deleteRecord);
    setRecordInx(inx);
    setDeleteConfirm(true);
  };

  const recordDeleted = (deleteRecord: any, index: number) => {
    if (index !== -1) {
      const newData = addData.filter((item, i) => i !== index);
      setAddData(newData);
      recordDeletedCls();
    }
  };

  const recordDeletedCls = () => {
    setDeleteRecord('');
    setRecordInx('');
    setDeleteConfirm(false);
  };

  const handleSubmit = async () => {
    addRecord()
    if (types?.value === 'location' && addData.length > 0) {
      const requestBody = {
        id: '',
        organizationId: OrgId,
        dropdown: 'location',
        list: addData.map((item: any) =>  {
          console.log(item)
          return(          
          {
            id: '',
            value: item,
            type: 'location'
          }
        )}
      )
      };
      const {header1} = authorize();
      const response = await axios.post(`${baseURL}/dropdowns/addByOrgId`, requestBody, { headers: header1 });
      if (response.data.message.code === successCode) {
        toast.success(response.data.message.description)
        handleModalColse()
      } else {
        toast.error(response.data.message.description)
      }
    } else {
      toast.error('No Data')
    }
  };

  const handleGetlocation = async (data: any) => {
    if (data.value === 'location') {
      try {
        const response = await axios.get(`${baseURL}/dropdowns/getByOrgIdAndDropdown?dropdown=location&organizationId=${OrgId}`)
        // console.log(response.data.data)
        if (response.data.message.code === successCode) {
          let len = response?.data?.data[0]?.list.length
          for (let i = 0; i < len; i++) {
            setAddData(prevData => [...prevData, response?.data?.data[0]?.list[i].value])
          }
        }
      } catch (error: any) {
        console.error('API Error: ', )
      }
    }
  }

  return (
    <div>
      <Modal isOpen={isOpen} size="lg" centered style={{ width: '580px' }}>
        <ModalHeader toggle={() => handleModalColse()}>
          Register Data
        </ModalHeader>
        <ModalBody>
          <div className="row w-100" style={{ alignItems: 'center', justifyContent: 'center', marginTop: '10px' }}>
            <div className="col-md-12 mb-2 d-flex flex-row">
              {/* <TextField
                label="Enter Your Type"
                fullWidth
                onChange={(e: any) => {
                  setTypes(e.target.value);
                }}
              /> */}
                <Autocomplete
      options={dropdowns}
      getOptionLabel={(option: any) => option.value}
      value={types || null} // Ensure that `value` is never undefined
      onChange={(e: any, v: any) => {
        setTypes(v)
        handleGetlocation(v)
      }}
      sx={{ width: '100%' }}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          label="Select Your Type"
          margin="none"
          fullWidth
          placeholder={types ? '' : 'Select a type...'} // Show placeholder when `types` is empty or undefined
          required
        />
      )}
    />
            </div>

            <div className="col-md-12 mb-2 d-flex flex-row">
              <div className="col-md-11">
                <TextField
                  label="Enter Data"
                  fullWidth
                  value={getLocation}
                  onChange={(e: any) => {
                    setGetLocation(e.target.value);
                  }}
                  onKeyDown={(e) => { handleKeyDown(e) }}
                />
              </div>
              <div className="col-md-1 d-flex align-items-center justify-content-center " role="button">
                <i
                  style={{ backgroundColor: "#d7dee5", borderRadius: "4px" }}
                  onClick={addRecord}
                  className="material-icons"
                >
                  add
                </i>
              </div>
            </div>

            <div className="d-flex flex-wrap">
              {Array.isArray(addData) && addData.map((item: any, index: number) => {
                return (
                  <div className="col-md-6 d-flex flex-row p-1" key={index}>
                    <div className="col-md-9">
                      <input
                        type="text"
                        value={item}
                        readOnly
                      />
                    </div>

                    <div className="col-md-2 d-flex align-items-center justify-content-center" role="button">
                      <i
                        style={{ backgroundColor: "#d7dee5", borderRadius: "4px" }}
                        onClick={() => confirmDeleteRecord(item, index)}
                        className="material-icons"
                      >
                        remove
                      </i>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </ModalBody>
        <ModalFooter className="gap-2">
          <Button
            label="Cancel"
            severity="secondary"
            style={{
              color: '#000',
              backgroundColor: '#94a0b7',
              fontSize: '12px',
              fontWeight: 'bold'
            }}
            onClick={() => handleModalColse()}
          ></Button>
          <Button
            label="Save"
            style={{
              backgroundColor: '#0f3995',
              fontSize: '12px',
              fontWeight: 'bold'
            }}
            onClick={() => { 
              
              handleSubmit()            
            }}
          ></Button>
        </ModalFooter>
      </Modal>

      <LogoutConfirmationModal
        open={deleteConfirm}
        handleConfirm={() => recordDeleted(deleteRecord, recordInx)}
        handleClose={() => recordDeletedCls()}
        message={`Do you want to delete this Location ${deleteRecord}? `}
      />
    </div>
  );
};

export default SettingCreation;